.transaction-history-table-wrapper {
  @include mq($mobile) {
    font-size: 12px;
    padding-left: 5px;
    padding-right: 5px;
  }
  margin-top: 30px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
  border: 1px solid transparent;
  border-radius: $main-border-radius;
  backdrop-filter: blur(11px);
  padding-left: 35px;
  padding-top: 37px;
  padding-right: 40px;
  padding-bottom: 43px;
  height: 350px;
  .table-inner-wrapper {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .binary-chart-loader-wrapper {
    display: flex;
    justify-content: center !important;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  table {
    width: 100%; /* Optionally adjust the width */
    text-align: left;
    border-collapse: collapse;

    tr {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      @include mq($mobile) {
        column-gap: 2px;
      }
    }
    tr.ennrolment-table {
      grid-template-columns: repeat(5, 1fr);
    }
    tr.sessions-table {
      grid-template-columns: repeat(4, 1fr);
    }
    tr.pending-bets-table {
      @include mq($mobile) {
        th,
        td {
          font-size: 10px;
        }
      }
      grid-template-columns: repeat(7, 1fr);
    }

    tbody {
      tr.bids-table {
        grid-template-columns: repeat(4, 1fr);
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        td {
          padding-top: 20px;
          padding-bottom: 20px;
          
          text-align: center;
        }
        td.ask {
          background-color: $binary-green;
          color: $alternative-color;
        }
        td.bid {
          background-color: $binary-red;
          color: $secondary-color;
        }
      }
    }
    thead {
      tr.bids-table {
        width: 100%;
        grid-template-columns: repeat(2, 1fr);

      }
      th{
        justify-content: center;
        text-transform: uppercase;
      }
    }
    thead {
      tr {
        padding-bottom: 10px;
      }
      th {
        @include mq($mobile) {
          font-size: 10px;
        }
        @include flex-center;
      }
      th.currency-th {
        @include mq($mobile) {
          gap: 2px;
        }
        position: relative;
        gap: 12px;
        svg {
          transition: transform $transition-duration $transition-timing-function;
        }
        svg.expanded {
          transform: rotate(180deg);
        }
      }
    }
    tbody {
      tr {
        border-bottom: 1px solid $primary-color;
      }
      tr:first-child {
        padding-bottom: 20px;
      }
      tr:not(:first-child) {
        @include mq($mobile) {
          padding-top: 5px;
          padding-bottom: 5px;
        }
        padding-top: 20px;
        padding-bottom: 20px;
      }
      .amount-cell {
        @include flex-center;
        gap: 5px;
      }
    }
    th {
      @include mq($mobile) {
        font-size: 10px;
      }
      font-size: 15px;
      font-weight: 400;
      line-height: 150%; /* 25.5px */
      letter-spacing: -0.34px;
    }
    td {
      @include mq($mobile) {
        font-size: 10px;
      }
      color: $primary-color;
      font-size: 13px;
      line-height: 150%; /* 22.5px */
      letter-spacing: -0.3px;
    }
    td.approved {
      color: $binary-green;
    }
    td.rejected {
      color: $course-drop;
    }
    td.pending {
      color: yellow;
    }
    td.cell-with-timer {
      @include flex-center;
      gap: 8px;
      @include mq($mobile) {
        svg {
          display: none;
        }
      }
    }
  }
}

.dark-profile {
  .transaction-history-table-wrapper {
    background: $block-dark-bg;
    tbody {
      tr {
        border-bottom: 1px solid $primary-color;
      }
    }
    th {
      color: $secondary-color;
    }
    td {
      color: rgba(255, 255, 255, 0.5);
    }
  }
}
