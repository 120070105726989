.login-page {
  display: flex;
    justify-content: center;
    position: relative;
  padding-top: 99px;
  padding-bottom: 103px;
flex-direction: column;
height: 100vh;
.container {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}
  .login-page__logo-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 70%;
      height: auto;
    }
    @include mq($medium-up) {
      max-width: 580px;
    }
  }
  .login-page__section-title {
    margin-left: auto;
    margin-right: auto;
    max-width: 367px;
    font-weight: 400;
font-size: 14px;
text-align: center;
margin-bottom: 57px;
  }
  .login {
    display: flex;
    gap: 20px;

    @include media-query($small) {
      flex-direction: column;
    }
  }

  .login__side {
    flex: 1 1 50%;

    input {
      background: linear-gradient(white, white) padding-box,
       $common-background-gradient border-box;
      border: 1px solid transparent;
      border-radius: $main-border-radius;
    }
    .btn {
      background: $common-background-gradient;
    }


    &:last-child {

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 20px;
      background: linear-gradient(white, white) padding-box,
       $common-background-gradient border-box;
      border: 1px solid transparent;
      border-radius: $main-border-radius;


      .login__heading {
        text-align: center;
        margin-bottom: 25px;
      }
    }
  }

  .login__heading {
    margin: 0 0 5px 0;
  }

  .form-container {
    margin-top: 20px;
  }

  .form-container__buttons-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 25px;
    margin-top: 24px;
  }
}

.fixed-select {
  position: fixed;
    left: 50px;
    top: 50px;
}

.our-products {}
.our-products-title {
  text-align: center;
}
.our-products-section-wrap {
  display: flex;
  border-radius: 10px;
  justify-content: space-between;
  border: 1px solid;
  padding: 40px 35px;
  gap: 30px;
  @include media-query($w850) {

    padding: 25px 18px;
  }
  @include media-query($w700) {


    flex-direction: column;
  }
}
.our-products-section {
  flex: 1;
  padding: 15px;
  &:first-of-type {
    border-right: 3px solid;
    border-radius: 10px;

  }
  &:last-of-type {
    border-left: 3px solid;
    border-radius: 10px;
  }
  @include media-query($w700) {
    border: none !important;
  }
}
.our-products-section-img {
  img {

    height: 120px;
    width: auto;
  }
}
.our-products-section-title {
  margin: 10px 0;
  font-weight: 600;
  font-size: 24px;
}
.our-products-section-subtitle {
  font-weight: 500;
  font-size: 16px;
  margin: 15px 0;

}
.our-products-section-text {
  font-size: 14px;
  max-width: 80%;
  line-height: 1.5;
}
.our-products-section-button {
  padding: 11px 27px;
  border-radius: 10px;
}

.binary-option-wrap {
  margin: 50px 0 50px;
}
.binary-option-title {
  text-align: center;
}
.binary-option-img {
  margin-bottom: 20px;
  border-radius: 10px;
  img {
    width: 100%;
    height: auto;
  }
}
.binary-option-sections {
  display: flex;
  @include media-query($w700) {
    flex-direction: column;
  }
}
.binary-option-section {
  &:first-of-type {
    .binary-option-section-listitem {
      padding-left: 0;

    }
  }
  &:last-of-type {
    .binary-option-section-listitem {
      border: none;

    }
  }
}
.binary-option-section-title {
  margin: 15px 0 20px;
  font-weight: 600;
  font-size: 18px;

}
.binary-option-section-listitem {

  padding: 0 15px;

  border-right: 1px solid;

  &>* {
    line-height: 1.5;
    font-size: 14px;
  }
  @include media-query($w700) {
    border: none;
    padding: 0;
  }
}
.login-page.dark-profile {
  input {
      background: linear-gradient($dark-profile-page-background, $dark-profile-page-background) padding-box, $dark-profile-gradient border-box;
      color: $secondary-color;
  }
  label {
    color: $secondary-color;
  }
      .btn, .js-send-btn.btn {
      background: $dark-profile-gradient;
      border: none;
    }
  .login__side:last-child {

    color: $secondary-color;
    background: linear-gradient($dark-profile-page-background, $dark-profile-page-background) padding-box, $dark-profile-gradient border-box;
  }
}