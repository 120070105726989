.plan-switcher {
  display: flex;
  gap: 8px;

  button {
    background: transparent;
    border: 1px solid $primary-color;
    min-width: 60px;
    height: 40px;
    color: $primary-color;
    border-radius: 4px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  button.selected {
    color: $secondary-color;
    background: $common-background-gradient;
  }
}

.dark-profile {
  .plan-switcher {
    button {
      background: transparent;
      border: 1px solid $purple-contrast;
      color: $secondary-color;
      padding: 2px;
    }
    button.selected {
      background: $purple-contrast;
      border: 1px solid transparent;
    }
  }
}
