.main-nav {
  // max-width: 234px;
  padding-left: 60px;
  padding-top: 50px;
  border-right: $main-border;
  padding-right: 20px;
      background: linear-gradient(360deg, #337fd0 0%, #9f38d2 98.84%);
      min-width: 269px;
      @include mq($laptop) {
        min-width: unset;
        max-width: fit-content;
        padding-left: 10px;
      }
  @include mq($medium-down) {
    max-width: unset;
    padding: 24px;
    border-right: unset;
  }


  svg {
    height: 25px;
    width: 25px;
  }


  .main-nav__first-lvl {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 15px;

    @include media-query($small) {
      flex-direction: row;
    }

    @include media-query($extra-small) {
      gap: 10px;
    }
  }

  .custom-select {
    @include media-query($extra-small) {
      // min-width: 130px;
    }
  }
  .main-nav__first-lvl-item {
    display: flex;
    flex-direction: column;

    .main-nav__first-lvl-item-wrapper {
      display: flex;
      gap: 20px;
      align-items: center;
    }

    .link {
      display: flex;
      align-items: center;
      gap: 20px;
    }

    .current-link {
      color: $secondary-color;

      a {
        color: inherit;
      }
    }

    span {
      display: flex;

//       path {
// fill: $secondary-color;
//       }
    }
  }

  .main-nav__second-lvl {
    display: flex;
    flex-direction: column;
    gap: 15px;
    padding-left: 25px;
    padding-top: 15px;
  }

  .main-nav__second-lvl-item {
    padding-left: 20px;
  }

  custom-select {
    .custom-select__dropdown-button {
      justify-content: center;
    }
  }

  .custom-select__btn {
    display: flex;
    padding: 0;
    align-items: center;
    gap: 18px;
    background-color: transparent;
    color: $secondary-color;

    &>svg {
      width: 12px;
      height: 6px;
        path {
          fill: transparent;
        }
    }
  }
}
.dark-profile {
  .main-nav{ 
    @include media-query($w850) {
      border-right: none;
    }
    border-right-color: $border-color;
  }
}