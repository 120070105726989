.refferals-tree-container {
  background: $secondary-color;
  border: 1px solid #79747d;
  border-radius: 5px;
  overflow-y: scroll;
  // overflow-x: scroll;
  padding-top: 30px;
  width: 100%;
  padding-left: 27px;
  padding-right: 27px;
  min-height: 500px;
  .upper-part-wrapper {
    @include flex-center;
    justify-content: space-between;
    @include mq($extra-small-down) {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    p {
      color: #85898f;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 600;
      line-height: 18px; /* 128.571% */
    }
  }
  .refferals-tree-filter {
    position: relative;
    width: 220px;
    input {
      width: 100%;
      padding-top: 11px;
      padding-bottom: 11px;
      padding-left: 14px;
      padding-right: 40px;
      border-radius: 5px;
      border: 1px solid #2c2f35;
    }

    svg {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
    }
  }
  .placeholder {
    @include flex-center;
    justify-content: center;
    min-height: 400px;

    p {
      display: block;
    }
  }
  &::-webkit-scrollbar {
    display: none;
  }
  .user-network-filter-wrapper {
    @include flex-center;
    // width: 45%;
    // margin-left: auto;
    // gap: 18px;
    justify-content: space-between;
    padding-left: 10%;
    padding-right: 15%;
    padding-top: 18px;
    padding-bottom: 10px;
    button {
      background: transparent;
      border: none;
      color: #a0f;
      font-family: "Montserrat";
      font-size: 13px;
      font-weight: 600;
      text-transform: uppercase;
    }
    // button:last-of-type {
    //   color: #85898f;
    // }
  }
  .cell-with-opener {
    color: #4d4d4d;
    font-family: "Montserrat";
    font-size: 14px;
    font-weight: 700;
  }
  .email-cell {
    padding-left: 8px;
    word-wrap: break-word; /* Ensures words wrap to the next line if they are too long */
    word-break: break-word; /* Allows breaking words that are too long to fit */
    white-space: normal !important; /* Enables wrapping of the content */
  }
  .refferals-tree-table {
    overflow-x: scroll;
    color: $primary-color;
    width: 100%;
    border-collapse: collapse;

    tr {
      display: grid;
      grid-template-columns: 40px minmax(180px, 1fr) 10% 10% repeat(3, 1fr); 
    }
    thead {
      tr {
      
        grid-template-columns: 40px minmax(180px, 1fr) 10% 10% repeat(3, 1fr); 
      }
    }
    // Parent Table Column Styling
    thead th {
      color: #85898f;
      padding-left: 8px;
      padding-right: 8px;
      font-family: Montserrat;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      text-transform: uppercase;
      font-weight: 600;
    }

    tbody tr {
   text-align: center;
      &:not(:last-child) {
        border-bottom: 1px solid $primary-color;
      }

      // Row Content and Expand Button Styling
      td {
        padding-left: 8px;
        padding-right: 8px;
        white-space: nowrap; // Ensure the content doesn't wrap
      }

      // Button to expand/collapse rows
      button {
        width: 20px;
        height: 20px;
        background-color: #d4b4e5;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        margin-right: 13px;
        .arrow-expand-tree.expanded {
          transform: rotate(180deg);
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    thead {
      tr {
        padding-bottom: 10px;
        padding-top: 23px;
      }
    }
    tbody {
      tr {
        padding-bottom: 10px;
      }
    }
  }

  // Inner Table Styling
  .refferals-tree-table-inner {
    margin-left: 30px; // Indent the inner tables by 20px for visual hierarchy
    border-collapse: collapse;
    overflow-x: scroll;
    // width: calc(100% - 30px);
    width: 100%;
    background: #ebebeb;
    thead th,
    tbody td {
      font-size: 12px;
      white-space: nowrap;
    }
    tbody {
      tr {
        padding-bottom: 10px;
        // padding-left: 17px;
      }
    }
  }
}

.dark-profile {
  .refferals-tree-container,
  .refferals-tree-table-inner {
    background: $block-dark-bg;

  }
  .refferals-tree-container {
    border: 1px solid $border-color;
  }
  .refferals-tree-table-inner {
    border: 1px solid $block-dark-bg;
  }
  td {
    color: $secondary-color;
  }
  .refferals-tree-filter {
    input {
      color: $secondary-color;
      border: 1px solid $border-color;
      background: $block-dark-bg;
    }
  }
}
