    .configure-ai__label {
      position: absolute;
      right: -1px;
      top: -47px;
      padding: 17px 32px 35px 32px;
      z-index: 1;
      background: $common-background-gradient;
      border-radius: $main-border-radius;
      color: $secondary-color;
      font-size: 14px;
      font-weight: 700;
      overflow: hidden;
      text-decoration: none;
    }