.register-page {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 99px;
  padding-bottom: 88px;

  .register {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .login-page__logo-wrapper {
    
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      display: block;
      width: 60%;
      height: auto;
    }
    @include mq($medium-up) {
      max-width: 580px;
    }


    @include media-query($w700) {
      width: 80%;
      img {
        width: 100%;
      }
    }
  }

  .form__main-content {
    display: flex;
    gap: 16px;

    @include media-query($small) {
      flex-direction: column;
      gap: 0;
    }
  }

  .register__headings-wrapper {
    text-align: center;
    margin-bottom: 29px;
  }

  .register__heading {
    margin-bottom: 9px;
  }
.register__form {

  width: 305px;
  margin-left: auto;
  margin-right: auto;
  label {
    padding: 0;
    font-weight: 500;
    font-size: 14px;
  }
  label[for="accept"] {
    gap: 4px;

    &::after {
      left: 5px;
      transform: translateX(-16%);
      
    }
  }
  input {
    background: linear-gradient(white, white) padding-box,
     $common-background-gradient border-box;
    border: 1px solid transparent;
    border-radius: $main-border-radius;

    &:checked {
      +label::before {
        background: transparent;
        border-color: transparent;
      }
    }
  }
  .btn {
    background: $common-background-gradient;
    width: 100%;
    justify-content: center;
    margin: 0;
  }
  .form-container--checkbox {
    margin-bottom: 14px;
  }
    .form-container {
    margin-bottom: 15px;
  }
  .form-container:nth-child(6) {
    margin-bottom: 18px;
  }
  .form-container:not(:last-of-type) {
    label {
      margin-bottom: 8px;
    }
  }

}

  .register__side {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px 20px;
    @include media-query($small) {
      align-items: unset;
      gap: 0px;
    }
    @include media-query($small-up) {
      align-items: unset;
      gap: 10px 20px;
    }

    .form-container {
      flex: 1 1 40%;

    }
  }

}
.register-page.dark-profile {
  input {
      background: linear-gradient($dark-profile-page-background, $dark-profile-page-background) padding-box, $dark-profile-gradient border-box;
      color: $secondary-color;
  }
  label {
    color: $secondary-color;
  }
      .btn, .js-send-btn.btn {
      background: $dark-profile-gradient;
      border: none;
    }
  .login__side:last-child {

    color: $secondary-color;
    background: linear-gradient($dark-profile-page-background, $dark-profile-page-background) padding-box, $dark-profile-gradient border-box;
  }
}