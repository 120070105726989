.h2 {
  font-size: $font-size-1;
  line-height: 1.2;
  font-weight: 600;
  color: inherit;
}

.h3 {
  font-size: $font-size-3;
  line-height: 1.2;
  font-weight: 700;
  color: inherit;
}

*,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: $body-bg;
  font-family: $primary-font;
  font-size: $primary-font-size;
  font-weight: 400;
  line-height: 1.2;
  color: $primary-color;
}



//Whitespace bug
img {
  vertical-align: middle;
}

//Lazyload quality bug
img[data-sizes] {
  display: none;
}

.no-js {
  display: none;
}

.rel {
  position: relative;
}

//========= CONTAINERS
/* Default container by center */
.page-width {
  max-width: $container-width;
  width: 100%;
  padding: 0 40px;
  margin: 0 auto;

  @include media-query($small) {
    padding: 0 16px;
  }
}

.small-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 0 10px;

}

.medium-wrapper {
  @extend .small-wrapper;
  @include mq($widescreen) {
    max-width: 1440px;
  }

}



.img-abs {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  path {
    fill: $secondary-color;
  }
}

//Gradient Overlay
.gradient-overlay {
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  &.gradient-overlay--to-top {
    &:before {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.73) 59.81%,
        #000000 85.79%
      );
    }
  }

  &.gradient-overlay--to-top-semitone {
    &:before {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9) 51%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  &.gradient-overlay--to-bottom {
    &:before {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.73) 59.81%,
        #000000 85.79%
      );
    }
  }

  &.gradient-overlay--to-right {
    &:before {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.9) 51%,
        rgba(0, 0, 0, 0.49) 100%
      );
    }
  }

  &.gradient-overlay--to-left {
    &:before {
      background: rgb(0, 0, 0);
      background: linear-gradient(
        0deg,
        rgba(0, 0, 0, 0.9) 51%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
}

.animation {
  transition: $transition-duration;
}

.show {
  opacity: 1;
  visibility: visible;
}

.hide {
  opacity: 0;
  visibility: hidden;
}

.bold {
  font-weight: 700;
}
.error-message {
  display: block;
  margin-top: 4px;
  color: red !important;
}
.page {
  min-height: 100vh !important;
  .dark-profile {
    background: $body-bg;
    background-color: $body-bg;
    color: $dark-profile-page-text;
  }
  justify-content: flex-start;

  .page__body-wrapper {
    display: flex;
    min-height: 100vh;

    @include media-query($medium-down) {
      flex-direction: column;
    }
  }

  .page__sections-wrapper {
    flex: 1;
  }

  .header {
    max-height: 204px;
    max-width: 300px;
    width: 100%;
    margin: 17px auto 0;
    color: $secondary-color;

    .header__wrapper {
      padding-top: 68%;
    }

    a {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .main-header {
    display: flex;
    min-height: 68px;
    border-bottom: $main-border;
    z-index: 2;

    .main-header__wrapper {
      display: flex;
      padding-left: 30px;
      padding-top: 12px;
      padding-right: 10px;
      padding-bottom: 10px;
      width: 100%;
      height: auto;
      justify-content: space-between;
      align-items: center;

      @include mq($mobile) {
        flex-direction: column;
        gap: 20px;
        // justify-content: left;
      }
    }
    .main-header-info-logo {
      width: 170px;
      height: 30px;
      position: relative;
    }
    .main-header__content {
      display: flex;
      gap: 22px;
      align-items: center;

      @include media-query($w600) {
        display: none;
      }
    }

    .main-header__content-title {
      text-transform: uppercase;
      color: $secondary-color;
    }

    .main-header__content-date {
      font-weight: 600;
      color: $primary-color;
      background-color: $secondary-color;
      text-align: center;

      padding-top: 12px;
      padding-bottom: 11px;
      border-radius: 5px;
      width: 112px;
    }

    .main-header__icons {
      display: flex;
      align-items: center;
      // position: absolute;
      right: 10px;
      // top: 50%;
      // transform: translate(0, -50%);
      gap: 19px;
      .switch-mode {
        border: none;
        border-radius: 100%;
        background: transparent;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        width: 30px;
        height: 30px;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .main-header__icons-profile {
        border: none;
        background-color: $primary-bg-color;
        border-radius: 50%;
        border-color: transparent;
        width: 45px;
        height: 45px;
        .main-header__icons-profile-login {
          a {
            text-decoration: none;
          }
          opacity: 0;
          visibility: hidden;
          transition: $transition-duration;
          background: linear-gradient(white, white) padding-box,
            linear-gradient(
                180deg,
                rgba(159, 56, 210, 1) 0%,
                rgba(51, 127, 208, 1) 100%
              )
              border-box;
          border: 1px solid transparent;
        }

        .main-header__icons-profile-login.active {
          opacity: 1;
          visibility: visible;
        }

        &:hover,
        &:focus,
        &:active {
          .main-header__icons-profile-login {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .main-header-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 46px;
        height: 46px;
        border-radius: 50%;
        padding: 0;
        color: revert;
        background-color: unset;

        svg {
          path {
            fill: revert-layer;
          }
        }

        &:hover,
        &:focus,
        &:active {
          color: $primary-hover-color;
        }

        .main-header__icons-messages-bubble {
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          right: 0;
          top: 0;
          min-height: 23px;
          min-width: 23px;
          border: $main-border;
          border-radius: 50%;
          background-color: $primary-bg-color;
          transform: translate(50%, -25%);
          color: $primary-color;
          font-size: $font-size-7;
          letter-spacing: -0.02em;
        }
      }

      .main-header__icons-profile-login {
        position: absolute;
        top: 100%;
        right: 0;
        border: $main-border;
        background-color: $primary-bg-color;
        border-radius: $main-border-radius;
        z-index: 2;
      }

      .main-header__icons-profile-login-row {
        display: flex;
        justify-content: flex-start;
        gap: 11px;
        padding: 15px 14px 11px 17px;
        width: auto;
        border-radius: 0;
        cursor: pointer;
        p {
          text-transform: uppercase;
          text-align: left;
        }

        svg {
          path {
            fill: revert-layer;
          }
        }
      }

      .main-header__icons-profile-login-label {
        font-size: $font-size-5;
        font-weight: 700;
      }
    }
  }

  .footer {
    color: $secondary-color;
    .footer__main {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @include media-query($small) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }

      .footer__main_telegram_link {
        position: absolute;
        right: 15px;
        bottom: 10px;
      }
    }

    .footer__main-info {
      display: flex;
      flex: 1 1 auto;
      align-items: center;
      gap: 87px;

      @include media-query($small) {
        width: 100%;
        justify-content: center;
        gap: 40px;
      }

      @include media-query($extra-small) {
        flex-direction: column;
        gap: 20px;
        align-items: center;
        text-align: center;
      }
    }

    .footer__main-info-logo {
      width: 170px;
      height: 30px;

      @include media-query($extra-small) {
        padding-top: 100px;
      }

      .logo-link {
        z-index: 1;
      }
    }
    .footer__main-about-wrapper {
      .footer__main-about-title {
        margin-bottom: 13px;
      }
      .footer__main-about-list {
        display: flex;
        gap: 30px;
        list-style: none;
        .footer_main-about-item:not(:last-child) {
          margin-bottom: 4px;
        }
        .footer_main-about-link {
          color: $secondary-color;
          text-decoration: none;
        }
      }
    }

    .footer__main-links-item {
      display: block;
      margin-bottom: 5px;
      font-size: $font-size-7;
      line-height: 1.2;
    }
    .footer__main-right-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .footer__main-social-links {
      display: flex;
      align-items: flex-end;
      gap: 16px;

      @include media-query($small) {
        margin-bottom: 20px;
      }

      .link {
        height: fit-content;
        color: $secondary-color;
      }
    }

    .footer__copyright {
      width: 100%;
      display: block;
      text-align: center;
      color: $secondary-color;
    }
  }
}

.profile-page {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.list-menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  transform: matrix(1, 0, 0, -1, 0, 0);
  opacity: 0;
  visibility: hidden;
  transition: opacity, visibility var(--duration-default, 0.3s);
  z-index: -1;

  &[aria-hidden="false"] {
    opacity: 0.5;
    visibility: visible;
    z-index: 11;
  }
}

.visually-hidden {
  position: absolute !important;
  overflow: hidden;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  clip: rect(0 0 0 0);
  word-wrap: normal !important;
}

.m-auto {
  margin: auto;
}

.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}

.shared_links {
  line-height: 2;
  padding-top: 40px;
}

.footer__main {
  .footer__main_telegram_link {
    position: fixed;
    right: 15px;
    bottom: 10px;
  }
}

.text-page {
  line-height: 1.5;
  font-size: initial;
  p {
    margin-bottom: 20px;
  }
  ol li {
    margin-bottom: 10px;
  }
}
.dark-profile {
  // background: $dark-profile-page-background;
  // background-color: $dark-profile-page-background;
  // color: $dark-profile-page-text;
  .main-header {
    background: #181432;
    border-color: $border-color;
  }
  .main-header__content-date {
    background: transparent !important;
    color: $secondary-color !important;
    font-size: 21px !important;
letter-spacing: 0.84px !important;
width: fit-content !important;
  }
  .main-header__content-title {
    color: rgba(225, 223, 236, 0.50) !important;
font-size: 14px;
font-weight: 550;
letter-spacing: 0.14px;
text-transform: none !important;
  }
  .main-nav {
    background: #181432;

  }
  .profile-section_wrapper {
    background: linear-gradient(
      293deg,
      #6788ff 31.56%,
      rgba(129, 255, 225, 0.75) 97.92%
    ) !important;
  }
  .profile-section__content-top {
    background: transparent;
    // background-color: unset;
    color: $dark-profile-page-text;
  }
  .profile-section__content-bottom {
    background: transparent;
    // background-color: unset !important;
    color: $dark-profile-page-text;
  }
  .main-header-info-logo {
	svg {
		width: 100%;
	}
  }
  .main-header__icons {
    & .main-header__icons-profile:nth-child(2) {
      svg {
        path {
          fill: rgba(68, 85, 145, 1) !important;
        }
      }
    }
    & .main-header__icons-profile:nth-child(3) {
      svg {
        path:first-of-type {
          fill: rgba(68, 85, 145, 1) !important;
        }
      }
    }
  }
  .main-header__icons-profile-login {
    .main-header-icon {
      &:hover,
      &:focus {
        .main-header__icons-profile-login-label {
          color: red;
        }
      }
    }
    .main-header__icons-profile-login-label {
      color: rgba(68, 85, 145, 1);
    }
  }
}
