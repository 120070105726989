.binary-chart-loader-wrapper {
    width: 100%;
    height: 100%;
    @include flex-center;
    flex-direction: column;
    @include mq($mobile) {
      min-height: 320px;
    }
    justify-content: center;

  }
  .binary-chart-loader-wrapper.extended {
    min-height: 600px;
  }