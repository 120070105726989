//Colors
$primary-color: #4d4d4d;
$secondary-color: #fff;
$ambient-white: #D9D9D9;
$purple-outline: #7b57c2;
$purple-accent: #9710FE;
$purple-light: #9D1CEA;
$purple-bg-transparent: rgba(214, 167, 250, 0.3);
$currency-card-purple-bg: rgba(103, 46, 167, 0.20);
$alternative-color: #000;
$primary-hover-color: purple;
$primary-error-color: #ff0000;
$primary-border-color: transparent;
$main-page-black: #313131;
$binary-green: #67e6a8;
$binary-red: #eb5757;
$course-drop: #db4d2e;
//Background Colors
$primary-bg-color: #fff;
$secondary-bg-color: black;




// new profile page colors
$layout-bg: #181432;
$body-bg: #0B091A;
$purple-contrast: #7D67FF;
$purple-lightened: #A090FF;
$dark-text: #0D0B1C;
$block-dark-bg: #131024;
$success-green: #6FDA72;
$failure-red: #B50909;
$border-color: rgba(193, 189, 212, 0.12);


//Gradients
$common-background-gradient: linear-gradient(
  180deg,
  rgba(159, 56, 210, 1) 0%,
  rgba(51, 127, 208, 1) 100%
);
$common-background-gradient-transparent: linear-gradient(
  180deg,
  rgba(159, 56, 210, 0.7) 0%,
  rgba(51, 127, 208, 0.7) 100%
);
$common-background-gradient-border: linear-gradient(white, white) padding-box, linear-gradient(180deg, #9f38d2 0%, #337fd0 100%) border-box;
$pro-gradient: linear-gradient(
  90deg,
  rgba(63, 94, 251, 1) 0%,
  rgba(252, 70, 107, 1) 100%
);

//Fonts
$primary-font: "Roboto", sans-serif;
$secondary-font: "Montserrat", sans-serif;
$alternative-font: "PT Mono", monospace;
$icon-font: "Font Awesome", sans-serif;

// dark-mode-colors
$dark-background: linear-gradient(180deg, #10081a 0%, #10081a 100%),
  linear-gradient(0deg, #10081a 15.15%, #10081a 100%);
$dark-theme-text-primary: #fff;
$dark-theme-text-secondary: #acacac;
$dark-profile-page-background: #0d0b1c;
$dark-profile-page-text: rgba(255, 255, 255, 1);
$dark-profile-gradient: linear-gradient(
  293deg,
  #6788ff 31.56%,
  rgba(129, 255, 225, 0.75) 97.92%
);
$dark-profile-gradient-transparent: linear-gradient(
  293deg,
  rgba(103, 136, 255, 0.2) 31.56%,
  rgba(129, 255, 225, 0.2) 97.92%
);
//Sizes
$container-width: 1280px;
$content-small-wrapper: 630px;
$content-medium-wrapper: 978px;
$main-border: 1px solid $secondary-color;
$main-border-radius: 10px;

//Font Sizes
$primary-font-size: 14px;
$font-size-1: 30px;
$font-size-2: 26px;
$font-size-3: 24px;
$font-size-4: 18px;
$font-size-5: 16px;
$font-size-6: 15px;
$font-size-7: 13px;
$font-size-8: 12px;
$font-size-9: 10px;
$font-size-10: 5px;

//Font families
$first-family: "Roboto", sans-serif;
$second-family: "Montserrat", sans-serif;
$third-family: "Font Awesome 5 Brands", sans-serif;

//Additional variables
$transition-duration: 300ms;
$transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

/*============================================================================
  Grid Breakpoints and Class Names
    - Do not change the variable names
==============================================================================*/
$grid-extra-small: 400px;
$grid-medium: 768px;
$grid-large: 990px;
$grid-extra-large: 1200px;
$grid-widescreen: 1440px;
$grid-extra-widescreen: 1600px;

$extra-small: "extra-small";
$small: "small";
$small-up: "small-up";
$small-mid: "small-mid";
$medium: "medium";
$medium-mid: "medium-mid";
$medium-down: "medium-down";
$medium-up: "medium-up";
$large: "large";
$large-down: "large-down";
$large-up: "large-up";
$extra-large: "extra-large";
$extra-large-down: "extra-large-down";
$extra-large-up: "extra-large-up";
$widescreen: "widescreen";
$extra-widescreen: "extra-widescreen";

$w1100: "w1100";
$w1050: "w1050";
$w1000: "w1000";
$w950: "w950";
$w900: "w900";
$w850: "w850";
$w800: "w800";
$w750: "w750";
$w700: "w700";
$w650: "w650";
$w600: "w600";
$w550: "w550";
$w500: "w500";
$w450: "w450";
$w400: "w400";
$w320: "w320";

// The `$breakpoints` list is used to build our media queries.
// You can use these in the media-query mixin.
$breakpoints: (
  $w1100 "(max-width: 1100px)",
  $w1050 "(max-width: 1050px)",
  $w1000 "(max-width: 1000px)",
  $w950 "(max-width: 950px)",
  $w900 "(max-width: 900px)",
  $w850 "(max-width: 850px)",
  $w800 "(max-width: 800px)",
  $w750 "(max-width: 750px)",
  $w700 "(max-width: 700px)",
  $w650 "(max-width: 650px)",
  $w600 "(max-width: 600px)",
  $w550 "(max-width: 550px)",
  $w500 "(max-width: 500px)",
  $w450 "(max-width: 450px)",
  $w320 "(min-width: 320px) and (max-width: 400px)",

  $extra-small "(max-width: #{$grid-extra-small - 1})",
  $small "(max-width: #{$grid-medium - 1})",
  $small-mid "(max-width: 560px)",
  $small-up "(min-width: 669px) and (max-width: 767px)",
  $medium "(min-width: #{$grid-medium}) and (max-width: #{$grid-large - 1})",
  $medium-mid "(max-width: 900px)",
  $w800 "(max-width: 800px)",
  $w850 "(max-width: 850px)",
  $medium-down "(max-width: #{$grid-large - 1})",
  $medium-up "(min-width: #{$grid-medium})",
  $large "(min-width: #{$grid-large}) and (max-width: #{$grid-extra-large - 1})",
  $large-down "(max-width: #{$grid-extra-large - 1})",
  $large-up "(min-width: #{$grid-large})",
  $extra-large
    "(min-width: #{$grid-extra-large}) and (max-width: #{$grid-widescreen - 1})",
  $extra-large-down "(max-width: #{$grid-widescreen - 1})",
  $extra-large-up "(min-width: #{$grid-extra-large})",
  $widescreen "(min-width: #{$grid-widescreen})",
  $extra-widescreen "(min-width: #{$grid-extra-widescreen})"
);

// list of devices for media queries
$mobile: "mobile";
$extra-small: "extra-small";
$tablet: "tablet";
$laptop: "laptop";
$desktop: "desktop";
$widescreen: "widescreen";
$large-down: "large-down";
$medium-down: "medium-down";
$medium-up: "medium-up";
$extra-small-down: "extra-small-down";
