.restore-pass-page {
  min-height: 100vh !important;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 99px;
  padding-bottom: 88px;
  .login-page__logo-wrapper {
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    max-width: 700px;
    img {
      width: 100%;
    }
    @include mq($medium-down) {
      max-width: 70%;
    }
  }
  .pass-section__form-confirm-code {
    width: 305px;
    margin-left: auto;
    margin-right: auto;
    .code-inputs {
      display: flex;
      gap: 13px;
    }
  }
  .form-container {
    width: 100%;
  }
  .form__main-content {
    display: flex;
    justify-content: center;
    gap: 16px;

    label {
      padding: 0;
      margin-bottom: 8px;
    }

    input {
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;
      border-radius: $main-border-radius;
      width: 100%;
    }
    .js-send-btn {
      background: $common-background-gradient;
      width: 100%;
      justify-content: center;
      margin: 0;
    }
  }

  .pass-section__headings-wrapper {
    text-align: center;
    margin-bottom: 22px;
  }

  .pass-section__heading {
    margin: 0;
    margin-bottom: 9px;
    font-size: 26px;
  }

  .pass-section__side {
    display: flex;
    flex-direction: column;
    min-width: 305px;
    justify-content: center;
    align-items: center;
    gap: 15px;
  }
}

.temporary-page {
  @extend .restore-pass-page;
  // background-image: url("../../images/solid-concrete-wall-textured-backdrop-min.jpg");
  // background-size: cover;
  height: 100vh;

  color: $secondary-color;

  .btn {
    width: 100%;
    justify-content: center;
    margin: 0;
  }
  .btn:first-of-type {
    background: $common-background-gradient;
    border: none;
  }
  .btn:last-of-type {
    background-color: transparent;
    border: 1px solid violet;

    &:hover,
    &:focus {
      background: $common-background-gradient;
      border: 1px solid transparent;
    }
  }
}

.temporary-page-header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 30px;
  padding-top: 12px;
  padding-right: 10px;
  padding-bottom: 10px;
  z-index: 2;
  // background-color: transparent;
.custom-select-wrapper {
  margin-bottom: 0;
}
  .main-header-info-logo {
    position: relative;
    width: 170px;
    height: 30px;
    a {
      display: block;
      height: 30px;
    }
    svg {
      path {
        fill: $secondary-color;

      }
    }
  }
  .page-header-right-side {
    display: flex;
    align-items: center;
    gap: 10px;

  }
  .custom-select-wrapper {
    width: initial;
  }
  .custom-select-pages {
    background: transparent;
    border: 1px solid violet;
    border-radius: 20px;

    ul {

      bottom: -50px;
      
      border-radius: $main-border-radius;
      background: $common-background-gradient;
      width: 140px;
      height: 40px;
      display: flex;
      align-items: center;
      transform: translateX(-80px);
      
      button {
        color: $secondary-color;
      }
    }
    
    span {
      color: $secondary-color;
    }
    svg {
      path {
        fill: $secondary-color;
      }
    }
  }
  .btn {
        background: $common-background-gradient;
    border: none;
  }
}
.dark-profile {
  background: $body-bg;
  background-color: $body-bg;
  color: $dark-profile-page-text;

  .form__main-content {
    label {
      color: $dark-profile-page-text;
    }
    .js-send-btn {
      background: linear-gradient(293deg, #6788FF 31.56%, rgba(129, 255, 225, 0.75) 97.92%);
      border: none;

      &:hover, &:focus {
            background: linear-gradient(180deg, #9f38d2 0%, #337fd0 100%);
      }
    }
  }
}