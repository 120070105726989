.binary-option-section {
  margin-bottom: 40px;
  .binary-options-block {
    @include mq($medium-up) {
      @include flex-center;
      gap: 14px;
    }
    .left-side {
      flex-grow: 2;
    }
  }
  .binary-auction-wrapper {
    padding-left: 27px;
    padding-right: 27px;
    padding-top: 21px;
    padding-bottom: 40px;
    background: $common-background-gradient-transparent;
    // box-shadow: 0px 0px 60px 0px rgba(194, 71, 193, 0.6) inset;
    backdrop-filter: blur(2.5px);
    border-radius: $main-border-radius;
    @include mq($medium-down) {
      margin-bottom: 20px;
    }
    @include mq($extra-small-down) {
      padding: 5px;
    }
  }
  .binary-top-side {
    .trading-intervals-list {
      @include mq($mobile) {
        gap: 5px;
        flex-wrap: wrap;
      }
      @include flex-center;
      width: 100%;
      gap: 10px;
      margin-bottom: 15px;
      button {
        @include flex-center;
        background: transparent;
        border-radius: $main-border-radius;
        border: 1px solid #4981bc;
        justify-content: center;
        width: 53px;
        height: 28px;
        color: #c2c2c2;
        text-align: center;
        font-size: 13px;
        font-weight: 550;
        &:hover,
        &:focus {
          background: $common-background-gradient;
        }
      }
    }
  }
  .tradingview-container {
    @include mq($medium-down) {
      padding: 15px;
    }
    padding: 30px;
    background: rgba(99, 69, 120, 0.33);
    border-radius: $main-border-radius;
    border: 1px solid $alternative-color;
  }

  .binary-right-side {
    position: relative;
    .coming-soon-banner {
      position: absolute;
      z-index: 999;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      @include flex-center;
      justify-content: center;
      span {
        position: absolute;
        display: block;
        font-size: 42px;
      }

      backdrop-filter: blur(3px);
    }
    pointer-events: none;

    .controls-block {
      @include mq(tablet) {
        @include flex-center;
        gap: 40px;
      }
    }
    .pair-switcher-wrapper {
      margin-bottom: 10px;
      .heading {
        display: block;
        margin-bottom: 5px;
        padding-left: 17px;
      }
      .pair-selection-dropdown-wrapper {
        border-radius: 60px;
        border: 1px solid $alternative-color;
        padding-left: 17px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;
        width: 100%;
        display: flex;
        gap: 20px;
      }
      .toggle-dropdown {
        background: transparent;
        border: none;
      }
      .toggle-dropdown.expanded {
        svg {
          transform: rotate(180deg);
        }
      }
      .pair.selected {
        background: $binary-green;
      }
      .pair-selection-dropdown {
        position: absolute;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        top: 100%;
        left: 0;
        border-radius: 20px;
        border: 1px solid $alternative-color;
        padding-left: 17px;
        padding-right: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        // opacity: 0.9;
        backdrop-filter: blur(30px);
        button {
          width: 100%;
          border: none;
          background: transparent;
        }
      }
    }
    .options-balance-list {
      list-style: none;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 400;
      letter-spacing: -0.4px;
      span {
        font-weight: 700;
      }
    }
    .percentage-block {
      @include flex-center;
      margin-bottom: 6px;
      justify-content: space-between;
      p {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
      }
      .percentage {
        @include flex-center;
        border-radius: 5;
        background: $binary-green;
        color: #252525;
        font-size: 18.75px;
        font-weight: 700;
        border-radius: 5px;
        padding-left: 14px;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-right: 8px;
        width: fit-content;
      }
    }
    .binary-transfer-button {
      @include flex-center;
      justify-content: center;
      margin-bottom: 20px;
      background: $common-background-gradient;
      width: 100%;
      padding-top: 15px;
      padding-bottom: 17px;
      color: $secondary-color;
      font-size: 15px;
      font-weight: 550;
      line-height: 122.2%; /* 18.33px */
      border-radius: 50px;
      border: none;
      &:hover,
      &:focus {
        background: black;
      }
    }
    .time-investment-controls-wrapper {
      @include mq($medium-down) {
        gap: 10px;
        margin-bottom: 10px;
      }
      display: flex;
      flex-direction: column;
      gap: 15px;
      margin-bottom: 10px;
    }
    .controls-wrapper {
      label {
        color: $primary-color;
        font-size: 16px;
        font-weight: 550;
        padding-left: 17px;
      }
      .controls-input-wrapper {
        @include flex-center;
        margin-top: 11px;
        border-radius: 60px;
        padding-right: 17px;
        padding-left: 10px;
        padding-top: 10px;
        padding-bottom: 10px;
        border: 1px solid #80807f;
      }
      button {
        background: $common-background-gradient;
        color: $primary-color;
        border-radius: 42px;
        height: 34px;
        border: none;
        width: 59px;
        &:hover,
        &:focus {
          background: black;
          color: $secondary-color;
        }
      }
      input {
        width: 100%;
        background: transparent;
        color: $primary-color;
        border: none;
        margin-left: 30px;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        appearance: none;
        margin: 0; /* Optional */
      }
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .betting-controls-wrapper {
      display: flex;
      flex-direction: column;
      gap: 8px;
      width: 100%;
      @include mq($medium-down) {
        gap: 10px;
      }
      .betting-button {
        text-transform: uppercase;
        @include flex-center;
        position: relative;
        justify-content: center;
        height: 60px;
        width: 100%;
        border-radius: $main-border-radius;
        border: none;
        background: $binary-green;
        color: $primary-color;
        font-size: 17px;
        font-weight: 700;
        &:hover,
        &:focus {
          box-shadow: 0 0 10px 5px greenyellow;
          outline: none;
        }
        span {
          position: absolute;
          @include flex-center;
          justify-content: center;
          right: 20px;
          width: 41px;
          height: 34px;
          top: 50%;
          transform: translateY(-50%);
          background: rgba(0, 115, 80, 0.2);
          border-radius: 12px;
        }
      }
      .betting-button.red {
        background: $binary-red;
        &:hover,
        &:focus {
          box-shadow: 0 0 10px 5px red;
        }
      }
      .payout-block {
        @include flex-center;
        justify-content: center;
        font-size: 20px;
        font-weight: 550;
        line-height: normal;
        gap: 10px;
        p {
          display: block;
        }
        span {
          display: block;
          font-weight: 700;
        }
      }
    }
  }
}

.dark-profile {

  .binary-auction-wrapper {
    box-shadow: 0px 0px 60px 0px rgba(129, 255, 225, 0.75) inset;
    background: $dark-profile-gradient-transparent;
  }
  .time-investment-controls-wrapper {
    .controls-wrapper {
      label,
      input {
        color: $secondary-color;
      }
    }
  }
}
