.transactions-section__table-td-wrapper {
  display: flex;
  justify-content: center;

  font-size: 14px;
  font-weight: 700;
  padding-bottom: 14px;

  @include media-query($small) {
    font-size: $font-size-9;
  }

  @include media-query($extra-small) {
    font-size: 7px;
  }
}
.transactions-section__table-wrapper {
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 15px;
    @include media-query($extra-small) {
      font-size: $font-size-9;
    }
  }
}
thead tr.transaction-section__table-heading-row {
  ::after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    height: 1px;
    background-color: $border-color;
    border-radius: $main-border-radius;
  }
}

.transaction-section__table {
  th {
    font-size: 13px;
    text-transform: uppercase;
    span {
      font-weight: 600;
    }
  }
  td {
    width: 20%;
    text-align: center;
    padding-top: 19px;
    padding-bottom: 19px;
    &:first-of-type {
      text-align: left;
    }
  }
  td.pending {
    color: rgba(189, 173, 25, 1);
  }
  td.approved {
    color: rgba(62, 221, 135, 1);
  }
  td.rejected {
    color: rgba(234, 42, 76, 1);
  }
  span:first-of-type {
    font-weight: 700;
  }
}
.transaction-section__table-body-row {
  font-weight: 700;
  position: relative;

  & td:first-child {
    font-weight: 500;
    text-align: center;
  }

  // &::after {
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   content: "";
  //   width: 100%;
  //   height: 1px;
  //   background-color: #d4d4d4;
  //   border-radius: $main-border-radius;
  // }
}
