.withdrawal-page {
  .page__sections-wrapper.medium-wrapper {
    min-height: auto;
  }
  .medium-wrapper {
    padding: 0;
  }
  .profile-section__widthdrawal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    width: 183px;
    height: 42px;
    border: none;
    overflow: hidden;
    background: linear-gradient(
      360deg,
      #337fd0 0%,
      #3f77b3 0.01%,
      #6927a4 53%,
      #9f38d2 100%
    );
    background-size: 300% 300%;
    background-position: 100% 220%;
    border: 1px solid transparent;
    border-radius: 10px;
    color: $secondary-color;
  }
  .range__list {
    padding: 0;
    margin-bottom: 6px;
  }

  .form-container.form-container--range input[type="range"] {
    -webkit-appearance: none;
    position: relative;
    opacity: 0.9;
    // background-color: #676767;
    background: linear-gradient(
          to right,
          #337fd0 0%,
          #3f77b3 0.01%,
          #6927a4 53%,
          #9f38d2 100%
        )
        no-repeat,
      #676767; /* Set default background color */
  }
  .range-points-container {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 55%;
    width: 100%;
    list-style: none;

    @include media-query($small) {
      top: 32%;
    }
    .rangePoint {
      width: 6px;
      height: 6px;
      background-color: #ff81fe;
      border-radius: 50%;
      position: absolute;
      z-index: 1;
      top: 0;
    }

    .rangePoint.selected {
      opacity: 0.3;
    }
    .rangePoint.transparent {
      background-color: transparent; /* Change color for selected points */
    }
  }
  .range-points-container.withdraw {
    top: 34%;
    transform: translateY(-10%);
    z-index: 333;
  }
  .form-container.form-container--range
    input[type="range"]::-webkit-slider-thumb {
    position: relative;
    z-index: 77;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: linear-gradient(
      360deg,
      #337fd0 0%,
      #3f77b3 0.01%,
      #6927a4 53%,
      #9f38d2 100%
    );
    border-radius: 100%;
    opacity: 1;
    z-index: 2;
  }
  .withdrawal-section {
    width: 100%;
    color: $primary-color;

    @include media-query($small) {
      margin-top: 30px;
      margin-bottom: 30px;
    }
    .withdrawal-section__main-heading {
      font-weight: 500;
      @include mq($mobile) {
        margin: 0;
        margin-bottom: 10px;
      }
    }
    .form-check-from-wrap {
      margin-bottom: 25px;
    }
    .form-container.form-container--range {
      margin-bottom: 15px;
    }
    .withdrawal-section__wrapper {
      display: flex;
      gap: 60px;
      // padding: 45px 27px;
      border: $main-border;
      border-radius: $main-border-radius;
      z-index: 1;
      margin-bottom: 30px;

      @include media-query($small) {
        flex-direction: column;
        gap: 20px;
      }

      .main {
        min-height: 580px;
      }

      table {
        width: 100%;
        border-collapse: collapse;

        @include media-query($extra-small) {
          font-size: $font-size-9;
        }
      }

      th {
        text-align: center;
        // padding-right: 17px;

        @include media-query($small) {
          padding-right: 3px;
        }
      }

      .withdrawal-section__table-body {
        @include media-query($small) {
          font-size: 10px;
        }
      }

      .withdrawal-section__table-td-wrapper {
        justify-content: center;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-7;
        font-weight: 600;

        span:last-child {
          font-size: inherit;
        }
      }

      .withdrawal-section__table-body-row {
        font-weight: 700;

        td {
          padding: 13px 17px 13px 0;
          text-align: center;

          @include media-query($small) {
            padding: 3px 7px 3px 0;
            font-size: $font-size-9;
          }
        }
      }
    }

    .withdrawal-section__table-heading-row {
      &:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        height: 4px;
        width: 100%;
        border-radius: $main-border-radius;
        background-color: $secondary-bg-color;
      }
    }

    .withdrawal-section__table-td-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      font-size: $font-size-7;
      text-transform: uppercase;
      font-weight: 600;
      padding-bottom: 14px;

      @include media-query($small) {
        font-size: $font-size-9;
      }

      @include media-query($extra-small) {
        font-size: 7px;
      }
    }

    .withdrawal-section__body-heading {
      padding-left: 16px;
      padding-bottom: 7px;
      font-size: $primary-font-size;
      font-weight: 500;
    }

    .withdrawal-section__invoice-network-heading {
      margin-bottom: 15px;
      font-weight: 500;
    }

    .withdrawal-section__invoice-network-wrapper {
      display: flex;
      gap: 21px;
    }

    .form-container--checkbox-btn {
      input:checked ~ label {
        background-color: $primary-hover-color;
        border-color: $primary-hover-color;
      }

      label {
        padding: 7px 12px;
        font-family: $alternative-font;
        font-size: $font-size-8;
        font-weight: 400;
        color: $alternative-color;

        &:before {
          content: unset;
        }

        &:after {
          content: unset;
        }
      }
    }

    .form-container--range {
      input {
        min-width: unset;
      }

      .range__list {
        padding-top: 38px;

        @include media-query($small) {
          padding-top: 8px;
        }
      }

      .range__list-item {
        &:after {
          z-index: 2;
        }

        &:not(.range__list-item:first-child) {
          @include media-query($extra-small) {
          }

          &:after {
            content: unset;
          }
        }


      }

      .min-max__range-item-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: 16px;
        font-family: $alternative-font;
        font-size: $font-size-7;
      }
    }

    .form-container--wallet {
      // margin-top: 27px;
      margin-bottom: 18px;

      @include media-query($small) {
        margin-top: 10px;
      }

      input {
        min-width: unset;

        &::placeholder {
          font-weight: 500;
          color: $primary-error-color;
        }
      }
    }
    .form-check-from-item {
      &:last-of-type {
        height: 100%;
        button {
          .icon {
            left: 110px;
            @include mq($mobile) {
              left: 0;
            }
          }
        }
      }
    }

    .withdrawal-section__description {
      margin-top: 16px;
      font-size: $font-size-9;
      font-weight: 500;
      line-height: 1.8;
    }

    .tabs__navigation {
      background: #eaeaea;
    }

    .custom-select {
      z-index: 3;

      .icon {
        left: 15px;
        right: auto;
        width: 20px;
        height: 10px;
        color: $alternative-color;
      }

      .custom-select__dropdown {
        padding: 8px 11px 8px 20px;

        border-radius: $main-border-radius;
        background: #eaeaea;
      }

      .custom-select__btn-text-wrapper {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .custom-select__dropdown-button {
        display: flex;
        // border-bottom: $main-border;
        padding-bottom: 8px;
        padding-left: 23px;
      }

      .custom-select__dropdown-option {
        &:last-child {
          .custom-select__dropdown-button {
            border-bottom: unset;
          }
        }
      }

      .custom-select__btn {
        padding: 8px 15px 8px 43px;
        border: $main-border;
        border-radius: $main-border-radius;
        background: #eaeaea;

        svg {
          path {
            stroke: $primary-color;
          }
        }
      }

      .custom-select__btn-text {
        flex: 0 1 46%;
        font-size: $font-size-6;
        font-weight: 700;
      }

      .custom-select__btn-money {
        position: relative;
        display: flex;
        min-height: 46px;
        flex-direction: column;
        justify-content: center;
        flex: 0 1 50%;
        padding-left: 20px;

        &:before {
          position: absolute;
          content: "";
          width: 1px;
          height: 100%;
          left: 0;
          top: 0;
          background-color: $primary-color;
        }

        .custom-select__btn-money-local {
          font-family: $alternative-font;
          font-size: $font-size-4;
          font-weight: 400;
          margin-bottom: 2px;

          @include media-query($extra-small) {
            font-size: $font-size-6;
          }
        }

        .custom-select__btn-money-universal {
          font-weight: 500;

          @include media-query($extra-small) {
            font-size: $font-size-8;
          }
        }
      }
    }

    .withdrawal-section__wrapper-side {
      // flex: 0 1 430px;
      display: flex;
      justify-content: space-between;

      @include media-query($small) {
        flex: 1;
      }

      &:last-child {
        flex: 0 1 320px;
        padding-top: 32px;

        @include media-query($small) {
          padding-top: 0;
          flex: 1;
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
        }
      }
    }

    .withdrawal-section__balance {
      .withdrawal-section__balance-heading {
        margin-bottom: 2px;
        font-size: 19px;
        font-weight: 500;
      }

      .withdrawal-section__balance-money {
        font-family: $alternative-font;
        font-size: $font-size-6;
        font-weight: 400;
      }
    }

    .withdrawal-section__balance--full {
      // margin-bottom: 50px;

      @include media-query($small) {
        margin-bottom: 0;
      }
    }

    .withdrawal-section__balance--available {
      // margin-bottom: 70px;

      @include media-query($small) {
        margin-bottom: 0;
      }
    }

    .withdrawal-section__add-deposit {
      font-weight: 500;
      font-size: $font-size-9;
      line-height: 1.8;

      .form-container--checkbox {
        margin-bottom: 9px;

        label {
          font-size: inherit;
          font-weight: inherit;

          &:before {
            border-radius: 3px;
            margin-right: 9px;
          }
        }
      }
    }
  }
}

.ballance-page {
  .profile-section__content-top-side-wrap {
    padding: 20px;
    border: 1px solid $primary-color;
    border-radius: 40px 40px 20px 20px;
    min-height: 407px;
    background: linear-gradient(
      360deg,
      #337fd0 0%,
      #3f77b3 0.01%,
      #6927a4 53%,
      #9f38d2 100%
    );
    color: $secondary-color;
    margin-bottom: -50px;
  }
  .profit_currencies-title {
    font-size: 18px;
  }
  .profile-section__client-btc-info .profile-section__client-btc-info-balance {
    font-size: 16px;
  }
}

.binary-right-values-wrap {
  display: flex;
  justify-content: space-around;
}

.tabs-with-dropdown {
  display: flex;
  background: #eaeaea;
  // flex: 1 1;
  & > * {
    width: 50%;
  }
  margin-bottom: 25px;
  border-radius: 10px;
  .tabs__navigation {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border: 1px solid;
    .custom-select__btn {
      border: none !important;
    }
    .custom-select {
      padding-top: 17px;
      padding-bottom: 12px;
    }
  }

  .tabs-with-dropdown-input {
    background-color: transparent;
    border: 1px solid;
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    height: 65px;
    input {
      padding: 8px 15px 8px 43px;
      background-color: transparent;
      color: $primary-color;
      line-height: 1;
      height: 100%;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border: none;
      outline: none;
    }
  }
}

.dark-profile {
  
  .withdrawal-page {
    .custom-select__btn-text {
      color: $dark-profile-page-background !important;
    }
    .withdrawal-section {
      background: $block-dark-bg;
    }
  }
  h3,
  p,
  span {
    color: $secondary-color;
  }
  .next-btn {
    background: $purple-contrast;
    color: $secondary-color;
  }
  .tabs-with-dropdown-input input[type="number"] {
    color: $alternative-color !important;
  }
}
