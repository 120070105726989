.restore-pass-page--new-pass {
  gap: 12px;

  .pass-section__side {
    gap: 20px;
  }

  .btn {
    margin-top: 10px;
  }
}