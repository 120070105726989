.next-btn {
  @include mq($mobile) {
    width: 100%;
  }
    border: none;
    text-align: center;
    width: 220px;
    border-radius: 10px;
    color: $primary-color;
    font-size: 18px;
    font-weight: 700;
    line-height: 120%; /* 21.6px */
    padding-top: 13px;
    padding-bottom: 12px;

    &:hover, &:focus {
      color: $secondary-color;
      background-color: $primary-color;
    }
  }