.details-page {
  .sessions-section {
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 27px 27px 11px 46px;

    @include media-query($small) {
      padding: 10px;
    }

    .sessions-section__table-body {
      @include media-query($small) {
        font-size: 10px;
      }
    }

    .sessions-section__table-wrapper {
      margin-bottom: 12px;

      th {
        @include media-query($extra-small) {
          padding-right: 7px;
        }
      }
    }

    .sessions-section__table-td-wrapper {
      justify-content: center;
      text-align: center;
      text-transform: uppercase;
      font-size: $font-size-7;
      font-weight: 600;

      span:last-child {
        font-size: inherit;

      }
    }

    .sessions-section__table-body-row {
      text-align: center;

      &:nth-child(2) {
        td {
          padding: 17px 17px 16px 0;
        }
      }

      td {
        padding: 22px 17px 16px 0;
        width: 21%;
        text-align: center;
        text-transform: uppercase;
      }
    }

    .sessions-section__footer-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .sessions-section__controls {
      @include media-query($small) {
        gap: 10px;
      }

      .sessions-section__controls-buttons {
        @include media-query($small) {
          gap: 10px;
        }
      }
    }

    .sessions-section__footer-settings {
      display: flex;
      align-items: center;
      gap: 30px;
      font-size: $font-size-7;
      text-transform: uppercase;

      @include media-query($small) {
        gap: 10px;
      }

      .custom-select__btn {
        padding: 0;
      }
    }
  }

  .modal-dialog--warning {
    max-width: 322px;
    max-height: 342px;
    padding: 15px;

    .modal-dialog__body {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      text-align: center;
    }

    .modal-dialog__warning-icon {
      margin: 25px auto 57px;
    }

    .price-with-currency {
      font-size: $font-size-2;
      text-transform: uppercase;
    }
  }
  .algorithms-history {
    margin-top: 85px;
    margin-bottom: 150px;

    @include media-query($extra-small) {
      margin-bottom: 30px;
    }

    .tabs__accordions-wrapper {
      position: relative;
      z-index: 200;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
      border: 1px solid transparent;
    border-radius: $main-border-radius;
      width: 100%;
      padding: 29px 27px;


      @include media-query($extra-small) {
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 18px 20px 20px;
      }
      h2 {
        margin-bottom: 24px;
        font-weight: 500;
        font-size: 18px;
      }
    }

    .tabs__navigation {
      position: absolute;
      left: 0;
      top: -50px;
      display: flex;
      overflow: hidden;
      z-index: 0;


      .tabs__header-tab {
        min-width: 161px;
        display: flex;
        justify-content: center;
        gap: 4px;
        padding-top: 20px;
        padding-bottom: 45px;
        
        background: linear-gradient(white, white) padding-box,
        linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
      border: 1px solid transparent;
        border-radius: 10px;
        color: $primary-color;
        font-size: $font-size-8;
        font-weight: 700;

        &[aria-selected="true"] {
          background: $common-background-gradient;
          color: $secondary-color;
        }
      }
    }

    .algorithms-history__table-wrapper {
      table {
        width: 100%;
        border-collapse: collapse;

        @include media-query($extra-small) {
          font-size: $font-size-9;
        }
      }

      th {
        text-align: center;
        // padding-right: 17px;

        @include media-query($small) {
          padding-right: 3px;
        }
      
      }

      .algorithms-history__table-heading-row {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 4px;
          width: 100%;
          border-radius: $main-border-radius;
          background-color: $primary-color;
        }

      }

      .algorithms-history__table-td-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 14px;

        @include media-query($small) {
          font-size: $font-size-9;
        }

        @include media-query($extra-small) {
          font-size: 7px
        }
      }

      .algorithms-history__table-body-row {
        font-weight: 700;
        position: relative;

        ::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 1px;
          background-color: $primary-color;
          border-radius: $main-border-radius;
        }

        td {
          padding-top: 20px;
          padding-bottom: 10px;
          text-align: left;

          @include media-query($small) {
            padding: 3px 7px 3px 0;
            font-size: $font-size-9;
          }
        }
        .table-data-with-icon {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        td:first-of-type {
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
        }
      }
    }

    .algorithms-history__table-body-row-link {
      display: flex;
      align-items: center;
      gap: 9px;

      @include media-query($extra-small) {
       svg {
         height: 15px;
         width: 15px;
       }
      }
    }
  }
}
.details-page__transaction-section {
  margin-top: 20px;
background: linear-gradient(white, white) padding-box,
      $common-background-gradient border-box;
      border: 2px solid transparent;
      border-radius: 20px;
      margin-bottom: 120px;
  padding: 37px 27px 23px 27px;

  .custom-select-pages {
    background: #25606d;
    span {
      color: $secondary-color;
    }
    svg {
      path {
        fill: $secondary-color;
      }
    }
    ul {
      background: #25606d;
      button {
        color: $secondary-color;
      }
    }
  }
}