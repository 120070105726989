.KYS-page {
  justify-content: flex-start;

  .KYS-page__body-wrapper {
    display: flex;

    @include media-query($small) {
      flex-direction: column;
    }
  }

  .KYS-page__sections-wrapper {
    flex: 1;
  }

  .KYS-section {
    width: 100%;
    margin-top: 50px;
    margin-bottom: 90px;

    @include media-query($small) {
      margin-bottom: 50px;
    }

    .tabs-component {
      .hide-scrollbar::-webkit-scrollbar {
        display: none;
      }

      .hide-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
      }

      .tabs__navigation {
        display: flex;
        justify-content: center;
        gap: 13px;
        margin-bottom: 18px;
      }

      .tabs__header-tab {
        padding: 6px 11px 5px;
        cursor: pointer;
        border-radius: 50px;
        transition: $transition-duration;
        background: linear-gradient(0deg, #337fd0 0%, #9f38d2 100%);
        color: $secondary-color;

        .world_1 {
          max-width: 0;
          visibility: hidden;
          opacity: 0;
          transition: $transition-duration;

        }

        &[aria-selected="true"] {
          .world_1 {
            max-width: 100px;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }


    .KYS-section__heading {
      margin: 0 0 14px;
    }
    .KYS-section__title {
            text-align: center;
      margin-bottom: 58px;
    }

    .KYS-section__wrapper {
      min-height: 535px;
      border-radius: $main-border-radius;
      z-index: 1;
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;

    }
    .KYS-section__forms-wrapper {
      display: flex;
      gap: 47px;

      @include media-query($w1050) {
        flex-direction: column;
      }
    }
    .form.KYS__form {
      @include mq($mobile) {
        padding-left: 10px;
        padding-right: 10px;
      }
      position: relative;
      padding: 38px 28px 18px;
      background-color: $primary-bg-color;
      border-radius: $main-border-radius;
      z-index: 1;
    }


    .KYS-section__inputs-wrapper {
      display: flex;
      flex-direction: column;

      &:nth-child(2) {
        .KYS-section__inputs-heading {
          margin: 20px auto 15px;
        }
      }
    }

    .KYS-section__inputs-wrapper--images {
      .KYS-section__inputs-heading {
        margin: 25px auto;
      }
    }

    .KYS-section__inputs-heading {
      margin: 0 auto 25px;
      font-size: $font-size-4;
      font-weight: 700;
    }

    .KYS-section__inputs-row {
      display: flex;
      gap: 21px;
      margin-bottom: 20px;

      @include media-query($small) {
        flex-direction: column;
      }
    }

    .form-container--image {
      min-width: 440px;
      margin: 0  auto;
      padding-bottom: 39px;
      padding-top: 39px;
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
        border: 1px solid transparent;

              @include media-query($medium-down) {
        min-width: 70%;
      }
        @include media-query($medium) {
        min-width: 80%;
      }
      @include media-query($small) {
        min-width: 100%;
      }
    }


    .form-container {
      flex: 1;

      input, select {
        min-width: unset;
        background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
        border: 1px solid transparent;
        padding-left: 15px;
        padding-top: 13px;
        padding-bottom: 13px;
      }
      select {
        border-radius: $main-border-radius;
      }
      select[id=country] {
        @include mq($mobile) {
          width: 100%;
        }
      }
    }

    .KYS-section__buttons-wrapper {
      display: flex;
      justify-content: center;
      gap: 20px;
      margin-top: 30px;

      button {
        margin: 0;
        justify-content: center;
      }

      @include mq($mobile) {
        flex-direction: column;
      }
    }

    .KYS-section__next-page {
      display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
      background: linear-gradient(0deg, #337fd0 0%, #9f38d2 100%);
      color: $secondary-color;
      width: 180px;
      padding-top: 13px;
      padding-bottom: 10px;
      path {
        stroke: $secondary-color;
      }
    }
 .KYS-section__prev-page {
  @extend .KYS-section__next-page;
  background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;
  color: $primary-color;
        path {
        stroke: $primary-color
      }
 }

  .KYS-section__upload-btn {
    @extend .KYS-section__prev-page;
    padding: 0;
    height: 34px;
    font-weight: 700;
font-size: 12px;
    width: 130px;
    height: 34px;
    border-radius: 5px;
    display: flex;
    gap: 9px;
    align-items: center;
    margin-top: 14px;

    path {
      fill: $primary-color;
    }
  }


    .error-message {
      display: block;
      margin-top: 10px;
      color: $primary-error-color;
      font-family: $primary-font;
      font-size: $font-size-8;

    }
  }
}
 
    .form-container--kys-preview {
      @extend .form-container--image;
      min-width: 451px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 19px;
      
      


      @include media-query($small) {
        min-width: 100%;
      }


    }
    .form-container--kys-image-thumb {
      width: 180px;

      img {
        object-fit: cover;
      }
    }
    .form-container--kys-placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    }
    .KYS-section__delete-btn {
      @extend .btn;
      background-color: transparent;
font-weight: 700;
        padding: 0;
        border-radius: 5px;
      border: none;
        &:hover, &:focus {
          color: $primary-color;
          background-color: transparent;
          
        }
    }
    .KYS-page.dark-profile {
      .KYS-section {
        .form.KYS__form {
          background: $block-dark-bg;
          border: 1px solid $border-color;
      }
      .KYS-section__next-page {
        background: $secondary-color;
        border: none;
        color: 
        rgba(0, 0, 0, 1);
      }
      .form-container--image {
        background: transparent;
        border: 1px solid $border-color;
      }
      .KYS-section__wrapper {
        background: transparent;
        .form-container--placeholder-icon-wrapper {
          svg {
            path {
              fill: rgba(103, 136, 255, 1);
            }
          }
        }
        .KYS-section__upload-btn {
          border: 1px solid $border-color;
          span {
            color: $dark-text;
          }
        }
        .form-container--placeholder-title {
          color: $primary-color;
        }
        .tabs__header-tab {
          background: $purple-contrast;
          border: none;
        }
      }
      .KYS-section__buttons-wrapper {
        button {
          @include mq($mobile) {
            width: 100%;
          }
        }
        .KYS-section__prev-page {
          background: transparent;
          border: 1px solid $secondary-color;
          color: $secondary-color;
          .KYS-section__arrow-icon {
            path {
              stroke: $secondary-color;
            }
          }
        }
        .KYS-section__prev-page.btn {
          span {
            color: $secondary-color !important;
          }
        }
        .KYS-section__next-page.btn {
          span {
            color: $dark-text;
          }
        }
        .KYS-section__next-page {

          svg {
            path {
              stroke: $primary-color;
            }
          }
        }
      }
    }
    }