.transfer-wrapper {
  width: 100%;
  color: $primary-color;
  display: flex;
  flex-direction: column;
  h3 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.48px;
    margin-bottom: 23px;
  }
  .balance-block {
    margin-bottom: 32px;
    .balance-name {
      font-size: 19.874px;
      font-weight: 550;
      letter-spacing: -0.397px;
      margin-bottom: 2px;
    }
    .balance-amount {
      font-size: 14.077px;
      font-weight: 550;
      letter-spacing: -0.282px;
    }
  }

  .next-btn {
    @include media-query($w500) {
      width: 100%;
    }
    margin-bottom: 20px;
  }
  .warning-description {
    font-size: 12px;
    font-weight: 550;
    line-height: 180%; /* 21.6px */
  }
}
