.auth-headings {
  position: relative;
}

.back-button {
  position: absolute;
  left: 0;
  top: 7px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  svg {
    height: 100%;
    width: 100%;
    fill: $primary-color;
  }
}

.copy-email {
  position: relative;
  background: linear-gradient(white, white) padding-box,
    $common-background-gradient border-box;
  border: 1px solid transparent;
  border-radius: 10px;
  width: 100%;
  color: $primary-color;
  padding-left: 15px;
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 35px;
  &::placeholder {
    color: $primary-color;
    width: 100%;
  }

  &-label {
    position: absolute;
    top: 10px;
    right: 10px;
    svg {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 2;

      > path {
        fill: revert-layer;
      }
    }
  }
}

.password-error {
  &.active {
    left: 0;
    display: block;
  }
}

.form-set-image {
  img {
    max-height: 100%;
    max-width: 100%;
  }
}

.main-header {
  position: relative;
  background: linear-gradient(0deg, #5157ae 0%, #662da6 100%);
}

input[type="range"].disabled {
  border-color: lightgray !important;
  -webkit-appearance: media-slider !important;
  &:hover {
    cursor: unset !important;
  }
}

.tooltip {
  flex-direction: column;
  gap: 20px;
  padding: 80px 50px;
  position: fixed;
  z-index: 999;
  background: black;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: fit-content;
  width: 70%;

  display: flex;
  align-items: center;
  color: #fff831;
  border: 1px solid #fff831;
  border-radius: 10px;
  font-family: "Rubik", sans-serif;
  font-weight: 600;
  font-size: 14px;
  line-height: 1.2;
  text-decoration: none;
  transition: 0.3s;
  cursor: pointer;
  &-liqi p {
    margin: 20px 0;
  }
}

.currencies-exchange-wrap {
}
.currencies-exchange-input {
  padding: 15px 40% 16px 4% !important;
  &::placeholder {
    color: #fff831a3 !important;
  }
}

.currencies-exchange-input-values {
  position: absolute;
  top: 9px;
  right: 0;
  width: 47%;
  margin: auto;
  height: fit-content;
  @include media-query($small) {
    display: flex;
    position: initial;
    margin-bottom: 10px;
    width: 100%;

    span {
      white-space: nowrap;
    }
  }
}
.currencies-exchange-deposit {
  margin: 15px 0;
  @include media-query($extra-small) {
    display: flex;
    flex-direction: column;
  }
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0; /* Установите отступ между стрелочкой и полем ввода, если необходимо */
}

.algorithms-page .modal-dialog {
  height: initial;
  max-height: initial;
  @include media-query($small) {
  }
}
.currencies-exchange-deposit {
  font-weight: 700;
  font-size: 16px;
  span:first-of-type {
    font-size: 14px;
  }
  @include media-query($extra-small) {
    display: flex;
    flex-direction: column;
  }
}

// spinner
.page {
  position: relative;
}
.site-spinner {
  top: 10px;
  left: 32px;
  position: absolute;
}
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  color: #fff;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  color: #fff831;
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}
.page__sections-wrapper.medium-wrapper {
     @include media-query($w1100) {
      margin: 0;

    };
  min-height: 75vh;
}
.footer-links {
  padding: 20px 40px;
  h4 {
    margin: 20px 0;
  }
}

.footer-links {
  &.active {
    margin-top: 50px;
  }
}
.footer__main-links {
  display: flex;
  gap: 20px;
  li a {
    color: #fff831;
  }
}

.login-page.page {
  min-height: calc(100vh - 100px);
  justify-content: center;
}

//public
.public-title {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 40px;
}
.logo-wrapper {
  height: 173px;
  text-align: center;
  padding-top: 20px;

  svg {
    height: 100%;
    width: auto;
    margin: auto;
  }
}
.bordered {
  position: relative;
  padding: 10px;

  border-radius: 11px;
  overflow: hidden;
  animation: bordered 4s infinite alternate;
  margin: 3px 8px;
  &:after {
    content: "";
    border: 1px solid yellow;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

@keyframes bordered {
  to {
    border-radius: 15px;
  }
}

body,
html {
  // height: calc(100vh - 100px);
  font-family: "Roboto", sans-serif;
}

.footer {
  height: 160px;
  border-top: 1px solid $secondary-color;
  padding-left: 32px;
  padding-top: 29px;
  padding-right: 49px;
  padding-bottom: 33px;
  width: 100%;
  background: linear-gradient(360deg, #5258af 0%, #7404ab 100%);
}

.page {
  min-height: calc(100vh - 100px);
}

.terms-info {
  display: flex;
  flex-wrap: wrap;
  position: relative;

  & > div {
    flex: 1;
  }

  @include media-query($small) {
    flex-direction: column-reverse;
  }
}

.public-item {
  display: flex;
  margin: 5px;
  &-value {
    // width: 50%;
    min-width: 135px;

    @include media-query($extra-small) {
      min-width: 100px;
    }
  }
}

.terms-risks {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &-title {
    width: fit-content;
    font-size: 17px;
    width: fit-content;
    padding: 6px 28px;
  }
}

.terms-description {
  padding: 10px;
  * {
    margin: 5px;
  }

  @include media-query($small) {
    margin-bottom: 30px;
  }
}
.terms-description-list {
  list-style: none;
  line-height: 1.5;
}
.terms-description-list-item {
  padding-left: 40px;
  position: relative;

  &:before {
    content: "\25CF";
    position: absolute;
    left: 12px;
    font-size: 15px;
  }
}

.big-bordered {
  border-radius: 40px;
  animation: big-bordered 4s infinite alternate;
  &:after {
    border: 5px solid yellow;
  }
}

@keyframes big-bordered {
  to {
    border-radius: 35px;
  }
}

.description-img-wrap {
  height: 130px;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  flex: unset;
  img {
    width: auto;
    height: 100%;
  }

  @include media-query($small) {
    display: none;
  }
}

.tb {
  font-weight: 600;
}
.about-us {
  display: flex;

  @include media-query($small) {
    flex-direction: column;
  }
}
.about-ai,
.about-me {
  flex: 1;
}
.about-me {
  &-info {
    position: relative;
    padding: 30px;
  }

  @include media-query($small) {
    margin-bottom: 20px;
  }
}
.about-us-img {
  position: absolute;
  top: 0;
  width: 30%;
  transform: translatey(-100%);
  @include media-query($small) {
    display: none;
  }
}
.about-ai {
}
.about-me-title {
  margin-top: 0;
}

.posibilities-content {
  padding: 20px;
  width: 100%;
  margin: auto;
  & > div {
    margin-bottom: 5px;
  }
}
.posibilities-content-grafic {
  height: 220px;
  @include media-query($w700) {
    height: initial;
  }
}

.posibilities-content-imgs {
  display: flex;
  flex-wrap: wrap;
  img {
    max-width: 150px;
    @include media-query($small) {
      max-width: 140px;
    }
    @include media-query($small-mid) {
      max-width: 120px;
    }
    @include media-query($extra-small) {
      max-width: 100px;
    }
  }
}

.posibilities-content-p {
  margin-top: 30px;
}

.about-me-p {
  margin-bottom: 20px;
}

.about-me-info {
  min-height: 330px;
  line-height: 1.5;
}
.about-me-info-wrap {
  position: relative;
}

.partners {
}
.partners-title {
}
.partners-info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media-query($w1100) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.login-page.page {
  padding: 60px 0 220px;
}
.partners-table {
  @include media-query($medium-mid) {
    margin-bottom: 30px;
  }
  @include media-query($small-mid) {
    overflow-x: scroll;
    width: 100%;
  }
}
.partners-block-text {
  @include media-query($small) {
    margin-bottom: 30px;
  }
}
.partners-text {
  padding: 30px 20px;
  @include media-query($w1100) {
    margin-bottom: 30px;
  }

  & > div {
    margin: 5px;
  }
}
.partners-text-p {
}
.partners-text-list {
}
.partners-text-list-item {
}
.partners-text-list-item {
}
.partners-text-list-item {
}

.partners-block-text {
  position: relative;
}
.partners-block-img {
  position: absolute;
  width: 30%;
  margin: auto;

  right: 5px;
  top: 0;
  transform: translatey(-100%);
  @include media-query($medium-mid) {
    display: none;
  }
}

.partners-table-wrap {
}
.partners-table-row {
}
.partners-table-item {
  min-width: 84px;
  padding: 3px 8px;
  font-size: 14px;
  box-sizing: border-box;
  margin: 3px;
  text-align: center;
}

.footer__main-links {
  @include media-query($small-mid) {
    flex-direction: column;
    gap: 10px;
  }
}

// * {
//   scrollbar-width: thin;
//   scrollbar-color: transparent #662da6;
// }

*::-webkit-scrollbar {
  width: 10px;
  height: 6px;
  background: transparent;
}

*::-webkit-scrollbar-track {

  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background: $common-background-gradient;
  border-radius: 20px;
  // border: 3px solid yellow;
}

.partners-text {
  max-width: 536px;
}

.partners-block-text {
  @include media-query($w900) {
    margin-bottom: 30px;
  }
}
// private wrapper
.private-wrap {
  padding: 60px 0 30px;

  .partners-info {
    display: flex;
    align-items: center;
    @include media-query($large-down) {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
  .partners-text {
    @include media-query($large-down) {
      margin-bottom: 30px;
    }
    @include media-query($w750) {
      margin-bottom: 4px;
    }
  }

  .partners-block-img {
    width: 20%;
  }

  @include media-query($w750) {
    padding: 10px 0 30px;
  }

  .public-title {
    text-align: left;
  }

  .terms-info {
    @include media-query($w900) {
      flex-direction: column-reverse;
    }
  }

  .footer-links {
    padding: 20px 15px;
  }

  .about-us {
    @include media-query($w850) {
      flex-direction: column;
    }
  }

  .terms-info {
    @include media-query($w320) {
      flex-wrap: unset;
    }
  }
  .about-us-img {
    @include media-query($small) {
      display: block;
      right: 10px;
    }
    @include media-query($w320) {
      // display: none;
    }
    // @include media-query($w850) {
    //   flex-direction: column;
    // }
  }
}

.login-wrap-container {
  width: 80vw;
  margin: auto;
  & > div {
    padding-top: 60px;
  }
}

.primary-login-page {
  min-height: 100vh;

  .primary-login-wrap {
    text-align: center;
  }
  .primary-login-icon {
    padding: 30px 0 30px;
    svg {
      width: 100%;
    }
  }
  .primary-login-title {
    font-size: 100px;
    text-shadow: 4px 3px 0px gray;

    margin-bottom: 20px;
    @include media-query($w700) {
      font-size: 70px;
    }
    @include media-query($w500) {
      font-size: 46px;
    }
  }
  .primary-login-pretitle {
    font-size: 40px;
    margin-bottom: 10px;

    @include media-query($w500) {
      font-size: 30px;
    }
  }
  .primary-login-text {
    font-size: 20px;
    margin-bottom: 20px;

    @include media-query($w500) {
      font-size: 18px;
    }
  }
  .primary-login-pretitle,
  .primary-login-text {
    text-shadow: 2px 2px 0px gray;
  }

  .primary-login-buttons {
    margin-top: 5%;
    display: flex;
    justify-content: center;
    gap: 15%;
    @include media-query($w500) {
      flex-direction: column;
      gap: 30px;
      justify-content: center;
      align-items: center;
    }
  }
  .primary-login-button {
    border-radius: 20px;
    padding: 8px 10px;
    letter-spacing: 0.5px;
    width: 120px;
    background-color: #fff831;
    color: black;
    border: none;
    font-size: 18px;
    width: 164px;
    transition: 0.3s ease;
    cursor: pointer;
    &:hover {
      background-color: #ffa500;
    }
  }
  .page .footer {
    position: fixed;
    bottom: 0;
  }
}

.points {
  .public-title {
    margin-bottom: 110px;
  }
  @include media-query($w850) {
    display: none;
  }
}
.points-title {
}
.points-info {
  height: 260px;
  padding: 30px 0;
}
.points-info-wrap {
}
.points-info-top {
  position: relative;
  display: flex;
  justify-content: space-around;
  .point-item-hidden {
    bottom: 85px;
    width: 299px;
    left: -100px;
  }
}
.points-info-bottom {
  display: flex;
  position: relative;

  justify-content: space-between;
  .point-item-hidden {
    top: 85px;
    width: 282px;
  }
}
.points-info-line {
  height: 5px;
  background: #fff831;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    background-color: black;
    border: 3px solid;
    border-radius: 50%;
    height: 14px;
    width: 14px;
  }
}

.point-item {
  text-align: justify;
  position: relative;

  .point-item-number {
    font-size: 30px;
    border-radius: 50%;
    border: 2px solid;
    height: 70px;
    width: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .point-item-hidden {
    // visibility: hidden;
    // opacity: 0;
    // left: 53px;
    position: absolute;
    transition: 0.3s ease;
  }
  &.active {
    .point-item-hidden {
      visibility: visible;
      opacity: 1;
    }
  }
}
.point-item-title {
  font-size: 24px;
  font-weight: 600;
}
.point-item-text {
}

.public-footer {
  position: fixed;
  background-color: black;
  bottom: 0;
  li {
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.footer__main-info-logo {
  @include media-query($w550) {
    display: none;
  }
}
.foter-public-links {
  .footer__main-links {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
  }
}

.footer__copyright {
  @include media-query($w950) {
    display: none !important;
  }
}

.statistics-section__balance-counter span:last-of-type {
  font-weight: 500;
  text-transform: uppercase;
}

.profit_currencies-title {
  font-size: 14px;
  margin-bottom: 10px;
}
.profile-section__client-info-wrapper {
  margin-bottom: 15px;
  &.new .profile-section__client-info {
    justify-content: center !important;
  }
}
.profile-section__client-btc-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 0 !important;

  .profile-section__client-btc-info-balance {
    font-size: $font-size-3;
  }
  .profile-section__client-btc-info-title {
    margin: 0 !important;
  }

  &:nth-of-type(2) {
    .profile-section__client-btc-info-balance {
      font-size: 14px;
    }
  }
}
.profile-section__client-btc-info-wrap:not(:last-of-type) {
  margin-bottom: 8px;
}
// .profile-section__client-usd-info-balance {
//   font-size: $font-size-6;
// }
