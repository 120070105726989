.algorithms-page {
  @include media-query($w1100) {
    margin: 0;
    padding: 0;
  }
  .algorithms-section {
    margin-top: 50px;
  }

  .algorithms-section__heading {
    margin: 0 0 24px;
  }

  .algorithms-section__wrapper {
    display: flex;
    width: 100%;
    gap: 19px;

    @include media-query($w1100) {
      flex-direction: column;
      gap: 30px;
    }
  }

  .algorithms-section__item {
    background: linear-gradient(white, white) padding-box,
      linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
    border: 1px solid transparent;
    border-radius: $main-border-radius;
    padding: 30px 27px 30px 26px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    .algorithms-section__item-heading {
      font-weight: 400;
      font-size: 17px;
      margin-bottom: 29px;
    }
    .algorithms-section__item-heading-bold {
      @extend .algorithms-section__item-heading;
      font-weight: 700;
    }
    .algorithms-section__item-body {
      margin-bottom: 24px;
    }

    .algorithms-section__item-row {
      display: flex;
      justify-content: space-between;
      position: relative;
      gap: 10px;
      border-bottom: 1px solid transparent;
      &:not(:first-child) {
        padding-top: 10px;
      }
      &:not(:last-child) {
        padding-bottom: 10px;
      }
      // &:not(:last-child)::after {
      //   content: "";
      //   position: absolute;
      //   bottom: 0;
      //   height: 1px;
      //   width: 100%;
      //   background-color: $primary-color;
      //   border-radius: $main-border-radius;
      // }

      .algorithms-section__item-row-left {
        flex: 0 1 185px;
        font-size: 14px;
      }

      &:not(.bold) {
        .algorithms-section__item-row-left {
          font-weight: 500;
        }
      }
    }

    .algorithms-section__item-row-left:not(.bold) {
      font-weight: 600;
    }

    .algorithms-section__item-row-right {
      flex: 0 1 185px;
      font-size: 14px;
    }

  }

  .algorithms-history {
    margin-top: 85px;
    margin-bottom: 150px;
    h2 {
      font-size: 28px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    @include media-query($extra-small) {
      margin-bottom: 30px;
    }

    .tabs__accordions-wrapper {
      position: relative;
      z-index: 200;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
      border: 1px solid transparent;
      border-radius: $main-border-radius;
      width: 100%;
      padding: 29px 27px;

      @include media-query($extra-small) {
        margin-top: 30px;
        margin-bottom: 30px;
        padding: 18px 10px 10px 10px;
      }
      .table-title-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
      }

    }

    .tabs__navigation {
      position: absolute;
      left: 0;
      top: -50px;
      display: flex;
      overflow: hidden;
      z-index: 0;

      .tabs__header-tab {
        min-width: 161px;
        display: flex;
        justify-content: center;
        gap: 4px;
        padding-top: 20px;
        padding-bottom: 45px;

        background: linear-gradient(white, white) padding-box,
          linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
        border: 1px solid transparent;
        border-radius: 10px;
        color: $primary-color;
        font-size: $font-size-8;
        font-weight: 700;

        &[aria-selected="true"] {
          background: $common-background-gradient;
          color: $secondary-color;
        }
      }
    }

    .algorithms-history__table-wrapper {
      table {
        width: 100%;

        @include media-query($extra-small) {
          font-size: $font-size-9;
        }
        @include media-query($small) {
          .hidden {
            display: none;
          }
        }
      }

      th {
        text-align: center;
        // padding-right: 17px;

        @include media-query($small) {
          padding-right: 3px;
        }
      }

      .algorithms-history__table-heading-row {
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 1px;
          width: 100%;
          border-radius: $main-border-radius;
          background-color: $primary-color;
        }
      }

      .algorithms-history__table-td-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 14px;

        @include media-query($small) {
          font-size: $font-size-9;
        }

        @include media-query($extra-small) {
          font-size: 7px;
        }
      }
      .algorithms-history__table-body {
        tr {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          padding-top: 10px;
          padding-bottom: 10px;
        }
        th {
          text-align: left;
          padding-top: 4px;
          padding-bottom: 8px;
        }
        .currency-th {
          display: flex;
          gap: 5px;
          .transactions-filter-dropdown {
            list-style: none;
            position: absolute;
            z-index: 100;
            background: black;
            outline: 1px solid white;
            padding: 4px;
            border-radius: 4px;

            button {
              background: transparent;
              color: white;
              border: none;
              &:hover,
              &:focus {
                color: pink;
              }
            }
          }
          .expand-filter-button {
            background: transparent;
            border: none;
            svg {
              path{
                fill: $primary-color
              }
   
            }
          }
        }
      }
      .algorithms-history__table-body-row {
        font-weight: 700;
        position: relative;
        .user-column {
          display: none;
        }

        ::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 1px;
          background-color: $primary-color;
          border-radius: $main-border-radius;
        }

        td {
          padding-top: 20px;
          padding-bottom: 10px;
          text-align: left;

          @include media-query($small) {
            padding: 3px 7px 3px 0;
            font-size: $font-size-9;
          }
        }
        .table-data-with-icon {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        td:first-of-type {
          @include media-query($small) {
            font-size: 10px;
          }

          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
        }
      }
    }

    .algorithms-history__table-body-row-link {
      display: flex;
      align-items: center;
      gap: 9px;

      @include media-query($extra-small) {
        svg {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  .modal-dialog {
    padding: 9px 29px 67px 29px;
    align-items: unset;
    justify-content: flex-start;

    .modal-dialog__body {
      width: 100%;
    }

    .modal-dialog__body-wrapper {
      height: 100%;
    }

    .tabs {
      height: 100%;
    }

    .tabs__wrapper {
      position: relative;

      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .modal-dialog__body-heading {
      margin-bottom: 7px;
    }

    .modal-dialog__body-subheading {
      margin-bottom: 7px;
      font-size: $primary-font-size;
      font-weight: 500;
    }

    .custom-select {
      width: 100%;
      border: $main-border;
      border-radius: $main-border-radius;
      font-size: $font-size-6;

      .custom-select__dropdown {
        padding-left: 52px;
        border-radius: $main-border-radius;
        border: $main-border;
      }

      .custom-select__btn {
        padding: 20px 20px 20px 52px;
        border-radius: $main-border-radius;

        .icon {
          width: 14px;
          height: 7px;
          right: auto;
          left: 15px;
          color: $alternative-color;
          z-index: 4;
        }
      }
    }

    .modal-dialog__invoice-wrapper {
      display: block;
      margin-bottom: 7px;

      .modal-dialog__invoice-heading {
        margin-bottom: 5px;
      }

      .modal-dialog__invoice-btn {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        margin: 37px auto 0;
        text-transform: uppercase;
      }

      .modal-dialog__invoice-network-heading {
        margin-bottom: 15px;
      }

      .modal-dialog__invoice-network-wrapper {
        display: flex;
        margin-bottom: 32px;
        gap: 21px;

        .modal-dialog__invoice-network {
          padding: 7px 12px;
          font-family: $alternative-font;
          font-size: 12px;
          text-align: left;
          font-weight: 400;
        }
      }

      .modal-dialog__invoice-description {
        &:last-child {
          display: block;
          margin-top: 10px;
          font-weight: 500;
        }
      }
    }

    .form-container {
      display: block;
      height: 100%;
      margin-bottom: 10px;

      label {
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translate(0, -50%);
        padding: 0;
        cursor: pointer;
        z-index: 1;

        @include media-query($small) {
          right: 12px;
        }

        &:hover {
          color: $primary-hover-color;
        }

        svg {
          width: 18px;
          height: 25px;
        }
      }

      input {
        width: 100%;
        max-width: 100%;
        min-width: 210px;
        padding: 16px 20px;
        font-family: $alternative-font;
        font-size: $font-size-5;
        font-weight: 400;
      }

      &:after {
        // content: '';
        position: absolute;
        right: 1px;
        top: 1px;
        bottom: 1px;
        width: 60px;
        border-radius: $main-border-radius;
        height: calc(100% - 2px);
        background: rgb(0, 0, 0);
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.5676645658263305) 0%,
          rgba(0, 0, 0, 1) 58%
        );
      }
    }

    .modal-dialog__success-wrapper {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      text-align: center;

      .modal-dialog__success-icon {
        margin-bottom: 24px;
      }

      .modal-dialog__success-heading {
        margin-bottom: 50px;
        font-size: 36px;
      }

      .modal-dialog__success-description {
        margin-bottom: 100px;
        font-size: $font-size-4;
      }

      .modal-dialog__success-btn {
        position: relative;
        top: auto;
        right: auto;
      }
    }
  }
  .dark-profile {
    .expand-filter-button {
      svg {
        path {
          fill: $secondary-color !important;
        }
      }
    }
    .algorithms-section__item {
      background: $dark-profile-gradient;
    }
    .algorithms-section__item-row:not(:last-child)::after {
      background-color: $secondary-color;
    }
    .algorithms-section__item-button {
      background: $secondary-color;
      color: rgba(77, 77, 77, 1);
    }
    .algorithms-section__item.blured {
      .algorithms-section__item-button {
        background: $dark-profile-gradient;
        color: $secondary-color;
      }
    }
    .tabs__accordions-wrapper {
      background: $block-dark-bg;
      border: 1px solid $border-color;
    }
  }
}
.algorithms-section__item-button {
  @extend .trading_section-transfer-link;
  background: $common-background-gradient;
  color: $secondary-color;
  margin: 0;
}
.blured {
  filter: blur(1px);
  background: lightgrey !important;
}
