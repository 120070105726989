.popup-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  @include mq($mobile) {
    padding-left: 0;
  }
  .next-btn {
    margin-left: auto;
    margin-right: auto;
  }
  width: 100%;
  font-size: 27px;
  line-height: 100%; /* 27px */
  color: $alternative-color;
  padding-bottom: 30px;
  padding-left: 22px;
  padding-top: 20px;
  .popup-icon-thumb {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 138px;
    height: 138px;
    margin-bottom: 40px;
  }
  .popup-status-title {
    @include mq($mobile) {
      font-size: 28px;
    }
    font-weight: 700;
    margin-bottom: 22px;
    text-align: center;
  }
  .popup-description {
    @include mq($mobile) {
      font-size: 16px;
    }
    font-weight: 400;
    margin-bottom: 30px;
    text-align: center;
  }
  .close-popup {
    border-radius: 100px;
  }
  .popup-icon-thumb {
    width: 100%;
    height: auto;
    // path {
    //   fill: $primary-color;
    // }
  }
}

.dark-profile {
  .popup-wrapper {
    color: $secondary-color;
    .popup-icon-thumb {
      width: 100%;
      height: auto;
      // path {
      //   fill: $secondary-color;
      // }
    }
  }
}
