.currency-preview {
  @include flex-center;
  width: 32px;
  height: 32px;
  padding: 7px;
  background: $secondary-color;
  border-radius: 5px;

  .bg-thumb {
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }
}
.bg-thumb.btc {
  background-image: url("../../images/currency-preview/btc.png");
}
.bg-thumb.eth {
  background-image: url("../../images/currency-preview/eth.png");
}
.bg-thumb.usdt {
  background-image: url("../../images/currency-preview/usdt.png");
}
