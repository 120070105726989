.profile-page {

  justify-content: flex-start;

  .profile-page__body-wrapper {
    display: flex;

    @include media-query($small) {
      flex-direction: column;
    }
    
  }

  .profile-page__sections-wrapper {
    flex: 1;
    
  }


}
.dark-profile {
  background: $body-bg;
  background-color: $body-bg;
  color: $dark-profile-page-text;
  .section {
    background: $body-bg;
  }
  }