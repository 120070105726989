.sessions-section {

  &.dark-profile {
border: 1px solid $border-color;
  }
  width: 100%;
  padding: 18px 27px 36px;
  margin-top: 60px;
  margin-bottom: 160px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
  border: 1px solid transparent;
  border-radius: $main-border-radius;
  overflow: hidden;

  @include media-query($extra-small) {
    margin-top: 30px;
    margin-bottom: 30px;
    padding: 18px 20px 20px;
  }
  @include mq($mobile) {
    padding-left: 4px;
    padding-right: 4px;
  }

  .sessions-section__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 17px;



  }

  .sessions-section__header-heading {
    font-size: $font-size-3;
    font-weight: 500;
  }

  .sessions-section__header-settings {
    display: flex;
    align-items: center;
    gap: 37px;
    font-size: $font-size-7;

    & > span {
      letter-spacing: 0.03em;
    }
  }



  .sessions-section__table-wrapper {
    margin-bottom: 52px;

    @include media-query($extra-small) {
      margin-bottom: 30px;
    }

    table {
      width: 100%;
      border-collapse: collapse;

      @include media-query($extra-small) {
        font-size: $font-size-9;
      }
    }

    th {
      text-align: center;
      // padding-right: 17px;
    }

    .sessions-section__table-heading-row {
      th {
        text-align: left;
      }
      // &:after {
      //   content: "";
      //   position: absolute;
      //   left: 0;
      //   bottom: 0;
      //   height: 1px;
      //   width: 100%;
      //   border-radius: $main-border-radius;
      //   background-color: $primary-color;
      // }
    }
    .sessions-section__transaction-heading-row {
      text-align: center;
    }


    .sessions-section__table-td-wrapper {
      font-size: $font-size-7;
      font-weight: 600;
      padding-bottom: 14px;
      span {
        font-weight: 600;
        font-size: 13px;
        text-transform: uppercase;
        font-family: $second-family;
        margin-right: 5px;
        svg {
          path {
            fill: none;
            stroke: $primary-color;
          }
        }
      }

    }


    .sessions-section__table-body-row {
      font-weight: 600;
      // border-bottom: 1px solid $primary-color;
      border-radius: $main-border-radius;
      height: 49px;

      td {
        padding: 4px;
        text-align: left;
      }
    }
  }

  .sessions-section__controls {
    display: flex;
    align-items: center;
    gap: 47px;

    .sessions-section__controls-counter {
      font-size: $font-size-7;
      font-weight: 500;
      letter-spacing: 0.03em;
    }

    .sessions-section__controls-buttons {
      display: flex;
      gap: 32px;
    }
  }
}

.alert-container {
  border: $main-border;
  border-radius: $main-border-radius;
}

#error_explanation {
  padding: 17px;
}
.balance_page-sessions-section {
  @extend .sessions-section;
  padding: 35px 27px 23px 27px;
  position: relative;
  z-index: 4;
  // margin-top: -86px;
  margin-bottom: 160px;
  // position: absolute;
  // bottom: -84px;
  border-radius: 8px;

  // z-index: 3;
  border: 1px solid $border-color;
  overflow: visible;
}
// .balance_page-page__sections-wrapper {
//   @extend .medium-wrapper;

//     margin-bottom: 60px;

// }
.dark-profile {
  .sessions-section {
    background: $block-dark-bg;
  }
  .balance_page-sessions-section {
    background: $block-dark-bg;
  }

  .sessions-section__table-heading-row::after {
    background: $border-color;
  }
}