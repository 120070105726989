.referals-page {
  @include media-query($large-down) {
    padding-left: 10px;
    padding-right: 10px;
  }
  padding-left: 10px;
  padding-top: 40px;
  padding-right: 10px;
  padding-bottom: 40px;

  border-radius: $main-border-radius;
  margin-top: 23px;
  margin-bottom: 70px;
  .with-rounded-wrapper {
    border-radius: 14px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 30px;
    padding-bottom: 40px;
  background: linear-gradient(white, white) padding-box,
    $common-background-gradient border-box;
  border: 2px solid transparent;
    @include mq($widescreen) {
      padding-left: 29px;
      padding-right: 26px;
    }
  }
  .with-gap{
gap: 14px;
  }
  .profile-page__sections-wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  .referals-page-section {
    @include media-query($large-down) {
      flex-direction: column;
      align-items: center;
      row-gap: 42px;
    }
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;

    &:nth-child(2) {
      gap: 30px;
    }
    .referals-section-photo-wrapper {
      position: relative;
      width: 260px;
      height: 260px;
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;
      border-radius: 50%;
      img {
        position: absolute;
        left: 50%;
        top: 50%;
        width: 60%;
        height: 68%;
        transform: translate(-50%, -50%);
      }
    }
    .referals-section-user-panel {
      display: flex;
      gap: 20px;
      @include mq($medium-down) {
        width: 100%;
      }

      @include media-query($small) {
        flex-direction: column;
        width: 100%;
        align-items: center;
      }
    }
    .referals-section-info-wrapper {
      width: 210px;
      line-height: 1;
      .coin-name-wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-bottom: 12px;
        p {
          margin: 0;
        }
      }
      @include mq($medium-down) {
        width: 100%;
      }
      @include media-query($small) {
        width: 100%;
        text-align: center;
      }
      
      .balance-list {

        @include mq($medium-down) {
          li {
            display: flex;
            align-items: center;
            justify-content: space-between;

          }
        }
      }
      h2 {
        white-space: normal;
        word-wrap: break-word;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 11px;
      }
      .gradient-wrapper {
        background: linear-gradient(
          316deg,
          #337fd0 0%,
          #3f77b3 0.01%,
          #6927a4 53%,
          #9f38d2 100%
        );
        background-clip: text;
      }
      h3 {
        font-weight: 700;
        font-size: 20px;
        letter-spacing: -0.03em;
        color: transparent;
      }
      ul {
        list-style: none;

        li:not(:last-child) {
          margin-bottom: 16px;
        }
      }
      p {
        font-weight: 500;
        margin-bottom: 10px;
      }
      span {
        font-weight: 700;
        font-size: 23px;
      }
    }

    .promo-wrapper {
      // @include media-query($medium) {
      //   min-width: 100%;
      // }
      // @include media-query($small) {
      //   min-width: calc((100% - 10px));
      // }
      min-width: 375px;
      @include media-query($large-down) {
        min-width: 100%;
      }
    }
    .referals-form-wrapper {
      @include media-query($large-down) {
        min-width: 100%;
      }
      background: linear-gradient(
        316deg,
        #337fd0 0%,
        #3f77b3 0.01%,
        #6927a4 53%,
        #9f38d2 100%
      );

      padding: 20px 24px;
      min-width: 100%;
      border-radius: $main-border-radius;
      margin-bottom: 5px;
      form {
        margin-bottom: 15px;
      }
      .copy-email {
        padding-right: 35px;
      }
    }
    .referals-section-social-links {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;
      padding-bottom: 15px;
      margin-bottom: 3px;

      p {
        color: $secondary-color;
        font-weight: 700;
        font-size: 15px;
      }
      ul {
        display: flex;
        list-style: none;
        gap: 7px;

        a {
          display: block;
          border-radius: 50%;
          width: 31px;
          height: 31px;
          background-color: $primary-bg-color;
          position: relative;
          color: rebeccapurple;
        }

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        a:not(:last-of-type) {
          path {
            fill: currentColor;
          }
        }
        a:last-of-type {
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
    .referals-section-fundraisers-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      color: $secondary-color;
      margin-bottom: 10px;
      .fundraisers-title {
        font-weight: 700;
        margin-bottom: 7px;
      }
      span {
        font-weight: 700;
      }
      .star-level-wrapper {
        display: flex;
        align-items: center;
        gap: 3px;

        p {
          font-weight: 700;
        }
      }
      .icon-holder {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 60px;
        height: 60px;
      }
      svg {
        width: 90%;
        height: 90%;
      }
      .current-level {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #5056ad;
        font-weight: 700;
        font-size: 15px;
      }
    }
    .promo-buttons {
      @include media-query($small) {
        flex-wrap: wrap;
      }
      min-width: 100%;
      display: flex;
      flex-direction: column;
      gap: 5px;

      .promo-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        height: 40px;
        background: linear-gradient(
          316deg,
          #337fd0 0%,
          #3f77b3 0.01%,
          #6927a4 53%,
          #9f38d2 100%
        );

        background-size: 150% 150%;
        border-radius: $main-border-radius;
        border: none;
        color: $secondary-color;
        width: 100%;
      }
    }
    .referals-page__table-wrapper {
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;
      border-radius: 8px;
      padding: 25px;
      width: 100%;
      min-width: 300px;
      max-height: 210px;
      overflow-y: scroll;
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      table {
        tbody,
        thead {
          tr {
            display: grid;
            grid-template-columns: minmax(80px, 1fr) minmax(130px, 140px) repeat(
                6,
                1fr
              );
          }
        }
        width: 100%;
        border-collapse: collapse;
        overflow-x: scroll;
        @include media-query($extra-small) {
          font-size: $font-size-9;
        }
      }
      table.inner {
        margin-left: 16px;
      }
      .cell-with-opener {
        @include flex-center;
        justify-content: flex-end;
        gap: 6px;
        button {
          width: 14px;
          height: 14px;
          background-color: #d4b4e5;
          border: none;
          border-radius: 4px;
          cursor: pointer;
          font-size: 14px;
          .arrow-expand-tree.expanded {
            transform: rotate(180deg);
          }
          &:hover {
            text-decoration: underline;
          }
        }
      }
      .email-cell {
        overflow-x: scroll;
      }
      th {
        font-weight: 500;
          font-size: 12px;
        span {
          font-weight: 500;
          font-size: 12px;
        }

        @include media-query($small) {
          padding-right: 3px;
        }
      }

      .referals-page__thead {
        padding-bottom: 14px;
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          height: 2px;
          width: 100%;
          border-radius: $main-border-radius;
          background: linear-gradient(
            316deg,
            #337fd0 0%,
            #3f77b3 0.01%,
            #6927a4 53%,
            #9f38d2 100%
          );
        }
      }

      .referals-page__table-td-wrapper {
        // display: flex;
        // align-items: center;
        // justify-content: center;
        gap: 5px;
        font-size: 14px;
        font-weight: 700;
        padding-bottom: 14px;

        @include media-query($small) {
          font-size: $font-size-9;
        }

        @include media-query($extra-small) {
          font-size: 7px;
        }
      }

      .referals-page__table-body-row {
        font-weight: 700;
        position: relative;

        ::after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: "";
          width: 100%;
          height: 1px;
          background-color: $primary-color;
          border-radius: $main-border-radius;
        }

        td {
          padding-top: 15px;
          padding-bottom: 11px;
          text-align: center;
          font-weight: 500;
          font-size: 12px;
          padding-left: 5px;

          @include media-query($small) {
            padding: 3px 7px 3px 0;
            font-size: $font-size-9;
          }
        }
        .table-data-with-icon {
          display: flex;
          align-items: center;
          gap: 5px;
        }
      }
    }

    .referals-common-profit-wrapper {
      @include media-query($w1100) {
        max-width: unset;
        min-width: 100%;
      }
      @include media-query($extra-small) {
        min-width: unset;
        width: 100%;
      }
      height: fit-content;
      max-width: 100%;
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;
      border-radius: $main-border-radius;
      padding-left: 18px;
      padding-top: 17px;
      padding-right: 25px;
      padding-bottom: 21px;

      .refferals-chart-heading-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          font-weight: 700;
        }
        span {
          background: linear-gradient(
            316deg,
            #337fd0 0%,
            #3f77b3 0.01%,
            #6927a4 53%,
            #9f38d2 100%
          );
          background-clip: text;
          font-weight: 700;
          font-size: 18px;

          color: transparent;
        }
      }
    }
  }
}
.referals-page.dark-profile {
  background: $body-bg;
  border: none;
  .referals-section-photo-wrapper {
    // background: $dark-profile-gradient border-box, white padding-box;
    background: unset;
    border: 2px solid $purple-contrast;
    flex-shrink: 0;
  }
  .with-rounded-wrapper {
    background: $block-dark-bg;
    border: 1px solid $border-color;
  }
  .gradient-wrapper {
    h3 {
      color: $secondary-color;
    }
    background: $secondary-color;
    background-clip: text;
  }
  .referals-section-info-wrapper .info-wrapper-upper-part::after {
    background: $secondary-color;
  }
  .referals-form-wrapper {
    z-index: 1;
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      position: absolute;
      top: -140px;
      left: -80px;
      z-index: -1;
      width: 214px;
      height: 240px;
      transform: rotate(-8deg);
      background: $purple-lightened;
      border-radius: 8px;
      box-shadow: 0px 5px 8px 0px rgba(61, 46, 152, 0.07);
      
      
      
      }
      &::before {
        content: "";
        z-index: -1;
        position: absolute;
        top: -90px;
        left: -100px;
        z-index: -2;
        width: 214px;
        height: 240px;
        transform: rotate(-8deg);
        background: $purple-lightened;
        border-radius: 8px;
        }
    background: $purple-contrast;

  }
  .promo-buttons .promo-btn {
    background: $secondary-color;
    color: $dark-text;

  }
  .referals-page__table-wrapper {
    background: $block-dark-bg;
    border: 1px solid $border-color;
  }
  .referals-page__table-body-row ::after {
    display: none;
  }
  .referals-page__table-wrapper .referals-page__thead:after {
    background: $secondary-color;
    opacity: 0.2;
  }
  .referals-common-profit-wrapper {
    background: $block-dark-bg;
    border: 1px solid $border-color;

    .refferals-chart-heading-wrapper span {
      background: $secondary-color;
      background-clip: text;
    }
  }
}
