.balance-cards {
  list-style: none;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(268px, 1fr));
  gap: 10px;

  .balance-card {
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 158px;
    padding-left: 18px;
    padding-top: 13px;
    padding-bottom: 12px;
    padding-right: 10px;
    border: 1px solid $purple-contrast;
    .coin-name-wrapper {
      @include flex-center;
      gap: 9px;

    }
    .coin-name {
      font-size: 14px;
      font-weight: 700;
      line-height: 20px; /* 166.667% */
      text-transform: uppercase;
    }
    .coin-price {
      font-size: 36px;
      font-weight: 700;
      letter-spacing: 1.6px;
    }
    .profile-section__widthdrawal-btn{
        width: 100%;
        border-radius: 8px;
        height: 40px; 
        margin-bottom: 0;
    }
  }
}

.dark-profile {
  .balance-card {

    border: 1px solid rgba(255, 255, 255, 0.1);

    background: $block-dark-bg;
  }
}
