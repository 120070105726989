.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(18, 20, 23, 0.5);
  z-index: 1200;
  transition: opacity $transition-duration $transition-timing-function;
  pointer-events: none;
  opacity: 0;

  .modal-window {
    position: absolute;
    width: 500px;
    background-color: $primary-bg-color;
    padding: 30px;
    background: linear-gradient(white, white) padding-box,
      $common-background-gradient border-box;
    border: 1px solid transparent;
    border-radius: $main-border-radius;
    @include media-query($w500) {
      height: fit-content;
      width: 90%;
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
    }

    transform: scale(0.7);
    transition: transform $transition-duration $transition-timing-function;
  }
  .modal-window.dark-profile {
    background: $block-dark-bg;
    border: 1px solid $border-color;
    .profile-section__widthdrawal-btn {
      background: $purple-contrast !important;
    }
    .copy-email {
      // background: $block-dark-bg !important;

      border: 1px solid $border-color;
      // color: $primary-bg-color;
    }

    .btn.next {
      background: $purple-contrast;
    }
    .deposit-dropdown-container {
      background: $secondary-color !important;
      color: $primary-color;

      border: 1px solid $border-color;
      ul {
        background: $secondary-color !important;
        color: $primary-color;
        border: 1px solid $border-color !important;
      }
      span {
        color: $primary-color;

        path {
          stroke: $primary-color;
        }
      }
    }
  }
  .modal-window-advantages {
    max-height: 90vh;
    overflow-y: scroll;
    position: absolute;
    width: 834px;
    padding: 70px 25px 70px 76px;
    img {
      display: block;
      width: 100%;
      height: auto;
      object-fit: contain;
      border-radius: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
    }
    .link {
      @include mq($extra-small-down) {
        width: 100%;
      }
      width: 280px;
      margin-top: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 10px;
      border: 1px solid $secondary-color;
      border-radius: $main-border-radius;
      color: $secondary-color;
      &:hover,
      &:focus {
        background: $common-background-gradient;
        border-color: transparent;
      }
    }
    backdrop-filter: blur(30px);
    border: 1px solid rebeccapurple;
    border-radius: 20px;
    @include media-query($w900) {
      // height: calc(100vh);
      width: 90%;
    }
    @include media-query($w500) {
      height: fit-content;
      padding: 10px;
      padding-top: 60px;
    }
    transform: scale(0.7);
    transition: $transition-duration $transition-timing-function;
    .close-icon {
      position: absolute;
      top: 25px;
      right: 25px;
      cursor: pointer;
    }
    h4 {
      @include media-query($w500) {
        font-size: 30px;
      }
      margin-bottom: 25px;
      color: $secondary-color;
      font-size: 40px;
      font-weight: 550;
      line-height: 120%; /* 48px */
    }
    p {
      color: $secondary-color;
      font-weight: 550;
      line-height: 160%; /* 22.4px */
    }
  }
}
.overlay.is-visible {
  opacity: 1;
  pointer-events: auto;
  overflow-y: auto;
  .modal-window {
    transform: scale(1);
  }
  .modal-window-advantages {
    transform: scale(1);
  }
}
.deposit-content-wrapper {
  display: inline-flex;
  flex-direction: column;
  width: 100%;
  .network-select-block {
    margin-bottom: 20px;
    .network-select-wrapper {
      display: flex;
      align-items: center;
      gap: 12px;
      button {
        flex-shrink: 0;
        border-radius: $main-border-radius;
        border: none;
        padding: 12px 6px;
        font-size: 12px;
        font-weight: 400;
        background: $common-background-gradient-border;
        border: 1px solid transparent;
        color: $primary-color;
        &:hover, &:focus {
          background: $common-background-gradient;
          color: $secondary-color;
        }
      }

      .selected {
        background: $common-background-gradient;
        color: $secondary-color;
      }
    }
  }
  .btn {
    background: $common-background-gradient;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    margin: 0;
    border: none;

    span {
      padding: 0;
      font-size: 12px;
    }
  }
  .btn.next {
    width: 160px;
    margin-left: auto;
    margin-right: auto;
  }
  h2 {
    margin-bottom: 20px;
  }
  .deposit-window-label {
    margin-bottom: 10px;
  }
  .deposit-window__currency-address-wrapper {
    position: relative;
    margin-bottom: 15px;
  }
  .deposit-window__info-wrapper {
    margin-bottom: 21px;
    p {
      line-height: 1.3;
      font-weight: 500;
    }
    p:first-child {
      font-weight: 700;
      margin-bottom: 10px;
    }
  }
  .deposit-dropdown-container.expanded {
    border: none;
    height: 114px;
    padding-bottom: 0;
  }
  .deposit-dropdown-container {
    display: flex;
    align-items: flex-start;
    position: relative;
    background: linear-gradient(white, white) padding-box,
      $common-background-gradient border-box;
    border: 1px solid transparent;
    border-radius: $main-border-radius;
    padding-left: 17px;
    padding-top: 18px;
    padding-bottom: 17px;
    margin-bottom: 20px;

    button {
      color: $primary-color;
      background: transparent;
      border: none;
    }
    .deposit-dropdown-container-btn {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      gap: 13px;
      height: 15px;

      span {
        width: 14px;
        height: 7px;
        font-weight: 700;
        path {
          stroke: $primary-color;
        }
      }
    }
    ul {
      list-style: none;
      position: absolute;
      z-index: 22;
      padding-left: 17px;
      padding-top: 18px;
      padding-bottom: 35px;
      top: 0;
      left: 0;
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;
      border-radius: $main-border-radius;
      width: 100%;

      li:first-child > button {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 13px;
        height: 15px;

        span {
          width: 14px;
          height: 7px;
          font-weight: 700;
          path {
            stroke: $primary-color;
          }
        }
      }
      li:not(:first-child) {
        margin-left: 27px;
        span {
          font-weight: 700;
        }
      }
      li:not(:last-child) {
        margin-bottom: 8px;
      }
    }
  }
  .deposit-window-group-wrapper {
    margin-bottom: 20px;
  }
  .deposit-window-buttons-wrapper {
    display: flex;
    align-items: flex-start;
    width: 208px;
    gap: 20px;
    .btn {
      align-items: flex-start;
      gap: 0px;

      font-weight: 400;

      padding: 8px 12px;
    }
  }
}
.modal-dialog__success-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  .modal-dialog__success-icon {
    width: 138px;
    height: 138px;
    margin-bottom: 40px;
  }
  .modal-dialog__success-heading {
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    background: linear-gradient(0deg, #337fd0 0%, #9f38d2 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 20px;
  }
  .modal-dialog__success-description {
    color: $primary-color;
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    text-align: center;
    margin-bottom: 40px;
  }
  .btn {
    background: $common-background-gradient;
    flex-direction: column;
    justify-content: center;
    height: 40px;
    margin: 0;
    border: none;
    width: 220px;
  }
}
.form-container__successful-message-wrapper {
  min-width: 200px;
  width: 100%;
  position: absolute;

  top: -2px;
  right: calc(-100% - 14px);
  display: flex;
  align-items: center;
  gap: 9px;
  padding: 12px 9px 11px 21px;
  font-weight: 700;
  background: $common-background-gradient;
  color: $secondary-color;
  border-radius: $main-border-radius;
  transition: $transition-duration;
  z-index: 2;

  &[aria-hidden="true"] {
    opacity: 0;
    visibility: hidden;
  }

  &[aria-hidden="false"] {
    opacity: 1;
    visibility: visible;
  }

  svg {
    height: 18px;
    width: 18px;
  }

  &:before {
    content: "";
    position: absolute;
    left: -1px;
    top: 50%;
    transform: translate(0, -50%) rotate(-45deg);
    width: 18px;
    height: 18px;
    background: $common-background-gradient;
    z-index: 1;
  }
}

.dark-profile {
  
  .network-select-block {
    .network-select-wrapper {
      button {
        background: transparent;
        color: $secondary-color;
        border: 1px solid $purple-contrast;
        &:hover, &:focus {
          background: $purple-contrast;
          border: 1px solid transparent;
        }
      }
      .selected {
        background: $purple-contrast;
        border: 1px solid transparent;
      }
    }
  }


  .modal-dialog__success-wrapper {
    .modal-dialog__success-description {
      color: $secondary-color;
    }
    .modal-dialog__success-heading {
      color: $purple-contrast;
      background-clip: unset;
      -webkit-background-clip: unset;
      -webkit-text-fill-color: unset;
      background: transparent;

    }
    .btn {
      background: $purple-contrast;
    }
  }
}
