.binary-bots-section {
  h2 {
    margin-top: 52px;
  }
}

.binary-bots {
  margin-top: 35px;
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(310px, 1fr));
  grid-template-rows: auto;
}
.trading-offer-wrapper {
  @include mq($mobile) {
    padding-left: 14px;
    padding-right: 14px;
  }
  padding-left: 35px;
  padding-top: 34px;
  padding-bottom: 27px;
  padding-right: 35px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
  border-radius: 10px;
  border: 1px solid transparent;
  h3 {
    font-size: 17px;
    font-weight: 700;
    letter-spacing: -0.34px;
    margin-bottom: 19px;
  }
  p {
    display: block;
    font-size: 17px;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.34px;
  }
  .details-row {
    @include flex-center;
    justify-content: space-between;
  }
  .details-row:not(:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid $primary-color;
  }
  .details-row:not(:first-of-type) {
    padding-top: 9px;
  }
  .buttons-wrapper {
    @include media-query($w500) {
      grid-template-columns: repeat(1, 1fr);
      grid-template-rows: repeat(2, 1fr);
    }
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 14px;
    margin-top: 45px;

    .next-btn {
      width: unset;
      color: white;
      padding-top: 12px;
      padding-bottom: 8px;
      border-radius: 10px;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 120%; /* 20.4px */
      letter-spacing: -0.34px;
      border: none;
      &:not(:disabled):hover,
      &:not(:disabled):focus {
        background: black;
        border: none;
      }
    }
    button:first-of-type {
      background: $common-background-gradient;
    }
    button:last-of-type {
      background: linear-gradient(85deg, #4cad90 -3.47%, #40d033 107.14%);
    }
    button:disabled {
      background: gray;
    }
  }
}

.dark-profile {
  .trading-offer-wrapper {
    h3 {
      color: $purple-contrast;
    }
    background: $block-dark-bg;
    border: 1px solid $border-color;
    button:first-of-type,
    button:last-of-type {
      background: $secondary-color;
      color: $dark-text;
      &:hover,
      &:focus {
        background: $dark-text;
        color: $secondary-color;
      }
    }
    button:disabled {
      background: gray;
      pointer-events: none;
    }
  }
  .details-row:not(:last-of-type) {
    padding-bottom: 9px;
    border-bottom: 1px solid transparent;
  }
}
