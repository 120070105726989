.profile-section {
  width: 100%;
  margin-top: 50px;

  .profile-section__heading {
    margin: 0 0 14px;
  }

  .profile-section__wrapper {
    border: $main-border;
    border-radius: 14px;
    z-index: 1;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
    border: 1px solid transparent;
    overflow: hidden;
  }

  .profile-section__content-top-side {
    flex-shrink: 0;
  }
  .profile-section__content-top-side:nth-child(2) {
    flex-shrink: 1;
    position: relative;
    z-index: 2;
    overflow: hidden;
  }
  .profile-section__balance-page {
    border-radius: 40px;
    // border: 2px solid #79747d;

    .profile-section__client-top-title {
      text-align: left;
    }
    .profile-section__client-info {
      column-gap: 44px;
    }
    .profile-section__widthdrawal-btn {
      display: flex;
      width: 183px;
      height: 42px;
      border: none;
      overflow: hidden;
      background: linear-gradient(
        360deg,
        #337fd0 0%,
        #3f77b3 0.01%,
        #6927a4 53%,
        #9f38d2 100%
      );
      background-size: 300% 300%;
      background-position: 100% 220%;
      border: 1px solid transparent;
      border-radius: 10px;
font-size: 16px;
font-weight: 700;
line-height: 122.2%; /* 19.552px */
text-transform: uppercase;
      @include media-query($large) {
        width: 90%;
      }
      @include media-query($w1000) {
        width: 100%;
      }
      // @include media-query($medium-down) {
      //   width: 100%;
      // }
    }
    .balance-page_referal-form {
      position: relative;
      z-index: 1;
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        top: -16px;
        left: -20px;
        z-index: -1;
        width: 132px;
        height: 62px;
        transform: rotate(-20deg);
        background: $purple-lightened;
        border-radius: 8px;
        box-shadow: 0px 5px 8px 0px rgba(61, 46, 152, 0.07);
        
        
        
        }
        &::before {
          content: "";
          position: absolute;
          top: 10px;
          left: -40px;
          z-index: -2;
          width: 132px;
          height: 62px;
          transform: rotate(-20deg);
          background: $purple-lightened;
          border-radius: 8px;
          }

      height: 223px;
      width: 250px;
      padding: 26px 20px 63px 20px;
      background: linear-gradient(
        360deg,
        #337fd0 0%,
        #3f77b3 0.01%,
        #6927a4 53%,
        #9f38d2 100%
      );
      color: $secondary-color;
      border: 1px solid transparent;
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include media-query($medium-down) {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }

      .profile-section__heading {
        margin-bottom: 8px;
        font-weight: 600;
      }

      .profile-section__input {
        margin-bottom: 19px;
        position: relative;
        width: 100%;

        .form-container {
          display: block;
          height: 100%;

          label {
            position: absolute;
            right: 13px;
            top: 50%;
            transform: translate(0, -50%);
            padding: 0;
            cursor: pointer;
            z-index: 999;

            &:hover {
              color: $primary-hover-color;
            }
          }

          .profile-section__referal-input {
            width: 210px;
            font-size: $font-size-8;
            background: unset;
          }

          &:after {
            content: "";
            position: absolute;
            right: 1px;
            top: 1px;
            bottom: 1px;
            width: 60px;
            border-radius: $main-border-radius;
            height: calc(100% - 2px);
            background: rgb(0, 0, 0);
            background: linear-gradient(
              90deg,
              rgba(0, 0, 0, 0.5676645658263305) 0%,
              rgba(0, 0, 0, 1) 58%
            );
          }
        }
      }

      .profile-section__social-links-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 13px;
      }

      .profile-section__social-links-title {
        font-size: $font-size-8;
        font-weight: 700;
      }

      .profile-section__social-links-list {
        display: flex;
        gap: 6px;
      }

      .profile-section__social-links-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        // background-color: $primary-color;
        background: $common-background-gradient;
        color: $primary-bg-color;
        border-radius: 50%;

        &:hover {
          background-color: $primary-hover-color;
        }
        svg {
          fill: $secondary-color;
        }
        path {
          fill: $secondary-color;
        }
      }
    }
  }
  .profile-section__label {
    position: absolute;
    right: -1px;
    top: -47px;
    padding: 17px 32px 35px 32px;
    z-index: 0;
    background: linear-gradient(0deg, #337fd0 0%, #9f38d2 100%);
    background-color: $secondary-bg-color;
    border-radius: $main-border-radius;
    color: $secondary-color;
    font-size: $font-size-8;
    font-weight: 700;
    overflow: hidden;
    text-decoration: none;
  }

  .profile-section__content-top {
    &::after {
      content: "";
      position: absolute;
      height: 1px;
      width: 90%;
      left: 0;
      bottom: 30px;
      background: $border-color;
      left: 50%;
      transform: translateX(-50%);
      @include mq($medium-down) {
        display: none;
      }
     }
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 18px 27px;
    gap: 20px;
    // border-radius: $main-border-radius;
    background-color: $primary-bg-color;
    z-index: 2;


    @include mq($medium-down) {
      flex-direction: column;
    }
    @include mq($laptop) {
      flex-direction: column;
    }

    .profile-section__content-top-side {
      @include mq($medium-down) {
        &::after {
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          left: 0;
          bottom: 0;
          background: $border-color;
          left: 0%;
      }
     }
      &:first-child {

        .profile-section__client-info-wrapper {
          display: flex;
          gap: 34px;
          padding-top: 12px;
          margin-bottom: 14px;
  

          @include media-query($small) {
            flex-direction: column;
            align-items: center;
          }
        }

        .profile-section__client-photo-wrapper {
          flex-shrink: 0;
          width: 200px;
          height: 200px;
          background: linear-gradient(white, white) padding-box,
            linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
          border: 1px solid transparent;
          border-radius: 50%;
        }

        .profile-section__client-photo {
          position: absolute;
          left: 50%;
          top: 50%;
          width: 57%;
          height: 68%;
          transform: translate(-50%, -50%);
          object-fit: contain;
        }

        .profile-section__client-info,
        .profile-section__client-btc-info {
          display: block;
          margin-bottom: 19px;
          width: 100%;
          @include media-query($small) {
            // width: 100%;
            text-align: center;
          }
        }

        .profile-section__client-name {
          max-width: 200px;
          font-size: $font-size-1;
          white-space: normal;
          word-wrap: break-word;
          margin-bottom: 26px;
          @include media-query($small) {
            max-width: 100%;
          }
        }

        .profile-section__client-btc-info-title,
        .profile-section__client-usd-info-title {
          font-weight: 600;
          margin-bottom: 7px;
        }

        .profile-section__client-usd-info-balance {
          font-weight: 700;
          font-size: $font-size-6;
        }
      }

      &:nth-child(2) {
        height: 150px;
        min-width: 420px;
        padding: 25px 25px 20px 25px;
        background: linear-gradient(white, white) padding-box,
          linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
        border: 1px solid transparent;
        border-radius: $main-border-radius;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mq($laptop) {
          min-width: max-content;

        }

        @include media-query($medium-down) {
          min-width: unset;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }

        .profile-section__heading {
          margin-bottom: 8px;
          font-weight: 600;
        }

        .profile-section__input {
          margin-bottom: 19px;
          position: relative;
          width: 100%;

          .form-container {
            display: block;
            height: 100%;

            label {
              position: absolute;
              right: 13px;
              top: 50%;
              transform: translate(0, -50%);
              padding: 0;
              cursor: pointer;
              z-index: 1;

              &:hover {
                color: $primary-hover-color;
              }
            }

            input {
              // width: 210px;
              // min-width: 210px;
              font-size: $font-size-8;
            }

            &:after {
              content: "";
              position: absolute;
              right: 1px;
              top: 1px;
              bottom: 1px;
              width: 60px;
              border-radius: $main-border-radius;
              height: calc(100% - 2px);
              background: rgb(0, 0, 0);
              background: linear-gradient(
                90deg,
                rgba(0, 0, 0, 0.5676645658263305) 0%,
                rgba(0, 0, 0, 1) 58%
              );
            }
          }
        }

        .profile-section__social-links-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          gap: 13px;
        }

        .profile-section__social-links-title {
          font-size: $font-size-8;
          font-weight: 700;
        }

        .profile-section__social-links-list {
          display: flex;
          gap: 6px;
        }

        .profile-section__social-links-list-item {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 24px;
          height: 24px;
          // background-color: $primary-color;
          background: linear-gradient(0deg, #337fd0 0%, #9f38d2 100%);
          color: $primary-bg-color;
          border-radius: 50%;

          &:hover {
            background-color: $primary-hover-color;
          }
        }
      }
    }
  }

  .profile-section__content-bottom {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    padding: 0px 32px 35px 47px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: $primary-bg-color;
    @include media-query($medium-down) {
      justify-content: center;
      flex-wrap: wrap;
      padding-top: 14px;
    }

    @include media-query($extra-small) {
      padding: 8px 27px 20px;
      justify-content: flex-start;
    }
    .profile-section__client-contacts {
      display: flex;
      flex-wrap: wrap;
      column-gap: 40px;
      row-gap: 31px;

      @include media-query($extra-small) {
        margin-left: 0;
      }
    }
    .profile-section__button-wrapper {
      display: flex;
      min-width: 488px;
      justify-content: space-between;
      @include media-query($w600) {
        min-width: 100%;
        flex-direction: column;
        gap: 24px;
        align-items: center;
      }
    }

    .profile-section__client-contacts-item {
      display: flex;
      flex-direction: column;
      gap: 2px;
    }

    .profile-section__client-contacts-item-title {
      font-size: $font-size-8;
      font-weight: 600;
    }

    .profile-section__client-contacts-item-link {
      font-size: $font-size-8;
      text-decoration: underline;
    }

    .profile-section__big-button {
      display: flex;
      max-width: 220px;
      height: 100px;
      width: 100%;
      overflow: hidden;
      background: linear-gradient(white, white) padding-box,
        linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
      border: 1px solid transparent;
      border-radius: 10px;
      .profile-section__big-button-left {
        align-items: flex-start;
      }


      &:hover {
        .profile-section__big-button-left {
          background: linear-gradient(0deg, #5157ae 0%, #662da6 100%)
              padding-box,
            linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
          color: $secondary-color;
        }
      }
      .profile-section__big-button-left {
        width: 70%;
        display: flex;
        flex-direction: column;
        color: inherit;
        padding: 27px 20px 19px 19px;

        transition: $transition-duration;
      }

      .profile-section__big-button-title {
        font-weight: 600;
        min-height: 2.5rem;
      }

      .profile-section__big-button-state {
        font-size: $font-size-8;
        font-weight: 600;
      }

      .profile-section__big-button-right {
        position: absolute;
        right: -80px;
        top: 50%;
        transform: translate(0, -50%);
        width: 146px;
        height: 146px;
        border-left: $main-border;
        border-radius: 50%;
        background-color: $primary-bg-color;
        transition: $transition-duration;

        svg {
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          left: 23px;
          path {
            fill: $primary-bg-color;
          }
        }
      }
    }
  }
}
.dark-profile {
.profile-section__big-button-title, .profile-section__big-button-state {
  color: $primary-color;
}
  .profile-section__wrapper {
    background: $block-dark-bg;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background-color: unset;
  }
  .profile-section__content-bottom {
    background-color: unset;
  }
  .profile-section__content-top {
    background-color: unset;
  }
  .profile-section__client-contacts-item-link {
    color: $dark-profile-page-text;
  }
  .profile-section__client-photo-wrapper {
    background: unset !important;
    border: 2px solid $purple-contrast !important;
    border-radius: 50%;
  }
  .profile-section__content-top-side:nth-child(2) {

&::after {
content: "";
position: absolute;
top: -16px;
left: -20px;
z-index: -1;
width: 132px;
height: 62px;
transform: rotate(-20deg);
background: $purple-lightened;
border-radius: 8px;
box-shadow: 0px 5px 8px 0px rgba(61, 46, 152, 0.07);



}
&::before {
  content: "";
  position: absolute;
  top: 10px;
  left: -40px;
  z-index: -2;
  width: 132px;
  height: 62px;
  transform: rotate(-20deg);
  background: $purple-lightened;
  border-radius: 8px;
  }
background: linear-gradient(293deg, #7D67FF 31.56%, rgba(147, 129, 255, 0.75) 97.92%) !important;
  }
  .configure-ai__label {
    background: $purple-contrast;
    opacity: 0.9;
  }
  .profile-section__social-links-list-item {
    background: $secondary-color !important;
    color: $purple-contrast;
    border-radius: 50%;

    &:hover {
      background-color: $primary-hover-color;
    }

    path {
      fill: $purple-contrast !important;
    }
  }
  .profile-section__social-links-list {
    a:last-of-type {
      svg {
        width: 13px;
      }
      path {
        fill: revert-layer;
      }
    }
  }
  .profile-section__big-button {
      &:hover,
    &:focus {
        .profile-section__big-button-left {
      background: $purple-lightened !important;
        }
        .profile-section__big-button-title, .profile-section__big-button-state {
          color: $secondary-color;
        }

    }
    background: $primary-bg-color !important;
    border: none !important;
    circle {
      fill: $purple-lightened;
      stroke: $purple-lightened;
    }
  }

  


}

.dark-profile {
  .profile-section__content-top, .profile-section__content-bottom {
    background-color: $block-dark-bg;
  }
    .profile-section__balance-page {
      border: none !important;
          .profile-section__widthdrawal-btn {
      background: $primary-bg-color;
    color:$block-dark-bg;
    }

    }
    .balance_page-sessions-section {
      border: 2px solid rgba(255, 255, 255, 0.10) !important;
    }

}