.coin-selection-wrapper {
  .coin-selection-label {
    font-size: 14px;
    font-weight: 700;
  }
  .coin-selection-block {
    position: relative;
    @include flex-center;
    margin-bottom: 20px;
    justify-content: space-between;
    border: 1px solid $primary-color;
    border-radius: 10px;
    padding-left: 13px;
    padding-top: 13px;
    padding-right: 39px;
    padding-bottom: 11px;

    input[type="number"] {
      background: transparent;
      color: $primary-color;
      appearance: none;
      -moz-appearance: textfield;
      border-radius: 10px;
      padding-left: 15px;
      padding-top: 13px;
      padding-bottom: 13px;
      border: none;
      font-size: 14px;
      width: 40%;
      outline: none;
    }
    input[type="number"]::placeholder {
      color: $primary-color;
    }
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      appearance: none;
    }
  }
  .coin-selection-block::after {
    content: "";
    background: $primary-color;
    width: 1px;
    height: 46px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .coin-select {
    width: 50%;
    button {
      @include flex-center;
      background: transparent;
      border: none;
      gap: 8px;
      svg {
        width: 35px;
        path {
          fill: $secondary-bg-color;
        }
      }
    }
  }
  .coin-select-menu {
    position: absolute;
    top: 0;
    border-radius: 10px;
    padding-left: 13px;
    padding-top: 13px;
    padding-right: 39px;
    padding-bottom: 11px;
    left: 0;
    width: 100%;
    display: none;
    background: transparent;
    outline: 1px solid $primary-color;
    list-style: none;
    button {
      color: $secondary-color;
    }
  }
  .coin-select-menu.expanded {
    display: block;
    background: rgba(0, 0, 0, 0.9);
    backdrop-filter: blur(100px);
  }
}
.dark-profile {
  input[type="number"] {
    // color: $secondary-color;
  }
  .coin-selection-block {
    border: 1px solid $secondary-color;
  }
}
