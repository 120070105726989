.progress-bar-wrapper {
  position: relative;
  max-width: 600px;
  margin-bottom: 30px;

  .range-list {
    @include flex-center;
    justify-content: space-between;
    padding: 0;
    margin-bottom: 6px;
    font-size: 10px;
    font-weight: 700;
    line-height: 180%;
    list-style: none;
  }

  input[type="range"] {
    z-index: 20;
    width: 100%;
    opacity: 0.6;
    height: 6px;
    appearance: none;
    border-radius: 10px;
    border: none;
    -webkit-appearance: none;
    position: absolute;
    opacity: 0.9;
    background: $common-background-gradient no-repeat, rgba(103, 103, 103, 0.5); /* Set default background color */
  }
  input[type="range"]::-webkit-slider-thumb {
    position: relative;
    z-index: 20;
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background: $common-background-gradient;
    border-radius: 100%;
    opacity: 1;
    z-index: 2;
  }
  .range-input-thumb {
    position: relative;
    margin-bottom: 16px;
  }
  .range-points-container {
    height: 6px;
    position: absolute;
    display: flex;
    justify-content: space-between;
    width: 100%;
    list-style: none;
    .rangePoint {
      position: absolute;
      width: 6px;
      height: 6px;
      background-color: $primary-hover-color;
      border-radius: 50%;
      z-index: 1;
      top: 0px;
    }
    .rangePoint.selected {
      opacity: 0.3;
    }
    .rangePoint.transparent {
      background-color: transparent; /* Change color for selected points */
    }
  }
  .progress-bar-list {
    margin-bottom: 25px;
    position: relative;
  }
  .coin-amount-wrapper {
    text-align: right;
    font-size: 13px;
    font-weight: 700;
  }
}

.dark-profile {
  input[type="range"]::-webkit-slider-thumb {
    background: $dark-profile-gradient;
  }
  input[type="range"] {
    background: $dark-profile-gradient no-repeat, rgba(255, 255, 255, 0.5); /* Set default background color */
  }
}
