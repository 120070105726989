.details-section {
  width: 100%;
  margin-top: 50px;
  background: linear-gradient(white, white) padding-box,
    linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
  border: 2px solid transparent;
  border-radius: 20px;
  overflow: hidden;
  padding: 35px 10px 36px 10px;
  @include mq($mobile) {
    padding-left: 0;
    padding-right: 0;
  }

  .details-section__main-heading {
    font-weight: 500;
    margin: 0;
    margin-bottom: 29px;
  }

  .details-section__form-submit-btn {
    @extend .algorithms-section__item-button;
    @include mq($mobile) {
      width: 100%;
    }
    font-weight: 700;
    line-height: 120%; /* 16.8px */
    width: 180px;
    max-width: 180px;
    margin-left: auto;
    margin-right: auto;
  }
  .details-section__form-submit-btn:disabled {
    background: gray !important;
    cursor: not-allowed;
    opacity: 0.6;
  }
  .details-section__wrapper {
    border: $main-border;
    border-radius: $main-border-radius;
    z-index: 1;
  }
  .error-container {
    margin-bottom: 30px;
    color: red;
  }
  .details-section__heading {
    font-size: $font-size-5;
    font-weight: 700;
    margin-bottom: 35px;
  }

  .details-section__progress-bar-wrapper {
    // max-width: 600px;
    margin-bottom: 30px;

    .range__list {
      padding: 0;
      margin-bottom: 6px;
    }

    .form-container.form-container--range input[type="range"] {
      -webkit-appearance: none;
      position: relative;
      opacity: 0.9;
      // background-color: #676767;
      background: linear-gradient(
            to right,
            #337fd0 0%,
            #3f77b3 0.01%,
            #6927a4 53%,
            #9f38d2 100%
          )
          no-repeat,
        #676767; /* Set default background color */
    }
    .range-points-container {
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 18%;
      width: 100%;
      list-style: none;
      .rangePoint {
        width: 6px;
        height: 6px;
        background-color: #ff81fe;
        border-radius: 50%;
        position: absolute;
        z-index: 1;
        top: 0;
        transform: translateY(-50%);
      }
      .rangePoint.selected {
        opacity: 0.3;
      }
      .rangePoint.transparent {
        background-color: transparent; /* Change color for selected points */
      }
    }
    .form-container.form-container--range {
      @include mq($mobile) {
        padding-right: 10px;
      }
    }
    .form-container.form-container--range
    
      input[type="range"]::-webkit-slider-thumb {
      position: relative;
      z-index: 77;
      -webkit-appearance: none;
      width: 15px;
      height: 15px;
      background: linear-gradient(
        360deg,
        #337fd0 0%,
        #3f77b3 0.01%,
        #6927a4 53%,
        #9f38d2 100%
      );
      border-radius: 100%;
      opacity: 1;
      z-index: 2;
    }
    .details-section__progress-bar {
      margin-bottom: 25px;
      position: relative;
    }

    .details-section__progress-bar-description {
      max-width: 238px;
      font-size: 10px;
      font-weight: 500;
      line-height: 130%;
    }
  }

  .details-section__footer {
    @include mq($mobile) {
      grid-template-columns: 1fr;
    }
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
    gap: 24px;
    margin-bottom: 25px;

    @include media-query($medium-down) {
      flex-direction: column;
      gap: 20px;
    }

    .details-section__footer-column {
      // min-width: 33%;
      display: flex;
      flex-direction: column;
      // justify-content: flex-end;

      @include media-query($medium-down) {
        max-width: unset;
      }
    }

    .details-section__footer-column-heading {
      margin-bottom: 20px;
      font-size: 26px;
      font-weight: 500;
    }

    .details-section__footer-column-checkboxes {
      padding-left: 29px;
      padding-top: 29px;
      padding-right: 28px;
      padding-bottom: 34px;
      border-radius: 14px;
      border: 1px solid $purple-contrast;

      .form-container--checkbox {
        &:first-child {
          margin-bottom: 7px;
        }
        .form__custom-checkbox + label::after {
          left: 5px;
          transform: translateX(-16%);
        }
        input {
          &:checked {
            + label::before {
              background: transparent;
              border-color: transparent;
            }
          }
        }

        label {
          font-size: $font-size-9;
          font-weight: 700;

          &:before {
            width: 18px;
            height: 18px;
            margin-right: 3px;
          }
        }
      }
    }

    .details-section__footer-column-description {
      margin-top: 5px;
      // max-width: 130px;
      font-size: $font-size-9;
      font-weight: 500;
      line-height: 130%;
    }

    .details-section__form-submit-btn {
      width: fit-content;
      margin-left: auto !important;
    margin-right: auto !important;

    @include mq($mobile) {
      margin: 0 !important;
    }
    }
  }
}

.details-section.dark-profile {
  border: none;
  .details-section__wrapper {
    border: none;
  }
  .details-section__progress-bar-wrapper
    .form-container.form-container--range
    input[type="range"] {
    background: linear-gradient(to right, $purple-contrast, $purple-contrast)
        no-repeat,
      rgba(103, 103, 103, 1);
      border-color: transparent;
  }
  .details-section__progress-bar-wrapper
    .form-container.form-container--range
    input[type="range"]::-webkit-slider-thumb {
    background: $purple-contrast;
  }
  .details-section__footer-column-heading {
    color: $purple-contrast;
  }
  .details-section__footer-column-checkboxes .form-container--checkbox label {
    color: $secondary-color;
  }
  .details-section__footer-column-checkboxes {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: $block-dark-bg;
  }
  .details-section__form-submit-btn {
    background: $dark-profile-page-text;
    color: rgba(77, 77, 77, 1);
  }
}
