.statistics-page {
  .dark-profile {
    .statistics-section__balance-wrapper, .statistics-section__graphics-row {
      background: $block-dark-bg !important;
      border: 1px solid $border-color !important;
      .statistics-section__balance-counter {
color: $secondary-color;
      }
      
    }
  }
  .statistics-section {
    overflow: hidden;
    margin-top: 50px;
    margin-bottom: 150px;

    @include media-query($small) {
      margin-bottom: 130px;
    }
  }

  .statistics-section__main-heading {
    margin-top: 50px;
    margin-bottom: 15px;
  }
  .statistics-section__balance-wrapper {
    padding-top: 17px;
    padding-bottom: 16px;
    padding-left: 25px;
    gap: 59px;
    margin-bottom: 64px;
    display: flex;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
    border: 1px solid transparent;
    border-radius: 20px;

    @include media-query($small) {
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;
    }

    .statistics-section__balance-current {
      margin-right: 68px;
      font-weight: 500;

      .statistics-section__balance-counter--usdt {
        font-size: 14px;
        color: $primary-color;
      }
    }

    .statistics-section__balance-heading {
      font-weight: 500;
      font-size: $primary-font-size;
    }

    .statistics-section__balance-counter {
      display: flex;
      align-items: center;
      color: $primary-color;
      font-weight: 700;
    }

    .statistics-section__balance-counter--eq {
      margin: 0 5px;
    }

    .statistics-section__balance-revenue-per-day {
      margin-right: 31px;
    }
  }

  .statistics-section__graphics-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 17px;
  }

  .statistics-section__graphics-column {
    display: flex;
    flex-direction: column;

    &:first-child {
      width: 51.5%;
      gap: 20px;
      overflow: hidden;
      .statistics-section__graphics-row {
        min-height: 175px;
      }
      @include media-query($medium-down) {
        width: 100%;
      }
     

      .statistics-section__graphics-row {
        background: linear-gradient(white, white) padding-box,
          linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
        border: 1px solid transparent;
        border-radius: 20px;
        .statistics-section__graphics-row-body {

          canvas {
            max-height: 88px;
            height: 100%;
            width: 100%;
            @include mq($mobile) {
              width: 100%;
              height: 100%;
            }
          }
        }

        &:nth-child(2) {
          canvas {
            max-height: unset;
            width: 100%;
          }
        }
      }
    }

    &:last-child {
      width: calc(100% - 51.5% - 17px);
      gap: 21px;

      @include media-query($medium-down) {
        width: 100%;
      }

      .statistics-section__graphics-row {
        min-height: 290px;
        background: linear-gradient(white, white) padding-box,
          linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
        border: 1px solid transparent;
        border-radius: 20px;
        &:first-child {
          .statistics-section__graphics-row-heading-wrapper {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
  .statistics-section__graphics-row {
    padding: 31px 38px;
    border: $main-border;
    border-radius: $main-border-radius;

    @include media-query($small) {
      padding: 10px 10px;
    }
  }

  .statistics-section__graphics-row-heading-wrapper {
    display: flex;
    gap: 17px;
    font-weight: 700;
    font-size: $primary-font-size;
  }
  .statistics-section__graphics-row-heading {
    margin-bottom: 0;
  }


  .statistics-section__graphics-row-body {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 11px;
    @include mq($mobile) {
      flex-direction: column;
    }
    .statistics-section__graphics-row-body-column {
      max-width: 174px;

      .statistics-section__graphics-row-vaults-list {
        display: flex;
        flex-direction: column;
        gap: 12px;
      }

      .statistics-section__graphics-row-vaults-item {
        position: relative;
        display: flex;
        margin-left: 20px;
        font-weight: 700;

        &:nth-child(1):before {
          background-color: #ff0000;
        }
        &:nth-child(2):before {
          background-color: #ff7508;
        }
        &:nth-child(3):before {
          background-color: #47b64c;
        }
        &:before {
          content: "";
          position: absolute;
          left: -20px;
          top: 50%;
          transform: translate(0, -50%);
          width: 10px;
          height: 10px;
          background-color: var(--bg-color);
          border-radius: 50%;
        }

        .statistics-section__graphics-row-vaults-item-currency {
          margin-right: 5px;
        }
      }
    }
  }
  .bots-weekly-percentage{
    display: flex;
    flex-direction: column;
  }
  .bots-weekly-percentage.pro {
    background: $pro-gradient !important;
}
.bots-weekly-percentage.combo {
  background: linear-gradient(90deg, rgba(253,29,29,1) 8%, rgba(252,176,69,1) 75%) !important;
}
  .chart-title-wrapper {
    display: flex;
    gap: 4px;
    align-items: center;
    margin-bottom: 10px;

    button {
      background: transparent;
      color: $primary-color;
      border: 1px solid $primary-color;
      border-radius: 10px;
      padding: 4px 6px;

    }
    button.selected {
      background: $common-background-gradient;
      color: $secondary-color;
    }
  }

  .statistics-section__footer {
    margin-top: 13px;
    font-size: $font-size-9;
    font-weight: 500;
    line-height: 1.8;
  }
}

.dark-profile {
  .chart-title-wrapper {

    button {
      background: transparent;
      color: $secondary-color;
      border: 1px solid $purple-contrast;
    }
    button.selected {
      background: $purple-contrast;

    }
  }

}