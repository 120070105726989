.balance-section_upper-wrapper {
    
    margin-bottom: 38px;
    
    
@include media-query($medium-down) {
    flex-wrap: wrap;
};
    .profile-section__content-top-side {
        flex-shrink: 0;
            @include media-query($w1000) {
    width: 100%;
}
    }

}
.balance-page_referal-form {
    height: 158px !important;
    width: 100% !important;
    padding: 10px 19px !important;
    border-radius: $main-border-radius !important;
    background: linear-gradient(180deg, #337fd0 0%, #3f77b3 0.01%, #6927a4 53%, #9f38d2 100%);
}
.balance-section_currency-container {
    display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
    gap: 32px;
    margin-bottom: 20px;
}
.balance-section_trading-wrapper {
    &:not(:last-child) {
        margin-bottom: 20px;
    }

    @extend .balance-section_upper-wrapper;
 
    margin-bottom: 0;
    align-items: stretch;
    justify-content: flex-start;
    flex-direction: column;
    flex-wrap: wrap;
    row-gap: 32px;
    padding-left: 20px;
    padding-top: 20px;
    padding-right: 27px;
    padding-bottom: 34px;
    border: 1px solid #4d4d4d;
    border-radius: 8px;

    background: linear-gradient(180deg, #337fd0 0%, #3f77b3 0.01%, #6927a4 53%, #9f38d2 100%);
// background-size: 150% 150%;
background-position: 120% 120%;
    color: $secondary-color;



}
.balance-section_trading-wrapper.bonus {
    width: 100%;
    // display: flex;
}
.deposit-window__currency-address-wrapper {
    width: 100%;
    margin-bottom: 46px;
}
.copy-email {
    width: 100%;
}
// .balance-section_currency-container {
//     display: flex;
//     // justify-content:space-between;
//         gap: 16px;

//     @include media-query($small) {
//         flex-direction: column;
//     }

// }
.balance-section_trading-group-wrapper {
display: flex;
flex-direction: column;
// justify-content: space-between;
align-items: flex-end;

gap: 12px;
// align-items: flex-end;
position: relative;
.align-left {
    flex-direction: column;
    align-items: flex-start;
    .balance-block-wrapper {
        width: 100%;
    
}
}
.balance-block-wrapper {

    .coin-name-wrapper {
        @include flex-center;
        gap: 9px;
        margin-bottom: 14px;
  
      }
      @include mq($mobile) {
        width: 100%;
    }
}

@include media-query($w1000) {
    flex-wrap: wrap;
    row-gap: 20px;
}

}
.balance-section_trading-group-wrapper:last-of-type {
flex-direction: row;
flex-wrap: wrap;


}


.balance_section-heading {
font-weight: 500;
font-size: $font-size-3;
font-family: $first-family;
margin-bottom: 30px;
}
.balance-section_buttons-wrapper {
    display: flex;
    column-gap: 43px;
    @include media-query($small) {
        flex-direction: column;
    }
}
.balance-section_left-side {
display: flex;
flex-direction: column;
width: 100%;
// justify-content: flex-end;
      @include media-query($w1000) {
    width: 100%;
}
}
.balance-section_currency_title {
    font-weight: 500;
    font-size: 19px;
    font-family: $first-family;
    margin-bottom: 2px;
    display: block;
}
// .balance-section_info_thumb {
//     display: flex;
//     justify-content: space-between;
// }
.balance-section_info_name {
    font-weight: 500;
font-size: 14px;

}
.balance-section_info_value {

    font-weight: 700;
    font-size: 17px;

}
.trading_section-currency-container {
    @extend .balance-section_currency-container;

}
.trading_section-transfer-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 183px;
    height: 42px;
    border: none;
    background-color: $primary-bg-color;
    text-decoration: none;
    border-radius: $main-border-radius;
    overflow: hidden;
    padding-top: 11px;
    padding-bottom: 11px;

    @include media-query($small) {
        width: 100%;
    }
}
.trading__section-button {

    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    outline: none;
    border: none;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 700;
    line-height: 122%;
font-size: 16px;
color: #2d2d2d;

}

.dark-profile {

 .balance-section_trading-group-wrapper {
    .balance-section_left-side {
        border-color: transparent;
     }
 }
    .balance-section_trading-wrapper {
      border: 1px solid $border-color !important;
      background: $block-dark-bg;

    }
      .profile-section__widthdrawal-btn {
    background: $primary-bg-color;
    color: $dark-profile-page-background;
  }
  .balance-page_referal-form {
    background: $purple-contrast !important;
    .profile-section__social-links-wrapper {
          .profile-section__social-links-list-item {
    background: $primary-bg-color !important;
    color: $primary-bg-color;
    border-radius: 50%;

    &:hover {
      background-color: $primary-hover-color;
    }

    path {
      fill: rgba(68, 85, 145, 1) !important;
    }
  }
  .profile-section__social-links-list {
    a:last-of-type {
      path {
        fill: revert-layer;
      }
    }
  }
    }

    .copy-email-label {
        path {
            fill: #445591;
        }
    }
  }
}