.custom-select-wrapper {
  display: flex;
  width: 173px;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  gap: 22px;
  margin-bottom: 20px;
  @include media-query($small) {
    justify-content: flex-end;
  }
}
.custom-select-label {
  display: block;
}

.custom-select-pages {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 16px;
  padding-bottom: 16px;
  background: linear-gradient(white, white) padding-box,
    $common-background-gradient border-box;
  position: relative;
  // min-width: 56px;
  // border: $main-border;
  border-radius: $main-border-radius;
  border: 1px solid transparent;
  font-weight: 600;
  font-size: $font-size-7;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    span {
      color: $primary-color;
      margin-right: 4px;
    }
    .dark {
      color: $dark-theme-text-primary;
    }
  }
  svg {
    path {
      fill: $primary-color;
    }
  }
  ul {
    position: absolute;
    bottom: -1;
    left: 0;
    z-index: 10;
    transform: translateX(-1px);

    background: linear-gradient(white, white) padding-box,
      $common-background-gradient border-box;
    list-style: none;
    width: 100%;
    border-bottom-left-radius: $main-border-radius;
    border-bottom-right-radius: $main-border-radius;
    border: 1px solid transparent;
    border-top: none;

    li {
      padding-left: 12px;
    }
  }
  // .custom-select__dropdown.active {
  //   //   border: $main-border;
  // }
}
.dark-profile {
  .custom-select-wrapper {
    background: $block-dark-bg;
  }
  .custom-select-pages {
      background: $block-dark-bg;
  border: 1px solid $border-color;
  span {
    color: $dark-profile-page-text;
  }
  // path {
  //   fill: $dark-profile-page-text;
  // }
  ul {
   
          background: $block-dark-bg;
  border-bottom: 1px solid $border-color;
  border-left: 1px solid $border-color;
  border-right: 1px solid $border-color;
  }
  .link {
     color: $dark-profile-page-text;
  }
  }
}