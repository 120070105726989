.restore-pass-page--2fa {
  gap: 94px;

  .pass-section__side {
    gap: 26px;
  }

  .pass-section__headings-wrapper {
    margin-bottom: 26px;
  }

  .form__pincode-wrapper  {
    display: flex;
    gap: 15px;
    margin-bottom: 26px;

    @include media-query($small) {
      gap: 10px;
    }
  }

  .btn {
    margin: 0 auto;
  }
}
