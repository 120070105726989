.form-container {
	.form-container__successful-message-wrapper {
		min-width: 200px;
		width: 100%;
		position: absolute;
		top: -2px;
		right: calc(-100% - 14px);
		display: flex;
		align-items: center;
		gap: 9px;
		padding: 12px 9px 11px 21px;
		font-weight: 700;
		background-color: $primary-color;
		color: $alternative-color;
		border-radius: $main-border-radius;
		transition: $transition-duration;

		&[aria-hidden="true"] {
			opacity: 0;
			visibility: hidden;
		}

		&[aria-hidden="false"] {
			opacity: 1;
			visibility: visible;
		}

		svg {
			height: 18px;
			width: 18px;
		}

		&:before {
			content: "";
			position: absolute;
			left: -1px;
			top: 50%;
			transform: translate(0, -50%) rotate(-45deg);
			width: 18px;
			height: 18px;
			background-color: $primary-color;
		}
	}

	.password__container {
		position: relative;
	}

	input, textarea, .custom-input, input:-webkit-autofill {
		padding: 10px 15px;
		color: $primary-color;
		outline:none;
		width: 100%;
		// min-width: 305px;
		background: unset;
		border-radius: $main-border-radius;
		border: $main-border;
		font-family: $secondary-font;
		font-size: $primary-font-size;
		line-height: 1.2;
		font-weight: 400;

		&::placeholder {
			color: $primary-color;
		}

		&:focus {
			border: $main-border;
		}
	}

	&.error input {
		border: 1px solid $primary-error-color;
	}

	.form__error-message {
		position: absolute;
		top: 105%;
		display: none;
		color: $primary-error-color;
		font-family: $primary-font;
		font-size: $font-size-8;
		padding-left: 15px;
	}

	&.error .form__error-message {
		display: block;
	}

	label {
		display: block;
		font-family: $primary-font;
		color: $primary-color;
		padding: 0 0 7px 15px;
		line-height: 1.2;
		
	}

	&.password {
		.password__eye {
			position: absolute;
			display: flex;
			bottom: 50%;
			right: 12px;
			transform: translateY(50%);
			cursor: pointer;
			transition: $transition-duration;
			background-color: unset;
			border: unset;
			color: $primary-color;
		}

		.password__eye:hover,
		.active .password__eye {
			color: $primary-hover-color;
		}
	}
}

.form-container--checkbox.checkbox {
	display: flex;
	flex-direction: row-reverse;
	justify-content: flex-end;
	width: fit-content;

	label {
		font-family: $primary-font;
		line-height: 1.2;
		font-size: $primary-font-size;
		width: auto;
		cursor: pointer;

		@include media-query($small) {
			display: block;
		}
	}

	.form__custom-checkbox {
		position: absolute;
		z-index: -1;
		opacity: 0;
		padding-bottom: 0;
		cursor: pointer;
	}

	.form__custom-checkbox + label {
		position: relative;
		display: inline-flex;
		align-items: center;
		user-select: none;
		color: $primary-color;
	}

	.form__custom-checkbox + label::before {
		content: '';
		display: inline-block;
		width: 20px;
		height: 20px;
		flex-shrink: 0;
		background: linear-gradient(white, white) padding-box,
        linear-gradient(0deg, #5157ae 0%, #662da6 100%) border-box;
		// background: transparent !important;
		flex-grow: 0;
		border: 1px solid transparent;
		border-radius: 50%;
		// margin-right: 20px;
		background-repeat: no-repeat;
		background-position: 0 center;
		background-size: 110% 110%;
	}

	.form__custom-checkbox + label::after {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		display: inline-block;
		
		transform: translateX(-10%);
		width: 24px;
		height: 20px;
		// flex-shrink: 0;
		// flex-grow: 0;
		// margin-right: 20px;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: 100% 100%;
	}

	.form__custom-checkbox:checked + label::before {

		background-color: transparent;
	}

	.form__custom-checkbox:checked + label::after {
	
		background-image: url("../../icons/completed.svg")
	}

	.form__custom-checkbox:not(:disabled):not(:checked) + label:hover::before {
		border-color: $primary-hover-color;
	}

	.form__custom-checkbox:not(:disabled):active + label::before {
		background-color: $primary-hover-color;
		border-color: $primary-hover-color;
	}

	.form__custom-checkbox:focus + label::before {
		box-shadow: 0 0 0 0.2rem rgba(255, 165, 0, 0.25);
	}

	.form__custom-checkbox:focus:not(:checked) + label::before {
		border-color: $primary-hover-color;
	}

	.form__custom-checkbox:disabled + label::before {
		background-color: #e9ecef;
	}
}

	.form__pincode {
		display: flex;
		flex-direction: column;
		align-items: center;

		input {
			min-width: unset;
			width: 40px;
			height: 40px;
		}
	}

.form {
	.form__buttons {
		.btn {
			width: fit-content;
			margin: 0 auto;
		}
	}

	.form-container--image {
		min-height: 240px;
		text-align: center;
		padding: 31px 30px 12px;
		border: $main-border;
		border-radius: $main-border-radius;

		label.focus {
			outline: none!important;
		}

		input {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			position: absolute;
			z-index: -10;
		}

		label.btn {
			@extend .btn;
			@extend .btn--secondary;

			padding: 5px 8px;
			margin: 0 auto;
			display: flex;
			align-items: center;
			width: fit-content;
			gap: 7px;
			border-radius: 5px;
			line-height: 1;
			font-size: $font-size-8;

			svg {
				display: flex;
				margin: auto;
			}
		}
		

		.file-input-reset {
			padding-top: 50px;
			position: relative;
			margin-top: -40px;
			width: 100%;
			background: black;
			text-align: center;
		}

		.form-set-image {
			position: relative;
			max-width: 180px;
			width: 100%;
			height: 240px;
			margin: 0 auto;
			background-size: cover;
			background-position: center center;
			z-index: 1;
		}


		.form-container--placeholder {
			position: absolute;
			display: flex;
			flex-direction: column;
			align-items: center;
			top: 31px;
			left: 50%;
			transform: translate(-50%,0);
			background-color: $primary-bg-color;
			font-size: $font-size-5;
			font-weight: 700;
			
		}

		.form-container--placeholder-icon-wrapper {
			max-width: 110px;

			svg {
					
			}
		path {
			

		}
		}

		.form-container--placeholder-title {
			margin-top: 14px;
			font-weight: 700;
			font-size: 16px;
			// background: $common-background-gradient;
		}
	}

	.form-container--file {
		position: relative;

		.custom-input {
			min-height: 38px;
		}

		input {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			position: absolute;
			z-index: -10;
		}
	}

}

.form-container.form-container--range {
	padding: 0;

	label {
		padding-bottom: 22px;
	}

	input[type=range] {
		position: relative;
		height: 6px;
		padding: 0;
		-webkit-appearance: none; /* Hides the slider so that custom slider can be made */
		width: 100%; /* Specific width is required for Firefox. */
		background: transparent; /* Otherwise white in Chrome */
		z-index: 1;
	}

	input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
	}

	input[type=range]:focus {
		outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
	}

	input[type=range]::-ms-track {
		width: 100%;
		cursor: pointer;

		/* Hides the slider so custom styles can be added */
		background: transparent;
		border-color: transparent;
		color: transparent;
	}

	/* Special styling for WebKit/Blink */
	input[type=range]::-webkit-slider-thumb {
		-webkit-appearance: none;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: $primary-color;
		box-shadow: 0 4px 14px $primary-hover-color;
		cursor: pointer;
		margin-top: -2px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
	}

	/* All the same stuff for Firefox */
	input[type=range]::-moz-range-thumb {
		-webkit-appearance: none;
		height: 10px;
		width: 10px;
		border-radius: 50%;
		background: $primary-color;
		cursor: pointer;
	}

	/* All the same stuff for IE */
	input[type=range]::-ms-thumb {
		-webkit-appearance: none;
		height: 40px;
		width: 40px;
		border-radius: 50%;
		background: $primary-color;
		cursor: pointer;
	}

	input[type=range]::-webkit-slider-runnable-track {
		width: 100%;
		height: 6px;
		cursor: pointer;
		background: rgba(147, 147, 147, 0.3);
		border-radius: 6px;

	}

	input[type=range]:focus::-webkit-slider-runnable-track {
	}

	input[type=range]::-moz-range-track {
		width: 100%;
		height: 6px;
		cursor: pointer;
		background: rgba(147, 147, 147, 0.3);
		border-radius: 6px;
	}

	input[type=range]::-ms-track {
		width: 100%;
		height: 6px;
		cursor: pointer;
		background: rgba(147, 147, 147, 0.3);
		border-radius: 6px;
	}

	.range__list {
		display: flex;
		justify-content: space-between;
		padding: 10px 0 0;
		margin: 0 -5px -5px -5px;

		.range__list-item {
			position: relative;
			display: flex;
			justify-content: center;
			font-size: $font-size-9;
			font-weight: 500;
			line-height: 1;
			right: -6px;


			&:after {
				// content: "";
				position: absolute;
				top: calc(100% + 2px);
				height: 6px;
				width: 6px;
				border-radius: 50%;
				background: $primary-color;
				cursor: pointer;
				z-index: 0;
			}

			&:first-child {
				&:after {
					left: 6px;
				}
			}

			&:last-child {
				&:after {
					right: 6px;
				}
			}
		}
	}
}
