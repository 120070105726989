// main-nav.scss

  .header-select {
    margin-bottom: 0;
  }
  .custom-select__btn{
    align-items: center;
    display: flex;
    gap: 18px;
    padding: 0;
  }

  .aside-drop-down-container {
    border: none;
    position: relative;
    // overflow: hidden;

    
  }
  .aside-dropdown-inner-wrapper {
    display: flex;
    align-items: center;
    column-gap: 21px;
    margin-bottom: 25px;

    span, a {
      color: $secondary-color;
    }
    a.link:active {
      color: $secondary-color;
    }

  }
  .aside-drop-down-list {
    list-style: none;
    

    & :not(:last-of-type) {
      margin-bottom: 12px;
    }

    a {
      color: $secondary-color;
      position: relative;
    }

    li:not(:last-child) {
      margin-left: 41px;
      // a::before {
      //   content: url('../../icons/list-decoration.svg');
      //   position: absolute;
      //   left: -32px;
      // }
      // a:focus::before {
      //   content: url('../../icons/list-decoration-active.svg');
      //   left: -35px;

      // }

    }
    
  }

  .main-nav__first-lvl {

    padding: 0;
    margin-bottom: 19px;
  }

  .main-nav__first-lvl-item {
    margin-bottom: 10px;
  
    span {
      color: $secondary-color
    }
  }



  .main-nav__second-lvl {
    list-style: none;
    padding-left: 20px;
  }

  .main-nav__second-lvl-item {
    margin-bottom: 5px;
  }

  .link {

    color: $secondary-color; // Adjust color as needed
  }





  .custom-select__btn-text {
    margin-right: 5px;
  }



  .custom-select__dropdown-option {

    cursor: pointer;
  }

  .custom-select__dropdown-option.selected {
    text-decoration: underline;
  }

  .custom-select__input-hidden {
    display: none;
  }


.dark-profile {
.aside-dropdown-inner-wrapper {
  span {
    color: $secondary-color;
  }
  // path {
  //   fill: $secondary-color;
  //   stroke: $secondary-color;
  // }
  ellipse {
    fill: $secondary-color;
  }

}

.main-nav__wrapper {
  .main-nav__first-lvl-item {
    span {
      color: $secondary-color;

      // path {
      //   fill: $secondary-color;
      // }
    }
  }
//     li:not(:last-child) a::before {
// content: url('../../icons/list-decoration-dark.svg');
//   }
//     li:not(:last-child) a:focus::before {
//         content: url('../../icons/list-decoration-active.svg');
//         left: -35px;

//       }
.link {
  color: $secondary-color;
}
}

.custom-select {
.custom-select__btn {
  span {
    color: $secondary-color;
  }
  // &> svg {
  //   path {
  //     stroke: $secondary-color;
  //   }
  // }
  // svg {
  //   path {
  //     fill: $secondary-color;
  //   }
  // }
}
.custom-select__dropdown {
  background-color: $dark-profile-page-background;
}
}

}