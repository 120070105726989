@mixin adaptiv-font($pcFontSize, $mobFontSize) {
	$addSize: $pcFontSize - $mobFontSize;
	$addMobSize: $addSize + $addSize * 0.7;
	@media (max-width: 749px) {
		font-size: calc(#{$mobFontSize + px} + #{$addMobSize} * ((100vw - 375px) / #{$container-width}));
	}
	@media (min-width: 750px) {
		font-size: calc(#{$mobFontSize + px} + #{$addSize} * (100vw / #{$container-width}));
	}
	@media (min-width: #{$container-width}px) {
		font-size: #{$pcFontSize}px;
	}
}


@mixin media-query($media-query) {
	$breakpoint-found: false;

	@each $breakpoint in $breakpoints {
		$name: nth($breakpoint, 1);
		$declaration: nth($breakpoint, 2);

		@if $media-query == $name and $declaration {
			$breakpoint-found: true;

			@media only screen and #{$declaration} {
				@content;
			}
		}
	}

	@if $breakpoint-found == false {
		@warn 'Breakpoint "#{$media-query}" does not exist';
	}
}

// animations
@mixin fadeInAndSlideIn($side) {
  @keyframes fadeInAndSlideIn-#{$side} {
    0% {
      opacity: 0;
      transform: translateX(#{if($side == 'right', 100%, -100%)});
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
}
// usage example: animation: fadeInAndSlideIn-right 1s ease; /* Apply the fadeInAndSlideIn animation from the right */
@mixin fadeIn {
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
@mixin flex-center {
  display: flex;
  align-items: center;
}
@mixin rotateAnimation($angle) {
  @keyframes rotateAnimation {
    0% {
       -webkit-transform: rotate(0deg) translate(0, 0); /* Safari */
      transform: rotate(0deg) translate(0, 0);
    }
	    50% {
        -webkit-transform: translate(-50%, -50%); /* Safari */
      transform: translate(-50%, -50%);
    }
    100% {
      -webkit-transform: translate(-50%, -50%) rotate(#{$angle}); /* Safari */
      transform: translate(-50%, -50%) rotate(#{$angle});
    }
  }
}
//   animation: rotateAnimation 2s linear infinite; /* Apply the rotateAnimation */


// new enhanced mixines we should consequently migrate to
// use this mixin to create media queries based on viewport width.
// allowed values ['mobile', 'extra-small', 'tablet', 'laptop', 'widescreen']
@mixin mq($device) {
  @if type-of($device) == number {
  @media only screen and (min-width: #{$device}px) {
    @content;
  }
}
@else if type-of($device) == map {
  $min-width: map-get($device, 'min-width');
  $max-width: map-get($device, 'max-width');
  @if $min-width and $max-width {
    @media only screen and (min-width: #{$min-width}px) and (max-width: #{$max-width}px) {
      @content;
    }
  }
  @else if $min-width {
    @media only screen and (min-width: #{$min-width}px) {
      @content;
    }
  }
  @else if $max-width {
    @media only screen and (max-width: #{$max-width}px) {
      @content;
    }
  }
}
@else if $device == mobile {
  @media only screen and (max-width: 480px) {
    @content;
  }
}
@else if $device == extra-small {
  @media only screen and (min-width: 481px) and (max-width: 767px) {
    @content;
  }
}
@else if $device == tablet {
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    @content;
  }
}
@else if $device == laptop {
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    @content;
  }
}
@else if $device == desktop {
  @media only screen and (min-width: 1200px) and (max-width: 1919px) {
    @content;
  }
}
@else if $device == widescreen {
  @media only screen and (min-width: 1920px) {
    @content;
  }
  
}
  @else if $device == medium-up {
  @media only screen and (min-width: 992px) {
    @content;
  }
  
}
   @else if $device == large-down {
  @media only screen and (max-width: 1439px) {
    @content;
  }
}
@else if $device == medium-down {
  @media only screen and (max-width: 991px) {
    @content;
  }
}
  @else if $device == extra-small-down {
  @media only screen and (max-width: 767px) {
    @content;
  }
}
@else {
  @error "Unknown device name #{$device}.";
}
}

@mixin flex-center {
display: flex;
align-items: center;
}
