.main-page {
  color: $primary-color;
  overflow: hidden;
  .animated-bg {
    color: transparent;
    background-image: url('https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?q=80&w=2574&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'); 
    background-size: 250%; /* Enlarged for smooth animation */
    background-position: 0 50%;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: animate-background 5s infinite alternate linear;
  }
  .slide-from-left {
    animation: fade-in-and-slide-in-ltr 1s linear;
  }
  .slide-from-right{
    animation: fade-in-and-slide-in-rtl 1s linear;
  }
  .fade-in-and-scale {
    animation: fade-in-and-scale 500ms linear;
  }
  .fade-in {
    animation: fade-in linear 1s;
  }
  .temporary-page-header {
    position: absolute;
    z-index: 4;
    padding-left: 33px;
    padding-right: 39px;
    padding-top: 26px;
    // justify-content: unset;

    @include media-query($medium-down) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }
    @include media-query($small) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .switch-mode {
    border: none;
    border-radius: 100%;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 30px;
    height: 30px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .btn {
    border-radius: 10px;
    background: #9420f2;
    box-shadow: 0px 0px 44px 0px rgba(159, 56, 210, 0.5);

    &:hover, &:focus {
      background: $purple-contrast;
      
    }
  }
  .custom-select-pages {
    border-radius: 40px;
    border: 1px solid $purple-outline;
    background: $purple-bg-transparent;
    backdrop-filter: blur(7px);
    button {
      gap: 10px;
    }
    ul {
      @include mq($mobile) {
        left: 100%;
        backdrop-filter: blur(3px);
        background: rgba(214, 167, 250, 0.95);
      }
      border: 1px solid $purple-outline;
      background: $purple-bg-transparent;
      backdrop-filter: blur(7px);
    }
    span {
      color: $primary-color;
    }
    svg {
      path {
        fill: $secondary-color;
      }
    }
  }
  .main-header-links-list {
    display: flex;
    list-style: none;
    gap: 52px;
    margin-right: auto;
    margin-left: 10px;
    @include media-query($large-down) {
      gap: 10px;
      margin-left: 0;
      // margin-right: 0;
    }
    @include media-query($medium-up) {
      margin-left: auto;
      // margin-right: 0;
    }
    @include media-query($medium-down) {
      flex-direction: column;
      margin-left: auto;
    }
    @include media-query($extra-small) {
      flex-direction: column;
    }

    li {
      font-weight: 500;
      text-align: center;
      cursor: pointer;
    }
  }
  .landing-header-links-list {
    @include flex-center;
    @include mq($extra-small-down) {
      flex-direction: column;
      width: 100%;
      backdrop-filter: blur(2px);
    }

    width: fit-content;
    list-style: none;
    color: $ambient-white;
    gap: 38px;
    padding-top: 13px;
    padding-bottom: 12px;
    padding-left: 34px;
    padding-right: 34px;
    border-radius: 40px;
    border: 1px solid $purple-outline;
    background: $purple-bg-transparent;

    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%; /* 16.8px */
    letter-spacing: -0.28px;
    @include mq($laptop) {
      max-width: 620px;
      gap: 16px;
      padding-left: 10px;
      padding-right: 10px;
    }
    li {
      a {
        text-decoration: none;
        color: inherit;
      }
      cursor: pointer;
      &:hover,
      &:focus {
        color: $purple-outline;
      }
    }
    li:not(:last-of-type) {
      position: relative;
      text-align: center;

      &::after {
        position: absolute;
        top: 50%;
        right: -19px;

        transform: translateY(-50%);
        content: "";
        width: 1px;
        height: 9px;
        background: $secondary-color;
      }
      @include mq($laptop) {
        &::after {
          right: -8px;
        }
      }
      @include mq($extra-small-down) {
        &::after {
          top: unset;
          right: unset;
          transform: unset;
          bottom: -19px;
          left: 50%;
          transform: translateX(-50%);
          width: 100px;
          height: 1px;
        }
      }
    }
  }
  .main-page-footer {
    width: 100%;

    padding-left: 120px;
    padding-right: 82px;
    padding-top: 31px;
    padding-bottom: 17px;
    background-color: #160924;

    @include media-query($small) {
      padding-left: 10px;
      padding-right: 10px;
    }
    .footer-upper-wrapper {
      @include media-query($medium-down) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
      }
      margin-bottom: 8px;
      display: flex;
      .main-footer-info-logo {
        width: 195px;
        height: 35px;
        margin-right: auto;

        @include media-query($medium-down) {
          margin-right: 0;
        }
        @include media-query($extra-large-up) {
          margin-right: 0;
        }
        a {
          display: block;
          width: 100%;
        }
        .img-abs {
          position: static;
          width: 100%;

          path {
            fill: revert-layer;
          }
          & > path {
            fill: $secondary-color;
          }
        }
      }
      .main-header-links-list {
        li {
          color: $secondary-color;
          font-weight: 400;
          font-size: 14px;
          line-height: 160%;
          letter-spacing: 0.01em;
          a {
            color: $secondary-color;
            font-weight: 400;
            font-size: 14px;
            line-height: 160%;
            letter-spacing: 0.01em;
            text-decoration: none;
          }
        }
      }
      .main-footer-social-links {
        display: flex;
        gap: 26px;
        list-style: none;
      }
    }

    .footer-contacts {
      @include media-query($medium-down) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
      text-align: right;
      margin-bottom: 9px;
      list-style: none;
      a {
        display: block;
        text-decoration: none;
        color: #fffef1;
        font-size: 13px;
        line-height: 138%;
        text-align: right;
      }
    }
    .footer-lower-wrapper {
      @include media-query($medium-down) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
      gap: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 138%;
      color: rgba(255, 254, 241, 0.5);
    }
    .footer-secondary-links {
      @include media-query($medium-down) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
      }
      list-style: none;
      display: flex;
      align-items: center;
      gap: 10px;
      a {
        text-decoration: none;
        display: block;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        letter-spacing: 0.01em;
        color: rgba(255, 255, 255, 0.4);
      }
    }
  }
  .main-page-footer.on-profile-page {
    background: $layout-bg;
    border-top: 1px solid $border-color;
  }
  .main-page-section {
    color: $main-page-black;
    .section-inner-container {
      margin: 0;
      margin-left: auto;
      margin-right: auto;
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
      @media (min-width: 1441px) {
        max-width: 1366px;
      }
      // @include media-query($extra-large-up) {
      //   max-width: 1366px;
      // }
      @include media-query($extra-large) {
        max-width: 100vw;
      }
    }
  }
  .main-page-button-link {
    background: $common-background-gradient;
    font-size: 16px;

    @include media-query($small) {
      width: 100%;
      justify-content: center;
    }
  }
  .hero-section {
    padding-top: 100px;
    margin-bottom: 94px;

    .section-inner-container {
      padding-left: 202px;
      padding-right: 109px;

      @include media-query($large) {
        padding-left: 10px;
        padding-right: 10px;
      }
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .hero-section-heading {
      text-align: center;
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      display: block;
      margin: 0;
      max-width: 629px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 20px;
    }
    h2 {
      font-weight: 500;
      font-size: 16px;
      line-height: 180%;
      text-align: center;
      color: #7e7e7e;
      margin-bottom: 52px;
    }
    .hero-canvas {
      @include media-query($large) {
        transform: scale(0.8);
      }
      @include media-query($medium-down) {
        transform: scale(0.9);
      }
      min-height: 313px;
      img {
        position: absolute;
        @include media-query($small) {
          display: none;
        }
      }
      .cube {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 3;
        @include mq($medium-up) {
          @include rotateAnimation(360deg);
          animation: rotateAnimation 2s $transition-timing-function forwards;
        }

        @include media-query($small) {
          display: block;
        }
      }
      .percents-and-lines {
        @include fadeInAndSlideIn(right);
        @include media-query($large) {
          transform: scale(0.8);
        }
        @include media-query($medium-down) {
          transform: scale(0.7);
          transform: translateX(10%);
        }
        animation: fadeInAndSlideIn-right 2s $transition-timing-function;
        top: 0;
        right: 0;
      }
      .left-side-hero-holder {
        @include fadeIn;
        position: absolute;
        z-index: 3;
        top: 38%;
        left: 0;
        transform: translateY(-50%);
        animation: fadeIn 3s $transition-timing-function;
      }
      .profile-preview {
        position: static;
      }
      .btc-coin {
        top: -25%;
        left: -25%;
        transform: translate(25%, 25%);
      }
      .eth-coin {
        bottom: -25%;
        right: -25%;
        transform: translate(-25%, -25%);
      }
      .horizontal-bar {
        top: 42%;
        left: 18%;
      }
    }
    .main-page-buttons-holder {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 25px;
      margin-bottom: 70px;
      @include media-query($medium-down) {
        flex-direction: column;
        width: 100%;
      }
    }

    .light {
      background: linear-gradient(white, white) padding-box,
        $common-background-gradient border-box;
      border: 1px solid transparent;
      color: $main-page-black;
    }
  }
  .hero-new-section {
    position: relative;
    z-index: 1;
    margin-bottom: 45px;
    height: 673px;
    .section-inner-container {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-left: 33px;
      padding-right: 31px;
      width: 100%;
      height: 100%;

      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .face-bg-wrapper {
      width: 100%;
      height: 100%;
      z-index: 1;
      background-image: url("../../images/hero-new/hero-face.png");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: contain;
    }
    .cryptouch-logo-wrapper {
      @include mq($extra-small-down) {
        background-position: top;
        top: 40%;
        height: 180px;
        justify-content: center;
      }
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      width: 90%;
      height: 229px;
      position: absolute;
      z-index: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-image: url("../../images/hero-new/hero-logo.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }

    .content-wrapper {
      width: 100%;

      @include flex-center;
      justify-content: space-between;
      @include mq($extra-small-down) {
        flex-direction: column;
        justify-content: center;
      }
    }
    .link-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
    }
    @include mq($extra-small-down) {
      .link-wrapper {
        width: 100%;
      }
    }
    h1 {
      @include mq($extra-small-down) {
        width: 100%;
      }
      animation: lights 2s 250ms linear infinite;
      width: 325px;
      font-size: 27px;
      font-weight: 550;
      line-height: 110%; /* 29.7px */
      span {
        color: $purple-accent;
      }
    }
    p {
      display: block;
      width: 170px;
      text-align: right;
      color: #bababa;

      text-align: right;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 110%; /* 17.6px */
      letter-spacing: -0.16px;
    }
    a {
      color: $secondary-color;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 122.2%; /* 17.108px */
      text-align: right;
      @include mq($extra-small-down) {
        font-size: 18px;
        text-align: center;
      }
    }
    .link-button {
      @include mq($extra-small-down) {
        width: 100%;
      }
      text-align: center;
      display: block;
      padding: 11px 27px;
      border-radius: $main-border-radius;
      width: fit-content;
      text-decoration: none;
      color: $secondary-color;
      background: $purple-accent;
      box-shadow: 0px 0px 44px 0px rgba(159, 56, 210, 0.5);
      &:hover,
      &:focus {
        background: $purple-outline;
      }
    }
  }
  .currency-rates-section {
    padding-bottom: 12px;
    .section-inner-container {
      padding-left: 33px;
      padding-right: 31px;

      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .hero-coin-cards {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
      gap: 16px;

      @include mq($mobile) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
      }
    }
    .hero-coin-card {
      border-radius: 20px;
      // min-width: 310px;
      padding-left: 25px;
      padding-top: 20px;
      padding-right: 26px;
      padding-bottom: 17px;
      background: $currency-card-purple-bg;
      .upper-part {
        @include flex-center;
        justify-content: space-between;
      }
      .currency-title {
        font-weight: 500;
        font-size: 16px;
        line-height: 180%;
        color: #acacac;
      }
      .currency-course {
        font-weight: 700;
        font-size: 16px;
        line-height: 180%;
        letter-spacing: -0.04em;
        color: #67e6a8;
      }
      .drop {
        color: #db4d2e;
      }

      .holder {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .rate-holder {
        display: flex;
        column-gap: 8px;
        align-items: center;
      }
      .currency-rate {
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
        color: #fff;
      }
    }
  }
  .promo-new-section {
    position: relative;
    height: 720px;
    .section-inner-container {
      position: relative;
      @include flex-center;
      padding-left: 33px;
      padding-right: 31px;
      width: 100%;
      height: 100%;

      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .face-bg-wrapper {
      position: absolute;
      left: 0;
      z-index: 0;
      width: 100%;
      height: 100%;
      background-image: url("../../images/promo-new/promo-new-bg.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
    .content-wrapper {
      position: relative;
      z-index: 1;
      @include mq($extra-small-down) {
        width: 100%;
      }
    }
    h2 {
      @include mq($extra-small-down) {
        width: 100%;
      }
      animation: lights 2s 250ms linear infinite;
      max-width: 400px;
      font-size: 30px;
      font-weight: 500;
      line-height: 130%; /* 39px */
      margin-bottom: 30px;
      span {
        color: $purple-accent;
      }
    }
    p {
      display: block;

      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%; /* 21px */
    }

    .description {
      @include mq($mobile) {
        max-width: 100%;
      }
      max-width: 300px;
      line-height: 180%;
      margin-bottom: 30px;
    }
    .sub-description-block {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      max-width: 265px;
    }
    .link-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
      margin-top: 60px;
    }
    a {
      color: $secondary-color;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 122.2%; /* 17.108px */
      @include mq($extra-small-down) {
        font-size: 18px;
        text-align: center;
      }
    }
    .link-button {
      @include mq($extra-small-down) {
        width: 100%;
      }
      text-align: center;
      display: block;
      padding: 11px 27px;
      border-radius: $main-border-radius;
      width: fit-content;
      text-decoration: none;
      color: $secondary-color;
      background: $purple-accent;
      box-shadow: 0px 0px 44px 0px rgba(159, 56, 210, 0.5);
      &:hover,
      &:focus {
        background: $purple-outline;
      }
    }
  }

  .promo-section {
    margin-bottom: 170px;

    .section-inner-container {
      padding-left: 120px;
      padding-right: 100px;
      display: flex;
      align-items: center;
      gap: 113px;
      @include media-query($w1100) {
        flex-direction: column;
      }
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .promo-section-left-side {
      min-width: 50%;
      h2 {
        font-weight: 500;
        font-size: 40px;
        line-height: 120%;
        margin-bottom: 20px;
      }
      .promo-section-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 180%;
        margin-bottom: 93px;
      }
      .promo-section-video-wrapper {
        display: flex;
        align-items: center;
        gap: 24px;
        p {
          line-height: 180%;
        }
        p:first-child {
          font-weight: 700;
          font-size: 16px;
        }
        p:last-child {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }
    .thumb {
      width: 538px;
      height: 334px;
      overflow: hidden;
      border-radius: 20px;
      outline: 4px solid rgb(165, 36, 165);
    }
    .hero-coin-card {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transform: scale(1.67);
    }
    .promo-section-right-side {
      @include media-query($small-mid) {
        transform: scale(0.7);
      }
      h3 {
        font-weight: 500;
        font-size: 28px;
        line-height: 130%;
      }
      p {
        font-weight: 500;
        font-size: 16px;
        line-height: 130%;
        margin-bottom: 30px;
      }
    }
  }
  .features-section {
    margin-bottom: 124px;

    .section-inner-container {
      padding-left: 120px;
      padding-right: 50px;
      display: flex;
      column-gap: 83px;
      @include media-query($w1100) {
        flex-direction: column;
        align-items: center;
      }
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
        width: 100%;
      }
    }
  }
  .features-new-section {
    padding-top: 106px;
    padding-bottom: 72px;
    .section-inner-container {
      padding-left: 103px;
      padding-right: 103px;

      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .features-list {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(378px, 1fr));
      @include mq($mobile) {
        grid-template-columns: 1fr;
      }
      margin-bottom: 28px;
      gap: 12px;
      list-style: none;
      .bg-thumb {
        height: 209px;
        width: 203px;
        position: absolute;
        top: -22%;
        left: -32px;
        background-image: url("../../images/features-new/feature-bg.png");
        background-repeat: no-repeat;
        background-position: left;
      }
      .feature-wrapper {
        @include flex-center;
        gap: 125px;
        @include mq($mobile) {
          gap: 116px;
        }
        overflow: hidden;
        position: relative;
        height: 152px;
        padding-left: 64px;

        background: $currency-card-purple-bg;
        border-radius: 20px;
        border: 1px solid transparent;
      }
      .number {
        font-family: Inter;
        font-size: 37px;
        font-weight: 900;
        line-height: 130%; /* 48.1px */
      }
      .content-block {
        width: 142px;
        @include mq($mobile) {
          width: 100px;
        }
        span {
          display: block;
          font-family: Inter;
          font-size: 14px;
          font-weight: 700;
          line-height: 180%; /* 25.2px */
        }
        p {
          font-family: Inter;
          font-size: 14px;
          font-weight: 400;
          line-height: 140%; /* 19.6px */
        }
      }
    }
    .lower-part {
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      @include mq($extra-small-down) {
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
      }
      p {
        max-width: 432px;

        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 180%; /* 25.2px */
      }
    }
    .link-button {
      @include mq($extra-small-down) {
        width: 100%;
      }
      text-align: center;
      display: block;
      padding: 11px 27px;
      border-radius: $main-border-radius;
      width: fit-content;
      text-decoration: none;
      color: $secondary-color;
      background: $purple-accent;
      box-shadow: 0px 0px 44px 0px rgba(159, 56, 210, 0.5);
      &:hover,
      &:focus {
        background: $purple-outline;
      }
    }
  }
  .cross-stripes-branding {
    position: absolute;
    z-index: 5;
    .purple-stripe {
      position: absolute;
      z-index: 6;
      overflow: hidden;
      width: 100vw;
      @include flex-center;
      transform: rotate(4.316deg);
      gap: 10px;
      list-style: none;
      padding-top: 6px;
      padding-bottom: 6px;
      background: $purple-light;
      li {
        @include flex-center;
        gap: 10px;
        padding-left: 10px;
        p {
          display: block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }
    .white-stripe {
      @extend .purple-stripe;
      z-index: 5;
      transform: rotate(-1.52deg);
      background: $secondary-color;
      gap: 54px;
    }
  }
  .features-section-left-side {
    min-height: 496px;
    min-width: 542px;
    @include media-query($medium-down) {
      min-width: unset;
      width: 542px;
      scale: (0.8);
    }
    @include media-query($small) {
      scale: (0.7);
    }
  }
  .sextagon-thumb {
    position: absolute;
  }
  .text-content-holder {
    position: absolute;
    color: $secondary-color;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span {
      font-weight: 700;
      font-size: 16px;
      line-height: 180%;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 130%;

      color: #acacac;
    }
  }
  .sextagon-thumb:first-child {
    top: 0;
    left: 0;
    z-index: 4;
    .text-content-holder {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .sextagon-thumb:nth-child(2) {
    top: 0;
    right: 0;
    .text-content-holder {
      left: 50%;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .sextagon-thumb:nth-child(3) {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    .text-content-holder {
      left: 50%;
      top: 50%;
      transform: translateX(-25%);
    }
  }
  .sextagon-thumb:nth-child(4) {
    width: 195px;
    height: 195px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -75%);
    z-index: 6;
    img {
      position: absolute;
      top: -50%;
      left: -50%;
    }
  }

  .features-section-right-side {
    @include media-query($small) {
      min-width: 100%;
    }
    .main-page-button-link {
      @include media-query($small) {
        min-width: 100%;
      }
      width: fit-content;

      font-weight: 700;
      line-height: 122%;
      padding: 11px 27px;
    }
    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      margin-bottom: 20px;
    }
    p {
      font-weight: 500;
      font-size: 16px;
      line-height: 180%;
      margin-bottom: 45px;
    }
  }
  .advantages-section {
    .section-inner-container {
      padding-left: 115px;
      padding-right: 95px;
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      margin-bottom: 64px;

    }
    .thumbs-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    .thumb-row {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
      gap: 10px;
    }
    .thumb:nth-child(odd) {
      background-color: #24324a;
    }
    .thumb:nth-child(even) {
      background-color: #784ea8;
    }

    .thumb {
      @include media-query($medium-down) {
        width: 100%;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: column;
      height: 250px;
      text-align: center;
      padding: 51px 40px;
      border-radius: 20px;
      transition: transform $transition-duration $transition-timing-function;
      &:hover {
        transform: scale(1.05);
      }
      p {
        font-weight: 500;
        font-size: 14px;
        line-height: 130%;
        color: $secondary-color;
      }
      button {
        border: none;
        color: $secondary-color;
        background: transparent;
        text-align: center;
        font-size: 14px;
        font-weight: 550;
        line-height: 130%; /* 18.2px */
        text-decoration-line: underline;
      }
    }
    .thumb:nth-child(odd) {
      background-color: #24324a;
    }
    .thumb:nth-child(even) {
      background-color: #784ea8;
    }
  }
  .algoritms-section {
    padding-top: 64px;
    margin-bottom: 100px;
    .section-inner-container {
      padding-left: 30px;
      padding-right: 30px;
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .lower-part {
      margin-top: 16px;
      ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        list-style: none;
        gap: 20px;

        li {
          @include flex-center;
          height: 86px;
          background: $currency-card-purple-bg;
          border: 1px solid $purple-bg-transparent;
          border-radius: 20px;
          padding-left: 21px;
          padding-top: 23px;
          padding-bottom: 23px;
          padding-right: 10px;
          filter: drop-shadow(0px 0px 34px rgba(15, 15, 15, 0.25));

          backdrop-filter: blur(4px);
        }
      }
    }
    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      margin-bottom: 35px;
    }
    .cards-holder {
      @include mq($mobile) {
        grid-template-columns: 1fr;
      }
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(416px, 1fr));
      gap: 12px;
    }
    .card-thumb {
      display: flex;
      flex-direction: column;
      gap: 22px;
      height: 310px;
      background: $secondary-color;

      @include media-query($extra-small-down) {
        padding-left: 10px;
        padding-right: 10px;
        height: fit-content;
      }
      @include mq($mobile) {
        gap: 10px;
      }
      h4 {
        @include mq($mobile) {
          font-size: 30px;
        }
        color: #535353;
        font-family: "Bebas Neue", "Roboto";
        font-style: normal;
        font-size: 47px;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.94px;
      }
      ul {
        @include mq($mobile) {
          gap: 6px;
        }
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow-y: scroll;
        ::-webkit-scrollbar {
          display: none; /* Chrome, Safari, and Edge */
        }
        scrollbar-width: none; /* Firefox */
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        li {
          @include flex-center;
          gap: 12px;
          p {
            color: #484848;
            font-size: 14px;
            font-weight: 700;
            line-height: 120%; /* 16.8px */
          }
        }
      }
      span {
        display: block;
        margin-bottom: 15px;
        font-size: 24px;
        font-weight: 700;
        line-height: 180%; /* 43.2px */
      }
      border-radius: 14px;
      border: 1px solid rgba(103, 46, 167, 0.2);
      padding-left: 36px;
      padding-top: 34px;
      padding-right: 30px;
      padding-bottom: 24px;
      // background: rgba(103, 46, 167, 0.2);
      // filter: drop-shadow(0px 0px 34px rgba(15, 15, 15, 0.25));
      // backdrop-filter: blur(2.5px);
      .main-page-button-link {
        border: 1px solid #bfbfbf;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(360deg, #337fd0 0%, #3f77b3 0.01%);
      }
    }
    .card-content {
      @include media-query($medium-down) {
        gap: 8px;
      }
      display: flex;
      margin-bottom: 24px;
      text-align: left;
      justify-content: space-between;
      .left-side {
        width: 50%;

        h4 {
          font-weight: 700;
          font-size: 26px;
          line-height: 120%;
          margin-bottom: 15px;
          @include media-query($medium-down) {
            font-size: 18px;
          }
        }
        span {
          font-weight: 700;
          font-size: 24px;
          line-height: 130%;
          color: #67e6a8;
          margin-bottom: 17px;
          @include media-query($medium-down) {
            font-size: 16px;
          }
        }
        .subtitle {
          display: block;
          margin-bottom: 17px;
        }
        .description {
          font-weight: 500;
          font-size: 17px;
          line-height: 130%;
        }
      }
      .right-side {
        p:not(:last-child) {
          font-weight: 700;
          font-size: 26px;
          line-height: 180%;
          margin-bottom: 15px;
          @include media-query($medium-down) {
            font-size: 18px;
          }
        }
        p:last-child {
          font-weight: 500;
          font-size: 17px;
          line-height: 130%;
        }
      }
    }
  }
  .video-section {
    margin-top: -200px;
    margin-bottom: 60px;
    position: relative;
    z-index: 6;

    .section-inner-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    iframe {
      border-radius: 20px;
    }
  }
  .feedback-section {
    margin-bottom: 100px;
    @include media-query($extra-large-up) {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    .section-inner-container {
      padding: 0;
      margin: 0;
      width: 100vw;
    }
    .feedback-row {
      @include media-query($medium-down) {
        flex-direction: column;
      }
      @include media-query($extra-large-up) {
        width: 1440px;
      }
      width: 100vw;
      display: flex;
      list-style: none;
      padding: 0;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      li {
        height: 432px;
        @include media-query($medium-down) {
          height: auto;
        }
      }
    }
    .feedback-row:last-child {
      flex-direction: row-reverse;
      @include media-query($medium-down) {
        flex-direction: column;
      }
      .feedback-thumb {
        border-top: 1px solid rebeccapurple;
      }
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    }
    .feedback-thumb {
      @include media-query($medium-down) {
        width: 100%;
        height: auto;
        border-bottom: 1px solid rebeccapurple;
        border-left: none;
        border-right: none;
      }
      border-right: 1px solid rebeccapurple;
      border-left: 1px solid rebeccapurple;
      width: 400px;
      height: 432px;
      padding-left: 45px;
      padding-top: 55px;
      padding-right: 46px;
      padding-bottom: 33px;
      background-color: #24324a;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .lower-part-holder {
      display: flex;
      gap: 14px;
      align-items: flex-start;
      margin-top: 56px;
      height: 53px;
    }
    .description {
      font-weight: 500;
      font-size: 15px;
      line-height: 180%;
      color: #acacac;
    }
    .icon-thumb {
      position: relative;
      width: 52px;
      height: 52px;
      overflow: hidden;

      svg {
        position: absolute;
        top: -85px;
        left: -47px;
      }
    }
    .rating-category {
      font-weight: 500;
      font-size: 16px;
      line-height: 180%;
      color: $secondary-color;
    }
    .star-list {
      display: flex;
      list-style: none;
      gap: 4px;
    }
  }
  .development-section {
    margin-bottom: 160px;

    .section-inner-container {
      padding-left: 115px;
      padding-right: 95px;
      overflow-x: hidden;
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }

    .development-upper-holder {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 60px;
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 120%;
      }
      .arrows-thumb {
        display: flex;
        gap: 18px;
        @include media-query($medium-down) {
          display: none;
        }
      }
      .arrow-button {
        width: 54px;
        height: 54px;
        border-radius: 100%;
        border: 1px solid #4d1d65;
        background: linear-gradient(
          360deg,
          #337fd0 0%,
          #6432a7 51%,
          #9f38d2 100%
        );
        display: flex;
        box-shadow: 0 0 43px 0 rgba(68, 30, 109, 0.5);
      }
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 17px;
        height: 22px;
      }
    }
    .scrolled {
      transform: translateX(-10%);
    }
    .development-lower-holder {
      width: 100vw;
      display: flex;
      gap: 40px;
      transition: transform $transition-duration $transition-timing-function;
      @include media-query($medium-down) {
        width: 100%;
      }

      .development-card {
        @include media-query($small) {
          min-width: 90%;
        }

        &:last-child {
          svg {
            display: none;
          }
        }

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        row-gap: 10px;
        min-width: 459px;
        height: 250px;
        padding-left: 40px;
        padding-right: 50px;
        padding-bottom: 51px;
        border-radius: 20px;
        backdrop-filter: blur(10px);
        box-shadow: 0 0 34px 0 rgba(15, 15, 15, 0.25);
        background-color: #24324a;

        svg {
          position: absolute;
          top: 50%;
          right: -10%;
          transform: translate(-15%, -50%);
          @include media-query($medium-down) {
            right: -46px;
          }
        }
      }

      .development-card:nth-child(even) {
        background-color: #784ea8;
      }
      span {
        display: block;
        color: $secondary-color;
        font-weight: 700;
        font-size: 24px;
        line-height: 130%;
      }
      p {
        display: block;
        font-weight: 500;
        font-size: 16px;
        line-height: 180%;
        color: #acacac;
      }
    }
  }
  .partnership-section {
    margin-bottom: 70px;

    .section-inner-container {
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
        flex-direction: column;
      }
      display: flex;
      gap: 24px;
      align-items: center;
      margin: 0;
      margin-left: auto;
      margin-right: auto;
      padding-left: 115px;
      padding-right: 95px;
    }
    .partnership-left-side {
      flex: 1;
      min-width: 400px;
      @include media-query($medium-down) {
        min-width: 100%;
      }
    }
    .partnership-right-side {
      flex: 1;
      height: 395px;
      background-image: url("../../images/partnership-section/handshake.png");
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      @include media-query($medium-down) {
        flex: auto;
        min-width: 100%;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      margin-bottom: 24px;
    }
    .partnership-description {
      font-weight: 500;
      font-size: 16px;
      line-height: 180%;
      display: block;
      width: 350px;
      margin-bottom: 26px;
    }
    .partnership-lower-row {
      @include media-query($medium-down) {
        flex-direction: column;
      }
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .content-thumb {
      @include media-query($medium-down) {
        width: 100%;
      }
      width: 100%;
      display: flex;
      align-items: center;
      gap: 22px;
      margin-bottom: 20px;

      p {
        font-weight: 500;
        font-size: 15px;
        line-height: 180%;
        color: $primary-color;
        white-space: normal;
        word-wrap: break-word;
      }

      .logo {
        position: relative;
        border-radius: 100%;
        min-width: 54px;
        min-height: 54px;
        background: #9f38d2;

        svg {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .main-page-button-link {
      @include media-query($small) {
        width: 100%;
      }
      width: 100%;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
    }
  }
  .partnership-new-section {
    @extend .promo-new-section;
    height: 1056px;
    margin-top: -290px;
    .face-bg-wrapper {
      background-image: url("../../images/partnership-section/partnership-new-bg.png");
    }
    .partnership-list {
      max-width: 276px;
      list-style: none;
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    li {
      @include flex-center;
      gap: 10px;
      svg {
        flex-shrink: 0;
      }
      p {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 150%; /* 21px */
      }
    }
    a {
      align-self: center;
      text-align: center;
    }
  }

  .news-section {
    padding-bottom: 72px;

    .section-inner-container {
      max-height: 520px;
      padding-left: 48px;
      padding-right: 63px;
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      margin-bottom: 52px;
      @include media-query($medium-up) {
        padding-left: 78px;
      }
    }
    .slider-container {
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
      word-wrap: break-word;
      & .slick-list {
        & .slick-slide > div {
          cursor: pointer;
          margin: 10px 10px;
          overflow: hidden;
          font-size: 16px;
          h4 {
            font-weight: 700;
            line-height: 150%; /* 24px */
          }
          p {
            font-weight: 500;
            line-height: 160%; /* 25.6px */
            display: -webkit-box; /* Enables multi-line truncation */
            -webkit-line-clamp: 3; /* Number of lines to show */
            line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden; /* Hides overflowing text */
            text-overflow: ellipsis; /* Adds "..." at the end */
            max-height: 4.5em;
          }
          .thumb {
            width: 100%;
            height: 170px;
            border-radius: 20px;
            overflow: hidden;
            margin-bottom: 21px;

            img {
              object-fit: contain;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .events-section {
    margin-bottom: 115px;
    h3 {
      font-size: 40px;
      font-weight: 550;
      line-height: 120%; /* 48px */
      margin-bottom: 40px;
    }
    .section-inner-container {
      padding-left: 115px;
      padding-right: 95px;
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
    .images-row {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 17px;
      @include media-query($medium-down) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
    .thumb {
      position: relative;
      img {
        width: 100%;
        object-fit: cover;
      }
      a {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background: transparent;
      }
      p {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        font-size: 30px;
        font-weight: 700;
        line-height: 110%; /* 33px */
        letter-spacing: -0.6px;
      }
    }
  }
  .development-map-section {
    margin-bottom: 121px;
    .section-inner-container {
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
        flex-direction: column;
      }

      margin-left: auto;
      margin-right: auto;
      padding-left: 70px;
      padding-right: 95px;
    }
    .flex-wrapper {
      display: flex;
      align-items: center;
      gap: 25px;
      @include mq($extra-small-down) {
        flex-direction: column;
      }
    }
    h3 {
      font-weight: 500;
      font-size: 40px;
      line-height: 120%;
      margin-bottom: 24px;
    }
    .development-card {
      background-color: $secondary-color;
      padding-left: 47px;
      padding-top: 35px;
      padding-right: 20px;
      border-radius: 12px;
      padding-right: 20px;
      overflow-y: scroll;
      height: 490px;
      @include media-query($medium-down) {
        padding-left: 10px;
        padding-right: 10px;
      }

      h4 {
        color: #535353;
        // font-family: "Bebas Neue";
        font-size: 74px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -2.245px;
        margin-bottom: 22px;
        @include media-query($medium-down) {
          font-size: 50px;
        }
      }
      .goals-list {
        list-style: none;
        display: flex;
        flex-direction: column;
        gap: 25px;
      }
      .goals-list > li {
        position: relative;
        padding-left: 20px;
      }
      .goals-list > li::before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        transform: translateY(30%);
        width: 12px;
        height: 12px;
        background-size: contain;
        background-repeat: no-repeat;
        background-image: url("../../icons/development-map/list-pointer.svg");
      }
      .goal-title {
        color: #484848;
        font-size: 15px;
        font-weight: 700;
        line-height: 120%; /* 18px */
      }
      .goals-sublist {
        color: #484848;
        font-size: 15px;
        font-weight: 400;
        line-height: 138%; /* 20.7px */
        padding-left: 0px;
        list-style: none;
      }
      .goals-sublist > li {
        position: relative;
        padding-left: 10px;
      }
      .goals-sublist > li::before {
        content: "-"; /* Dash as the marker */
        position: absolute;
        left: 0;
        color: $primary-color; /* Color of the dash */
      }
    }
    .development-list {
      display: flex;
      flex-direction: column;
      background: transparent;
      list-style: none;
      min-width: 50%;
      height: 490px;
      overflow-y: scroll;
      @include mq($extra-small-down) {
        min-width: 100%;
      }
      li {
        display: flex;
        justify-content: space-between;
        padding-left: 59px;
        padding-right: 37px;
        padding-top: 30px;
        padding-bottom: 30px;
        border-bottom: 1px solid #9f38d2;
        cursor: pointer;
        @include media-query($medium-down) {
          padding-left: 10px;
          padding-right: 10px;
        }
      }
      li.active,
      li:hover,
      li:focus {
        background: #9f38d2;
      }
    }
  }
}

// dark-mode
.dark {
  background: $dark-background;
  color: $dark-theme-text-primary;
  .wave-image-holder {
    position: relative;

    // opacity: 0.3;
  }
  .waves-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
        180deg,
        rgba(16, 8, 26, 0.99) 0%,
        rgba(16, 8, 26, 0.5) 60.29%,
        rgba(16, 8, 26, 0.99) 100%
      ),
      url("../../images/main-page/waves.png");
    background-repeat: no-repeat;

    background-size: cover;
    mix-blend-mode: luminosity;
    z-index: 0;
  }
  .algoritms-section {
    position: relative;
    z-index: 1;
    .main-page-button-link {
      box-shadow: 0 0 44px 0 rgba(159, 56, 210, 0.5);
      background: linear-gradient(
        0deg,
        #337fd0 -45.19%,
        #3f77b3 -45.16%,
        #6927a4 100.86%,
        #9f38d2 230.37%
      ) !important;
      border: none !important;
    }
  }
  .advantages-section {
    position: relative;
    z-index: 1;
  }
  // .main-header-info-logo {
  //   .img-abs {
  //     path {
  //       fill: black;
  //     }
  //   }
  // }
  .main-page-section {
    color: $dark-theme-text-primary;
  }

  .main-page-button-link {
    box-shadow: 0 0 44px 0 rgba(159, 56, 210, 0.5);
    border: none;
  }
  .main-page-button-link.light {
    background: transparent;
    border: 1px solid #9f38d2;
    box-shadow: unset;
    color: $dark-theme-text-primary;
  }
  .promo-section p {
    color: $dark-theme-text-secondary;
  }
  p.watch-video-title {
    color: $dark-theme-text-primary;
  }
  .features-section p {
    color: $dark-theme-text-secondary;
  }
  .thumb-row {
    .thumb {
      filter: drop-shadow(0px 0px 34px rgba(15, 15, 15, 0.25));
      border: 1px solid $currency-card-purple-bg;
      backdrop-filter: blur(25px);
    }
    .thumb:nth-child(1),
    .thumb:nth-child(3),
    .thumb:nth-child(6),
    .thumb:nth-child(8) {
      background-color: rgba(15, 15, 15, 0.25) !important;
    }
    .thumb:nth-child(2),
    .thumb:nth-child(4),
    .thumb:nth-child(5),
    .thumb:nth-child(7) {
      background-color: rgba(103, 46, 167, 0.15) !important;
    }
  }

  .feedback-thumb {
    background-color: rgba(36, 50, 74, 0.2) !important;
    backdrop-filter: blur(50px);
    box-shadow: 0 0 34px 0 rgba(15, 15, 15, 0.25);
    border: 1px solid rebeccapurple;
  }
  .development-lower-holder {
    .development-card {
      color: rgba(36, 50, 74, 0.2);
      background-color: rgba(36, 50, 74, 0.2) !important;

      backdrop-filter: blur(5px);
      box-shadow: 0 0 34px 0 rgba(15, 15, 15, 0.25);

      border: 1px solid rebeccapurple;
      span {
        background-image: linear-gradient(
          6deg,
          #337fd0 0%,
          #6432a7 51%,
          #9f38d2 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        -webkit-text-fill-color: transparent;
      }
    }

    .development-card:nth-child(even) {
      background-color: rgba(103, 46, 167, 0.2) !important;
    }
  }
  .development-section {
    background-image: linear-gradient(
        180deg,
        rgba(16, 8, 26, 0.3) 0%,
        rgba(16, 8, 26, 0.6) 100%
      ),
      linear-gradient(
        0deg,
        rgba(16, 8, 26, 0.3) 15.15%,
        rgba(16, 8, 26, 0.6) 100%
      ),
      url("../../images/partnership-section/earth-bg.png");
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    mix-blend-mode: lighten;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .partnership-section {
    .partnership-lower-row {
      p {
        color: $secondary-color;
      }
    }
  }
}
